import * as React from "react";
import { ConfirmInfo } from "lu-component";
import { MessageInfo } from "../../../types/info";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const MessageTitle = styled.div`
	font-size: 1.2em;
`;
const ErrorMessage = styled.span`
	color: red;
`;

export type ConfirmBoxProps = {
	titleLabel?: string;
	info: MessageInfo;
	onClose?: () => void;
};
export const ConfirmBox = React.memo((props: ConfirmBoxProps) => {
	const { info, onClose, titleLabel = "登録" } = props;
	const [show, setShow] = React.useState<boolean>();
	React.useEffect(() => {
		// 成功ステータスで、messageがない場合は表示しない。
		if (info.isSuccess && !info.message) return;
		setShow(true);
	}, [info]);
	const title = React.useMemo(() => {
		if (info.isSuccess) {
			return (
				<MessageTitle>
					<FontAwesomeIcon icon={faCheckCircle} fixedWidth />
					{titleLabel}完了
				</MessageTitle>
			);
		}
		return (
			<MessageTitle>
				<ErrorMessage>
					<FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />
					{titleLabel}エラー
				</ErrorMessage>
			</MessageTitle>
		);
	}, [info.isSuccess, titleLabel]);
	const onCloseClick = React.useCallback(() => {
		setShow(false);
		if (info.isSuccess && !!onClose) {
			onClose();
		}
	}, [onClose, info.isSuccess]);
	return <ConfirmInfo title={title} message={info.message} show={show} onClose={onCloseClick} />;
});
