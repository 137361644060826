import moment from "moment";
import * as React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { AccountWithoutPassword } from "../../../../../server/models/account";

export type NavigationBarProps = {
	jobAction?: {
		onJobEditPage: () => void;
		onConceptPage: () => void;
		onPreviewPage: () => void;
	};
	onJobAllPage?: () => void;
	onJobPage?: () => void;
	onActivityPage: () => void;
	onAccountPage?: () => void; // アカウント一覧への導線がある場合
	onLogout?: () => void; // アクティビティ一覧への導線がある場合
	onJobDownloadPage?: () => void; // 案件DLページの導線がある場合
	onInfoPage?: () => void;
	onAggregatePage?: () => void;
	account: AccountWithoutPassword;
};
export const NavigationBar = (props: NavigationBarProps) => {
	const {
		jobAction,
		onAccountPage,
		onLogout,
		account,
		onActivityPage,
		onJobPage,
		onJobDownloadPage,
		onInfoPage,
		onJobAllPage,
		onAggregatePage,
	} = props;
	return (
		<Navbar bg={"dark"} variant="dark" expand="lg" fixed="top">
			<Navbar.Brand href="/activity" className="font-weight-bold">
				FT Test it
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="basic-navbar-nav" />
			<Navbar.Collapse id="basic-navbar-nav">
				<Nav className="mr-auto">
					<Nav.Link onClick={onActivityPage}>アクティビティ一覧</Nav.Link>
					{onAccountPage && <Nav.Link onClick={onAccountPage}>アカウント一覧</Nav.Link>}
					{
						// No.15
						onJobAllPage && <Nav.Link onClick={onJobAllPage}>ジョブ検索</Nav.Link>
					}
					{onAggregatePage && <Nav.Link onClick={onAggregatePage}>集計一覧</Nav.Link>}
					{onJobPage && <Nav.Link onClick={onJobPage}>ジョブ一覧に戻る</Nav.Link>}
					{jobAction && (
						<NavDropdown title={"ジョブ設定"} id={"job"}>
							<NavDropdown.Item onClick={jobAction.onJobEditPage}>基本設定</NavDropdown.Item>
							<NavDropdown.Item onClick={jobAction.onConceptPage}>コンセプト設定</NavDropdown.Item>
							<NavDropdown.Item onClick={jobAction.onPreviewPage}>プレビュー</NavDropdown.Item>
						</NavDropdown>
					)}
				</Nav>
				{/*
				<Nav.Link href="#home">
					<FontAwesomeIcon icon={faBell} color="lightyellow" className="h5" />
					<Badge pill variant="danger">
						3
					</Badge>
					</Nav.Link>
				*/}
				<Nav>
					{onJobDownloadPage && <Nav.Link onClick={onJobDownloadPage}>ジョブDL</Nav.Link>}
					{onInfoPage && <Nav.Link onClick={onInfoPage}>お知らせ</Nav.Link>}
					<Navbar.Text style={{ marginLeft: "20px", marginRight: "10px" }}>
						{account.name}さん
						{!!account.prevLoginedAt && (
							<>（前回ログイン：{moment(account.prevLoginedAt).format("YYYY-MM-DD HH:mm")}）</>
						)}
					</Navbar.Text>
					<Nav.Link onClick={onLogout}>Logout</Nav.Link>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};
