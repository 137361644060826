import * as React from "react";
import { Rect } from "../../../../models/rect";
import EventListener from "react-event-listener";
import { Coordinate } from "../../../../models/coordinate";
import { Area } from "../../../../models/area";

export type ResizableOverlayProps = {
	parentId: string;
	rect: Rect;
	onResized: (rect: Rect) => void;
	children: React.ReactNode;
	fixed?: boolean;
	onClick?: () => void;
};
type CursorDirection = "n" | "w" | "e" | "ne" | "nw" | "se" | "sw" | "";

const resizePad = 10;
export const ResizableOverlay = React.memo((props: ResizableOverlayProps) => {
	const { parentId, rect, onResized, children, fixed, onClick } = props;
	const [cursor, setCusor] = React.useState<CursorDirection>("");
	const [area, setArea] = React.useState<Area>({ width: 100, height: 100 });
	const [focus, setFocus] = React.useState<boolean>(); // リサイズ中華どうか。
	const [isResizing, setIsResizing] = React.useState<boolean>(false);
	const [target, setTarget] = React.useState<Coordinate>();
	const [targetDiff, setTargetDiff] = React.useState<Coordinate>();

	// エリアの定義
	React.useEffect(() => {
		const parent = document.getElementById(parentId);
		if (parent) {
			setArea({ height: parent.clientHeight, width: parent.clientWidth });
		}
	}, [parentId]);

	const onRectMouseMove = React.useCallback(
		(e: React.MouseEvent) => {
			if (isResizing) {
				return;
			}
			const { offsetX, offsetY } = e.nativeEvent;
			let c = "";
			if (offsetY < resizePad) {
				c += "n";
			} else if (rect.height - resizePad < offsetY) {
				c += "s";
			}
			if (offsetX < resizePad) {
				c += "w";
			} else if (rect.width - resizePad < offsetX) {
				c += "e";
			}
			if (fixed && c !== "se") {
				return setCusor("");
			}
			setCusor(c as CursorDirection);
		},
		[rect, isResizing, fixed]
	);

	const overflow = React.useMemo(() => document.body.style.overflow, []);

	const viewRect = React.useMemo(() => {
		let { x, y, height, width } = rect;
		if (!target) {
			return rect;
		}
		const targetX = target.x;
		const targetY = target.y;
		if (cursor.indexOf("e") >= 0) {
			const tmpWidth = targetX - x;
			if (tmpWidth < 0) {
				x = targetX;
				width = Math.abs(tmpWidth);
			} else {
				width = tmpWidth;
			}
		}
		if (cursor.indexOf("s") >= 0) {
			const tmpHeight = targetY - y;
			if (tmpHeight < 0) {
				y = targetY;
				height = Math.abs(tmpHeight);
			} else {
				height = tmpHeight;
			}
		}
		if (cursor.indexOf("w") >= 0) {
			const tmpWidth = width + x - targetX;
			if (tmpWidth < 0) {
				x = x + width;
				width = Math.abs(tmpWidth);
			} else {
				x = targetX;
				width = tmpWidth;
			}
		}
		if (cursor.indexOf("n") >= 0) {
			const tmpHeight = height + y - targetY;
			if (tmpHeight < 0) {
				y = y + height;
				height = Math.abs(tmpHeight);
			} else {
				y = targetY;
				height = tmpHeight;
			}
		}
		return { x, y, height, width };
	}, [rect, cursor, target]);

	const onMouseDown = React.useCallback(() => {
		if (cursor) {
			setIsResizing(true);
		}
	}, [cursor, focus]);

	const onMouseUp = React.useCallback(() => {
		if (isResizing) {
			onResized(viewRect);
		}
		setIsResizing(false);
		setFocus(false);
		setCusor("");
		setTarget(undefined);
		setTargetDiff(undefined);
		document.body.style.overflow = overflow;
	}, [viewRect, isResizing]);

	const style = React.useMemo<React.CSSProperties>(() => {
		return {
			transform: `translate(${viewRect.x - resizePad}px, ${viewRect.y - resizePad}px)`,
			width: `${viewRect.width}px`,
			height: `${viewRect.height}px`,
			border: `${resizePad}px solid rgba(0,0,0,0)`,
			position: "absolute",
			zIndex: 10,
			boxSizing: "content-box",
			cursor: cursor ? `${cursor}-resize` : "",
			userSelect: "none",
		};
	}, [viewRect, cursor]);
	const onMouseMove = React.useCallback(
		(e: MouseEvent) => {
			document.body.style.overflow = "hidden"; // 移動中のスクロールを許可しない。
			const { offsetX, offsetY, srcElement, x, y } = e;
			if (srcElement instanceof HTMLDivElement && srcElement.className === "custom-draggable-area") {
				if (!targetDiff) {
					setTargetDiff({ x: x - offsetX, y: y - offsetY });
					return;
				}
			} else if (!targetDiff || !target) {
				return;
			}
			let updateX = x - targetDiff.x;
			let updateY = y - targetDiff.y;
			if (updateX < 0) {
				updateX = 0;
			}
			if (updateY < 0) {
				updateY = 0;
			}
			if (updateX > area.width) {
				updateX = area.width;
			}
			if (updateY > area.height) {
				updateY = area.height;
			}
			setTarget({ x: updateX, y: updateY });
		},
		[area, targetDiff, fixed]
	);
	const onRectClick = React.useCallback(() => {
		if (onClick) onClick();
		setFocus(false);
	}, [onClick]);
	return (
		<>
			{isResizing && (
				<div
					className={"custom-draggable-area"}
					style={{ height: "100%", width: "100%", position: "absolute", zIndex: 1000000, cursor: `${cursor}-resize` }}
				>
					<EventListener target={"window"} onMouseMove={onMouseMove} onMouseUp={onMouseUp} />
				</div>
			)}
			<div style={style} onClick={onRectClick} onMouseDown={onMouseDown} onMouseMove={onRectMouseMove}>
				{children}
			</div>
		</>
	);
});
