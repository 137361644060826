import * as React from "react";
import { JobPage, JobPageType } from "../../../components/pages/job";
import { initState, reducer } from "../../../reducers/job/all";
import { Loading } from "lu-component";
import { get, makeError, put } from "../../../lib/request";
import { GetJobAllResponse, PutJobStatusResponse } from "../../../../../server/types/request/job";
import { GetEnqueteTestResponse } from "../../../../../server/types/request/preview";
import { replaceEndpointUrl, endpoint } from "../../../../../server/router/endpoint";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { JobStatus } from "../../../../../server/models/status";
import { clientEndpoint } from "../../../routes/endpoint";
import { AccountWithoutPassword } from "../../../../../server/models/account";
import { JobAllPage, JobAllPageProps } from "../../../components/pages/job/all";
import { useHistory } from "react-router";

const getActivityId = (data: GetJobAllResponse["data"], jobId: string) => {
	for (const d of data) {
		if (d.jobs.some((job) => job._id.toString() === jobId)) {
			return d.activity._id.toString();
		}
	}
};
export const JobAllContainer = (loginAccount: AccountWithoutPassword) => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { info, loading, data, ...jobAllprops } = state;
	const history = useHistory();
	React.useEffect(() => {
		get<GetJobAllResponse>(endpoint.jobAll)
			.then((response) => {
				dispatch({ type: "loadJobs", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	const onEditPage = React.useCallback(
		(id: string) =>
			history.push(replaceEndpointUrl(clientEndpoint.jobCreate, { activityId: getActivityId(data, id), jobId: id })),
		[data]
	);
	const onRetest = React.useCallback(
		(id: string) =>
			history.push(
				replaceEndpointUrl(clientEndpoint.jobCreateRetest, { activityId: getActivityId(data, id), retestJobId: id })
			),
		[data]
	);
	const onChangePage = React.useCallback(
		(id: string, page: JobPageType) => {
			if (page === "enquete-test") {
				get<GetEnqueteTestResponse>(`${endpoint.enqueteTest}/${id}`)
					.then((response) => {
						window.open(response.data.url);
					})
					.catch((error) => {
						dispatch({ type: "changeMessageInfo", payload: makeError(error) });
					});
			} else {
				history.push(`/${page}/${getActivityId(data, id)}/${id}`);
			}
		},
		[data]
	);
	const onOutlinePage = React.useCallback(
		(id: string) => {
			history.push(replaceEndpointUrl(clientEndpoint.outline, { activityId: getActivityId(data, id), jobId: id }));
		},
		[data]
	);
	const onAggregatePage = React.useCallback(
		(id: string) => history.push(replaceEndpointUrl(clientEndpoint.aggtegateCreate, { jobId: id, aggregateId: "new" })),
		[]
	);
	const onJobStatusChange = React.useCallback((jobId: string, status: keyof typeof JobStatus) => {
		put<PutJobStatusResponse>(`${endpoint.jobStatus}/${jobId}`, { status })
			.then((response) => {
				dispatch({ type: "changeJobStatus", payload: { ...response.data, jobId } });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	const onChangeSearches = React.useCallback<JobAllPageProps["onChangeSearches"]>(
		(name, value) => dispatch({ type: "changeSearches", payload: { name, value } }),
		[]
	);
	return (
		<>
			<Loading loading={loading} />
			<ConfirmBox info={info} titleLabel={"処理"} />
			<JobAllPage
				{...jobAllprops}
				data={data}
				loginAccount={loginAccount}
				onEditPage={onEditPage}
				onChangePage={onChangePage}
				onOutlinePage={onOutlinePage}
				onJobStatusChange={onJobStatusChange}
				onRetest={onRetest}
				onChangeSearches={onChangeSearches}
				onAggregatePage={onAggregatePage}
			/>
		</>
	);
};
