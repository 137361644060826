import * as React from "react";
import { Row, Card, Button, Col } from "react-bootstrap";
import { NoMenuNavigationBar } from "../../parts/nomenu-navigation-bar";
import styled from "styled-components";

const MessageArea = styled.div`
	white-space: pre-wrap;
`;

export const MessagePage = (props: { children: React.ReactChild | React.ReactChild[] | React.ReactChildren }) => {
	return (
		<>
			<NoMenuNavigationBar />
			<Row>
				<Col md={{ span: 6, offset: 3 }}>
					<Card className={"m-5 pt-5 pb-5"}>
						<Card.Body>
							<MessageArea className="text-center">{props.children}</MessageArea>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	);
};
