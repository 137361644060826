import { ActivityWithBrand } from "../../../../../server/models/activity";
import { Job } from "../../../../../server/models/job";
import { Concept } from "../../../../../server/models/concept";
import { SimpleQuestion } from "muscat-library";
import { MessageInfo } from "../../../types/info";
import { JobDetailAction } from "../../../types/actions";

export type State = {
	activity: ActivityWithBrand;
	job: Job;
	evaluationItem?: string[];
	question?: SimpleQuestion;
	concepts: Concept[];
	estimate: string;
	loading: boolean;
	info: MessageInfo;
};

export const initState: State = {
	activity: undefined,
	job: undefined,
	estimate: undefined,
	concepts: [],
	loading: true,
	info: {
		isSuccess: true,
		message: "",
	},
};

export const reducer = (state: State, action: JobDetailAction): State => {
	switch (action.type) {
		case "loadJob":
			return {
				...state,
				loading: false,
				...action.payload,
			};
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return { ...state, loading: action.payload };
		default:
			return state;
	}
};
