import * as React from "react";
import { Text, Bar } from "../common";
import { Button } from "react-bootstrap";

import { EvaluationItem, ConceptEvaluationSampleSize } from "../../../../../../server/models/graph/concept-evaluation";
import styled from "styled-components";
import { array2CsvBlob, downloadData } from "../../../../lib/data";

const GraphWrap = styled.div`
	max-width: 1000px;
	margin: 0px auto;
`;

const areaWidth = 1000;
const baseBarStartX = 350;
const yTopPad = 100;
const baseBarWidth = 300;
const itemHeight = 30;
const barPad = 4;
const diffBarX = baseBarStartX + baseBarWidth + 80;
const rightPad = 40;
const diffbarAreaWidth = areaWidth - diffBarX - rightPad;
const height = itemHeight - barPad * 2;

const percent = (v: any) => {
	return typeof v === "number" ? v.toFixed(1) + "%" : v;
};

type BaseEvaluationProps = {
	max: number;
	areaHeight: number;
	step: number;
	items: EvaluationItem[];
	norm?: number;
};
const BaseEvaluation = React.memo((props: BaseEvaluationProps) => {
	const { max, areaHeight, step, items, norm } = props;
	const BaseLineGroup = React.useMemo(() => {
		let v = 0;
		const tmp: JSX.Element[] = [];
		while (v <= max) {
			const targetX = baseBarStartX + (baseBarWidth * v) / max;
			tmp.push(
				<g key={`line-${v}`}>
					<Text x={targetX} y={yTopPad - 20} value={v + "%"} fontSize={"12.5px"} />
					<line x1={targetX} x2={targetX} y1={yTopPad - 10} y2={yTopPad + 10} stroke="#ccc" />
					<line x1={targetX} x2={targetX} y1={yTopPad} y2={areaHeight} stroke="#ccc" strokeDasharray="3" />
				</g>
			);
			if (max !== v && v + step > max) {
				v = max;
			} else {
				v += step;
			}
		}
		return tmp;
	}, [max, step]);
	const NormLine = React.useMemo(() => {
		if (!norm) return <></>;
		const targetX = baseBarStartX + (baseBarWidth * norm) / max;
		return <line x1={targetX} x2={targetX} y1={yTopPad} y2={areaHeight} stroke="#222" strokeDasharray="3" />;
	}, [norm]);
	return (
		<>
			<Text
				x={baseBarStartX}
				y={20}
				value={"印象に残った点（MA、SA)"}
				textAnchor={"start"}
				fontSize={"17px"}
				fontWeight={"bold"}
				textDecoration={"underline"}
			/>
			<Bar x={baseBarStartX - 50} y={40} height={15} width={15} color={"#ffd587"} />
			<Text x={baseBarStartX - 30} y={48} value={"印象に残った要素(MA)"} textAnchor={"start"} fontSize={"11.5px"} />
			<Bar x={baseBarStartX + 100} y={40} height={15} width={15} color={"#f7a100"} />
			<Text x={baseBarStartX + 120} y={48} value={"最も・・・(SA)"} textAnchor={"start"} fontSize={"11.5px"} />
			{!!norm && (
				<>
					<line x1={baseBarStartX + 210} y1={48} x2={baseBarStartX + 230} y2={48} stroke="#222" strokeDasharray="3" />
					<Text x={baseBarStartX + 240} y={48} value={"過去平均(MA)"} textAnchor={"start"} fontSize={"11.5px"} />
				</>
			)}
			{BaseLineGroup}
			{NormLine}
			<line x1={baseBarStartX} y1={yTopPad} x2={baseBarStartX + baseBarWidth} y2={yTopPad} stroke="#999" />
			{items.map((item, index) => {
				const { sa, ma } = item.values;
				const saX = baseBarStartX + (baseBarWidth * sa) / max;
				const maX = baseBarStartX + (baseBarWidth * ma) / max;
				const saXText = ma !== sa && ma - sa <= 8 ? saX - 30 : saX;
				const areaY = yTopPad + itemHeight * index;
				const textY = height / 2 + barPad + areaY;
				const y = barPad + areaY;
				return (
					<g key={`evaluation-item-${index}`}>
						{/**
						<switch>
							{/**
							<foreignObject x={0} y={areaY} width={baseBarStartX - 10} height={itemHeight}>
								<p style={{ textAlign: "right", fontSize: "0.85em" }}>{item.label}</p>
							</foreignObject>
							 
						</switch>
						{/*<Text x={baseBarStartX - 10} y={textY} value={item.label} textAnchor={"end"} />*/}
						{/*<EvaluvationLabel x={baseBarStartX - 10} y={textY + 5} label={item.label} />
						<Text
							x={baseBarStartX - 10}
							y={textY + 5}
							value={item.label.length >= 24 ? item.label.slice(0, 23) + "..." : item.label}
							textAnchor={"end"}
							fontSize={"0.85em"}
							dominantBaseline={""}
						/>
						*/}
						{item.label
							.split("")
							.reduce((a, _, i) => (i % 35 ? a : [...a, item.label.slice(i, i + 35)]), [])
							.map((label, index) => {
								return (
									<Text
										key={`moji-${index}`}
										x={baseBarStartX - 10}
										y={areaY + (index + 1) * 10}
										value={label}
										textAnchor={"end"}
										fontSize={"9.5px"}
										dominantBaseline={""}
									/>
								);
							})}
						<Bar x={0} y={areaY} width={areaWidth} height={itemHeight} color={index % 2 ? "white" : "#ddd"} />
						<Bar x={baseBarStartX} y={y} width={maX - baseBarStartX} height={height} color={"#ffd587"} />
						<Text x={maX} y={textY} value={percent(ma)} textAnchor={"start"} fontSize={"12px"} />
						<Bar x={baseBarStartX} y={y} width={saX - baseBarStartX} height={height} color={"#f7a100"} />
						<Text x={saXText} y={textY} value={percent(sa)} textAnchor={"start"} fontSize={"12px"} />
					</g>
				);
			})}
		</>
	);
});

type DiffEvaluationProps = {
	items: EvaluationItem[];
	step: number;
	areaHeight: number;
};

const DiffEvaluation = React.memo((props: DiffEvaluationProps) => {
	const { items, step, areaHeight } = props;
	const max = React.useMemo(() => {
		const m = Math.max(...items.filter((item) => item.values.diff !== undefined).map((item) => item.values.diff));
		return Math.max(Math.ceil(m / 10) * 10, 0);
	}, [items]);
	const min = React.useMemo(() => {
		const m = Math.min(...items.filter((item) => item.values.diff !== undefined).map((item) => item.values.diff));
		const tmp = m / 10;
		if (tmp > 0) {
			return 0;
		} else {
			return Math.min(Math.floor(tmp) * 10, 100);
		}
	}, [items]);
	const BaseLineGroup = React.useMemo(() => {
		let v = min;
		const tmp: JSX.Element[] = [];
		while (v <= max) {
			const targetX = diffBarX + (diffbarAreaWidth * (v - min)) / (max - min);
			tmp.push(
				<g key={`diff-line-${v}`}>
					<Text x={targetX} y={yTopPad - 20} value={v + "%"} fontSize={"12.5px"} />
					<line x1={targetX} x2={targetX} y1={yTopPad - 10} y2={yTopPad + 10} stroke="#ccc" />
					<line x1={targetX} x2={targetX} y1={yTopPad} y2={areaHeight} stroke="#ccc" strokeDasharray="3" />
				</g>
			);
			if (max !== v && v + step > max) {
				v = max;
			} else {
				v += step;
			}
		}
		return tmp;
	}, [max, min, step]);
	return (
		<>
			<Text
				x={diffBarX}
				y={20}
				value={"印象に残った点（MA)"}
				textAnchor={"start"}
				fontSize={"17px"}
				fontWeight={"bold"}
				textDecoration={"underline"}
			/>
			<Text
				x={diffBarX}
				y={40}
				value={"購入意向者と非購入意向者との差"}
				textAnchor={"start"}
				fontSize={"17px"}
				fontWeight={"bold"}
				textDecoration={"underline"}
			/>
			<Text
				x={diffBarX + diffbarAreaWidth / 2 - 30}
				y={60}
				textAnchor={"start"}
				value={"（購入意向者(T2B)-非購入意向者(B3B)）"}
				fontSize={"9.5px"}
			/>
			<line x1={diffBarX} y1={yTopPad} x2={diffBarX + diffbarAreaWidth} y2={yTopPad} stroke="#999" />
			{BaseLineGroup}
			{items.map((item, index) => {
				if (item.values.diff === undefined) return <></>;
				const zeroX = min > 0 ? diffBarX : diffBarX + Math.abs(diffbarAreaWidth * (min / (max - min)));
				const value = (diffbarAreaWidth * item.values.diff) / (max - min);
				const x = diffBarX + value;
				const areaY = yTopPad + itemHeight * index;
				const y = barPad + areaY;
				const textY = height / 2 + barPad + areaY;
				const barStartX = value < 0 ? zeroX + value : zeroX;
				const width = Math.abs(x - diffBarX);
				return (
					<g key={`diff-evaluation-item-${index}`}>
						<Bar x={barStartX} y={y} width={width} height={height} color={"red"} />
						<Text
							x={value < 0 ? barStartX - 25 : barStartX + width}
							y={textY}
							value={percent(item.values.diff)}
							textAnchor={"start"}
							fontSize={"12px"}
						/>
					</g>
				);
			})}
		</>
	);
});

export type ConceptEvaluationProps = {
	filename?: string;
	label: string;
	sampleSize: ConceptEvaluationSampleSize;
	norm?: number;
	items: EvaluationItem[];
	step?: number;
	width?: number;
	hideDiff?: boolean;
};

export const ConceptEvaluation = React.memo((props: ConceptEvaluationProps) => {
	const { items, norm, label, sampleSize, width, hideDiff, filename = "印象に残った点", step = 10 } = props;
	const areaHeight = React.useMemo(() => {
		return items.length * itemHeight + yTopPad;
	}, [items]);
	const max = React.useMemo(() => {
		const m = Math.max(...items.map((item) => item.values.ma));
		return Math.min(Math.round(m / 10) * 10 + 10, 100);
	}, [items]);
	const attribute = React.useMemo(() => {
		if (width) {
			const height = Math.round((width / areaWidth) * areaHeight);
			return { width, height };
		}
		return {};
	}, [width, areaHeight]);
	const onDownload = React.useCallback(() => {
		const tmp = array2CsvBlob([
			["要素", "印象に残った点（MA）", "印象に残った点（SA）", "印象に残った点（MA)購入意向者と非購入意向者との差"],
			...items.map(({ label, values }) => {
				const { ma, sa, diff } = values;
				//return [label, ma.toFixed(1) + "%", sa.toFixed(1) + "%", diff === undefined ? "" : diff.toFixed(1) + "%"];
				return [label, ma.toFixed(1), sa.toFixed(1), diff === undefined ? "" : diff.toFixed(1)];
			}),
		]);
		downloadData(tmp, `${filename || "印象に残った点"}.csv`);
	}, [items, filename]);
	const svgRef = React.useRef<SVGSVGElement>();
	const onDownloadImage = React.useCallback(() => {
		if (!svgRef.current) return;
		const svg = svgRef.current;
		const svgData = new XMLSerializer().serializeToString(svg);
		const canvas = document.createElement("canvas");
		canvas.width = svg.width.baseVal.value;
		canvas.height = svg.height.baseVal.value;

		const ctx = canvas.getContext("2d");
		const image = new Image();
		image.onload = function () {
			ctx.drawImage(image, 0, 0);
			canvas.toBlob((blob) => {
				downloadData(blob, `${filename || "印象に残った点"}.png`);
			});
		};
		image.src = "data:image/svg+xml;charset=utf-8;base64," + btoa(unescape(encodeURIComponent(svgData)));
	}, [svgRef]);
	return (
		<GraphWrap>
			<Button size={"sm"} variant={"outline-secondary"} onClick={onDownload}>
				Download
			</Button>
			<Button size={"sm"} variant={"outline-secondary"} onClick={onDownloadImage} style={{ marginLeft: "10px" }}>
				画像Download
			</Button>
			<svg viewBox={`0 0 ${areaWidth} ${areaHeight}`} {...attribute} ref={svgRef}>
				<Text x={0} y={20} value={label} textAnchor={"start"} fontSize={"17px"} fontWeight={"bold"} />
				<Text
					x={150}
					y={25}
					value={`（${sampleSize.total}s）`}
					fontSize={"11px"}
					textAnchor={"middle"}
					fill={sampleSize.total < 30 ? "red" : "black"}
				/>
				<Text x={150} y={40} value={`購入意向者：${sampleSize.n.buy}s`} fontSize={"11px"} textAnchor={"middle"} />
				<Text x={150} y={55} value={`非購入意向者：${sampleSize.n.noBuy}s`} fontSize={"11px"} textAnchor={"middle"} />
				<BaseEvaluation max={max} areaHeight={areaHeight} step={step} items={items} norm={norm} />
				{!hideDiff && <DiffEvaluation items={items} step={step} areaHeight={areaHeight} />}
			</svg>
		</GraphWrap>
	);
});
