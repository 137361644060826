import * as React from "react";
import { Button } from "react-bootstrap";
import { Role } from "../../../../../../../server/lib/permission/role";
import { AccountWithoutPassword } from "../../../../../../../server/models/account";
import { ActivityWithBrand } from "../../../../../../../server/models/activity";
import { ConceptType } from "../../../../../../../server/models/concept";
import { Job } from "../../../../../../../server/models/job";
import {
	SalesVolumePredictionData,
	GetSimulationResponse,
} from "../../../../../../../server/types/request/report/simulation";
import { makeRate } from "../../../../../../../server/lib/common";
import { CorrectionValueSetting, CorrectionValueSettingProps } from "../correction-value";
import { NormTable } from "../norm-table";
import { SimulationResult } from "../result";

export type SimulationOneProps = {
	loginAccount: AccountWithoutPassword;
	job: Job;
	activity: ActivityWithBrand;
	target: "test1" | "test2";
	onSimulation: () => void;
	onSimulationSave: () => void;
	onChangeRatio: CorrectionValueSettingProps["onChangeRatio"];
	data: SalesVolumePredictionData;
	oneNumberScoreNorm: GetSimulationResponse["oneNumberScoreNorm"];
	oneNumberScoreData: GetSimulationResponse["oneNumberScoreData"];
	constValue: GetSimulationResponse["constValue"];
	goalSalesVolume: number;
	maxRecognition: number;
};

export const SimulationOne = React.memo((props: SimulationOneProps) => {
	const {
		loginAccount,
		data,
		job,
		activity,
		target,
		oneNumberScoreNorm,
		oneNumberScoreData,
		goalSalesVolume,
		constValue,
		onSimulation,
		onSimulationSave,
		onChangeRatio,
		maxRecognition,
	} = props;
	const { value } = data;
	const initLocalRatio = React.useMemo(() => makeRate(value.o, value.p, value.q + value.r + value.s), [value]);
	const initInboundRatio = React.useMemo(() => makeRate(value.o, value.p, value.t), [value]);
	// 相対パスだとサーバ側でよむことができないので、絶対パスで画像を読み込む必要がある。
	const baseUrl = React.useMemo(() => location.origin, []);
	const hasEditRole = React.useMemo(
		() =>
			// 管理者 or ブランドメンバー or ブランドマネージャーは対象
			Role.isAdmin(loginAccount) ||
			Role.isActivityMember(loginAccount, activity) ||
			Role.isActivityBrandManager(loginAccount, activity),
		[activity, loginAccount]
	);
	return (
		<>
			{hasEditRole && (
				<>
					<Button onClick={onSimulationSave} variant={"outline-secondary"} style={{ margin: "10px 0px" }}>
						保存
					</Button>
					<Button onClick={onSimulation} variant={"outline-secondary"} style={{ margin: "10px" }}>
						画像Download
					</Button>
				</>
			)}
			<table className={"simulation-table"}>
				<tr>
					<td className={"concept-title"}>提示コンセプト：{ConceptType[target]}</td>
					<td className={"jobno"}>
						JOB#: {job.jobNum}（{activity.category.name}、{activity.brand.name}）
					</td>
				</tr>
				<tr>
					<td className={"main"}>
						<div className={"image-area"}>
							<img src={data.conceptUrl} />
						</div>
					</td>
					<td className={"main all-result"}>
						<table className={"sub-table"}>
							<tbody>
								<tr>
									<td className={"title-area"}>
										<img src={`${baseUrl}/images/title1.png`} />
									</td>
									<td>
										<div className={"graph-area"}>
											<NormTable
												target={target}
												oneNumberScoreNorm={oneNumberScoreNorm}
												oneNumberScoreData={oneNumberScoreData}
												activity={activity}
												job={job}
											/>
											<div className={"explain"}>
												＊購入意向はTB,T2Bをウエイト処理したワンナンバースコアで表示/計算　＊ノームランクは、A:上位20%,
												B:20%, C:30%, D:下位30%＝再テスト基準
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
						<hr className={"dashed"} />
						<table className={"sub-table"}>
							<tbody>
								<tr>
									<td className={"title-area"}>
										<img src={`${baseUrl}/images/title2.png`} />
									</td>
									<td>
										<div className={"graph-area"}>
											<CorrectionValueSetting
												hasEditRole={hasEditRole}
												maxRecognition={maxRecognition}
												data={data}
												initLocalRatio={initLocalRatio}
												initInboundRatio={initInboundRatio}
												onChangeRatio={onChangeRatio}
											/>
											<SimulationResult
												data={data}
												goalSalesVolume={goalSalesVolume}
												constValue={constValue}
												initLocalRatio={initLocalRatio}
												initInboundRatio={initInboundRatio}
											/>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</td>
				</tr>
			</table>
		</>
	);
});
