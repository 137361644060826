import * as React from "react";
import { JobPage, JobPageType } from "../../../components/pages/job";
import { initState, reducer } from "../../../reducers/job/list";
import { useHistory, useParams } from "react-router";
import { Loading } from "lu-component";
import { get, makeError, put } from "../../../lib/request";
import { GetJobsResponse, PutJobStatusResponse } from "../../../../../server/types/request/job";
import { GetEnqueteTestResponse } from "../../../../../server/types/request/preview";
import { replaceEndpointUrl, endpoint } from "../../../../../server/router/endpoint";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { JobStatus } from "../../../../../server/models/status";
import { clientEndpoint } from "../../../routes/endpoint";
import { AccountWithoutPassword } from "../../../../../server/models/account";

export const JobListContainer = (loginAccount: AccountWithoutPassword) => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { info, loading, ...jobPageProps } = state;
	const history = useHistory();
	const { activityId } = useParams<{ activityId: string }>();
	React.useEffect(() => {
		get<GetJobsResponse>(replaceEndpointUrl(endpoint.loadJob, { activityId }))
			.then((response) => {
				dispatch({ type: "loadJobs", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	const onEditPage = React.useCallback(
		(id: string) => history.push(replaceEndpointUrl(clientEndpoint.jobCreate, { activityId, jobId: id })),
		[]
	);
	const onRetest = React.useCallback(
		(id: string) => history.push(replaceEndpointUrl(clientEndpoint.jobCreateRetest, { activityId, retestJobId: id })),
		[]
	);
	const onChangePage = React.useCallback((id: string, page: JobPageType) => {
		if (page === "enquete-test") {
			get<GetEnqueteTestResponse>(`${endpoint.enqueteTest}/${id}`)
				.then((response) => {
					window.open(response.data.url);
				})
				.catch((error) => {
					dispatch({ type: "changeMessageInfo", payload: makeError(error) });
				});
		} else {
			history.push(`/${page}/${activityId}/${id}`);
		}
	}, []);
	const onOutlinePage = React.useCallback((id: string) => {
		history.push(replaceEndpointUrl(clientEndpoint.outline, { activityId, jobId: id }));
	}, []);
	const onJobStatusChange = React.useCallback((jobId: string, status: keyof typeof JobStatus) => {
		put<PutJobStatusResponse>(`${endpoint.jobStatus}/${jobId}`, { status })
			.then((response) => {
				dispatch({ type: "changeJobStatus", payload: { ...response.data, jobId } });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	return (
		<>
			<Loading loading={loading} />
			<ConfirmBox info={info} titleLabel={"処理"} />
			<JobPage
				{...jobPageProps}
				loginAccount={loginAccount}
				onEditPage={onEditPage}
				onChangePage={onChangePage}
				onOutlinePage={onOutlinePage}
				onJobStatusChange={onJobStatusChange}
				onRetest={onRetest}
			/>
		</>
	);
};
