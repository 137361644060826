import * as React from "react";
import { reducer, initState } from "../../../reducers/job/detail";
import { JobDetailPage } from "../../../components/pages/job/detail";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { Loading } from "lu-component";
import { useHistory, useParams } from "react-router";
import { get, makeError } from "../../../lib/request";
import { GetJobDetailResponse } from "../../../../../server/types/request/job-detail";
import { endpoint } from "../../../../../server/router/endpoint";

export const JobDetailContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, ...jobDetailProps } = state;
	const history = useHistory();
	const { jobId, activityId } = useParams<{ jobId: string; activityId: string }>();
	React.useEffect(() => {
		get<GetJobDetailResponse>(`${endpoint.jobDetail}/${jobId}`)
			.then((response) => {
				dispatch({ type: "loadJob", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	const onJobList = React.useCallback(() => history.push(`/activity/${activityId}/job`), []);
	return (
		<>
			<Loading loading={loading} />
			<ConfirmBox info={info} onClose={onJobList} />
			{!!jobDetailProps.activity && <JobDetailPage {...jobDetailProps} onJobList={onJobList} />}
		</>
	);
};
