import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Button } from "react-bootstrap";
import { ButtonEvent } from ".";

/*/
import styled from "styled-components";
const DeleteBtn = styled.button`
	border-radius: 25px;
	border: 0px;
	width: 25px;
	height: 25px;
	font-size: 10px;
`;
/*/

export const DeleteButton = React.memo((props: ButtonEvent) => {
	const { onClick, text } = props;
	return (
		<Button
			variant="light"
			onClick={(e) => {
				e.stopPropagation();
				onClick();
			}}
			style={{ color: "#6c757d" }}
		>
			<FontAwesomeIcon icon={faTrash} />
			{text}
		</Button>
	);
});
