import * as React from "react";
import { Button } from "react-bootstrap";
import { AggregateSetting, AggregateSettingProps } from "../../../parts/aggregate-setting";
import { ButtonArea } from "../../../parts/aggregate-setting/common";
import { Title } from "../title";

export type AggregateCreatePageProps = AggregateSettingProps & {
	title: string;
	onSave: () => void;
	onDownload?: () => void;
	onView?: () => void;
	onList: () => void;
};
export const AggregateCreatePage = React.memo((props: AggregateCreatePageProps) => {
	const { title, onSave, onDownload, onView, onList, ...aProps } = props;
	return (
		<>
			<Title>{title}</Title>
			<AggregateSetting {...aProps} />
			<ButtonArea>
				<Button variant={"outline-secondary"} onClick={onSave}>
					保存
				</Button>
				{onDownload && (
					<Button variant={"outline-secondary"} onClick={onDownload}>
						ダウンロード
					</Button>
				)}
				{onView && (
					<Button variant={"outline-secondary"} onClick={onView}>
						閲覧
					</Button>
				)}
				<Button variant={"outline-secondary"} onClick={onList}>
					一覧へ戻る
				</Button>
			</ButtonArea>
		</>
	);
});
