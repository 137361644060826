import * as React from "react";
import { Loading } from "lu-component";
import { reducer, initState } from "../../reducers/info";
import { ConfirmBox } from "../../components/parts/confirm-box";
import { InfoPage } from "../../components/pages/info";
import { get, makeError } from "../../lib/request";
import { endpoint } from "../../../../server/router/endpoint";
import { GetInfoResponse } from "../../../../server/types/request/info";

export const InfoContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, ...infoPageProps } = state;
	/*/
	React.useEffect(() => {
		get<GetInfoResponse>(endpoint.info)
			.then((response) => dispatch({ type: "loadInfo", payload: response.data }))
			.catch((error) => dispatch({ type: "changeMessageInfo", payload: makeError(error) }));
	}, []);
	/*/
	return (
		<>
			{/*<Loading loading={loading} />*/}
			{!info.isSuccess && <ConfirmBox info={info} />}
			<InfoPage {...infoPageProps} />
		</>
	);
};
