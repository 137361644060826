import { Qtype, Question, QuotaQueTag } from "muscat-library";
import * as React from "react";
import { Row, Col, FormGroup, FormLabel, Button, Alert, ButtonGroup } from "react-bootstrap";
import { Option } from "../../../../../../../server/types/request";
import { SingleSelect } from "../../../select";
import { SelectionGroup } from "muscat-aggregate-library";
import { AddSelectionButtonArea, ExpandButtonArea } from "../../common";
import { AddButton } from "../../../button";
import { InputText } from "../../../input";
import { replaceTag } from "../../../../../../../server/lib/common";
import { ErrorObject } from "../../../../../../../server/validation/validation-base";
import { getErrorMessage } from "../../../../../lib/error";
import { GroupSelectionAccordion } from "./group-selection-accordion";
import { SelectionItem } from "./selection-item";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

export type GroupSelectionItemProps = {
	questions: Question[];
	selectionGroup: SelectionGroup;
	errors?: ErrorObject;
	onChange: <T extends keyof SelectionGroup>(name: T, value: SelectionGroup[T]) => void;
	onDelete: () => void;
};

export const GroupSelectionItem = React.memo((props: GroupSelectionItemProps) => {
	const { questions, selectionGroup, onChange, errors, onDelete } = props;
	const targetQuestion = React.useMemo(() => {
		return questions.find((question) => question.quename === selectionGroup.quename);
	}, [questions, selectionGroup]);
	const questionOptions = React.useMemo<Option[]>(() => {
		const options: Option[] = [];
		for (const question of questions) {
			if (question.type === "E" || question.type === "FI" || question.type === "O" || question.type === "MT") continue;
			options.push({ value: question.quename, label: `${question.quename}.${replaceTag(question.quetitle)}` });
		}
		return options;
	}, [questions]);
	const qType = React.useMemo<Qtype>(() => {
		if (!selectionGroup.quename) return undefined;
		const question = questions.find((question) => question.quename === selectionGroup.quename);
		return question.type;
	}, [questions, selectionGroup]);
	const options = React.useMemo<Option[]>(() => {
		if (!selectionGroup.quename) return [];
		const question = questions.find((question) => question.quename === selectionGroup.quename);
		if (question.type === "M" || question.type === "S") {
			return question.choice_group.reduce((a, b) => {
				return [
					...a,
					...b.choices.map(({ value, text }) => ({
						value,
						label: replaceTag(text),
					})),
				];
			}, []);
		}
		return [];
	}, [questions, selectionGroup]);
	const onChangeSelection = React.useCallback(
		(index: number, target: "label" | "from" | "to" | "values" | "not" | "and", value: any) => {
			onChange(
				"grouping",
				selectionGroup.grouping.map((group, gIndex) => {
					if (index !== gIndex) return group;
					return { ...group, [target]: value };
				})
			);
		},
		[onChange, selectionGroup]
	);
	const onAgeExpand = React.useCallback(() => {
		onChange("grouping", [
			{ label: "15~19歳", from: 15, to: 19 },
			{ label: "20~24歳", from: 20, to: 24 },
			{ label: "25~29歳", from: 25, to: 29 },
			{ label: "30~34歳", from: 30, to: 34 },
			{ label: "35~39歳", from: 35, to: 39 },
			{ label: "40~44歳", from: 40, to: 44 },
			{ label: "45~49歳", from: 45, to: 49 },
			{ label: "50~54歳", from: 50, to: 54 },
			{ label: "55~59歳", from: 55, to: 59 },
			{ label: "60~64歳", from: 60, to: 64 },
			{ label: "65~69歳", from: 65, to: 69 },
		]);
	}, [onChange, selectionGroup]);
	const onAge10Expand = React.useCallback(() => {
		onChange("grouping", [
			{ label: "10代", from: 15, to: 19 },
			{ label: "20代", from: 20, to: 29 },
			{ label: "30代", from: 30, to: 39 },
			{ label: "40代", from: 40, to: 49 },
			{ label: "50代", from: 50, to: 59 },
			{ label: "60代", from: 60, to: 69 },
		]);
	}, [onChange, selectionGroup]);

	const onDeleteSelection = React.useCallback(
		(index: number) => {
			onChange(
				"grouping",
				selectionGroup.grouping.filter((group, gIndex) => {
					return index !== gIndex;
				})
			);
		},
		[onChange, selectionGroup]
	);
	const onAddSelection = React.useCallback(
		(index: number) => {
			const tmp = [...selectionGroup.grouping];
			if (options.length) {
				tmp.splice(index, 0, { label: "", values: [] });
			} else {
				tmp.splice(index, 0, { label: "", from: undefined, to: undefined });
			}
			onChange("grouping", tmp);
		},
		[onChange, selectionGroup, options]
	);
	const onAddSelectionLast = React.useCallback(() => {
		onAddSelection(selectionGroup.grouping.length);
	}, [onAddSelection, selectionGroup]);
	const groupingErrorMessage = React.useMemo(() => {
		return getErrorMessage("grouping", errors);
	}, [errors]);
	return (
		<GroupSelectionAccordion headerText={selectionGroup.label} onDelete={onDelete}>
			<FormGroup>
				<div>
					<FormLabel>グループ名</FormLabel>
					<InputText
						value={selectionGroup.label}
						onChange={(v) => onChange("label", v)}
						error={getErrorMessage("label", errors)}
					/>
				</div>
			</FormGroup>
			<FormGroup>
				<div>
					<FormLabel>設問</FormLabel>
					<SingleSelect
						value={selectionGroup.quename}
						options={questionOptions}
						onChange={(value) => onChange("quename", value !== undefined ? value.toString() : undefined)}
						error={getErrorMessage("quename", errors)}
					/>
				</div>
			</FormGroup>
			{!!groupingErrorMessage && (
				<Row style={{ marginTop: "0.6rem" }}>
					<Col md={{ offset: 1, span: 10 }}>
						<Alert variant={"danger"}>{groupingErrorMessage}</Alert>
					</Col>
				</Row>
			)}
			<ExpandButtonArea>
				{targetQuestion && targetQuestion.quota_que_tag === QuotaQueTag.age && (
					// {true && (
					<ButtonGroup>
						<Button variant={"secondary"} onClick={onAgeExpand} size={"sm"}>
							<FontAwesomeIcon icon={faCaretDown} />
							5歳刻みで展開
						</Button>
						<Button variant={"secondary"} onClick={onAge10Expand} size={"sm"}>
							<FontAwesomeIcon icon={faCaretDown} />
							10歳刻みで展開
						</Button>
					</ButtonGroup>
				)}
			</ExpandButtonArea>

			{selectionGroup.grouping.map((group, index) => (
				<SelectionItem
					key={`${selectionGroup.name}-group-${index}`}
					index={index}
					errors={errors}
					selectionGroup={selectionGroup}
					group={group}
					options={options}
					qType={qType}
					onAddSelection={onAddSelection}
					onChangeSelection={onChangeSelection}
					onDeleteSelection={onDeleteSelection}
				/>
			))}
			<AddSelectionButtonArea>
				<AddButton onClick={onAddSelectionLast} text={"選択肢を追加"} />
				{/*targetQuestion && targetQuestion.quota_que_tag === QuotaQueTag.age && (
					<>
						<Button variant={"outline-secondary"} onClick={onAgeExpand} size={"sm"}>
							5歳刻みで展開
						</Button>
						<Button variant={"outline-secondary"} onClick={onAge10Expand} size={"sm"}>
							10歳刻みで展開
						</Button>
					</>
				)*/}
			</AddSelectionButtonArea>
		</GroupSelectionAccordion>
	);
});
