import * as React from "react";
import { SuccessPasswordResetPage } from "../../components/pages/success-password-reset";
import { useHistory } from "react-router";
import { clientEndpoint } from "../../routes/endpoint";

export const SuccessPasswordResetContainer = () => {
	const history = useHistory();
	const changeLocation = React.useCallback(() => {
		history.push(clientEndpoint.login);
	}, []);
	return <SuccessPasswordResetPage changeLocation={changeLocation} />;
};
