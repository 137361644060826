import * as React from "react";
import { SellPotential } from "../../../../../../../server/models/graph/demand-forecast";
import { StandardLine, StockedBarGraph, StokedBarGroup } from "../../../../parts/graph/stocked-bar-graph";

import { ResultTable } from "./result-table";
import { GoalTable } from "./goal-table";
import { UserTable } from "./user-table";
import {
	SalesVolumeConstValue,
	SalesVolumePredictionData,
} from "../../../../../../../server/types/request/report/simulation";
import { isDefined } from "../../../../../lib/data";

export type SimulationResultProps = {
	data: SalesVolumePredictionData;
	constValue: SalesVolumeConstValue;
	goalSalesVolume: number;
	initLocalRatio: number;
	initInboundRatio: number;
};

export const SimulationResult = React.memo((props: SimulationResultProps) => {
	const { data, goalSalesVolume, constValue, initLocalRatio, initInboundRatio } = props;
	const sellPotentials = React.useMemo<
		[{ label: string; sellPotential: SellPotential }, { label: string; sellPotential: SellPotential }]
	>(() => {
		const { value, correctionValue } = data;
		const noChange =
			!isDefined(correctionValue.recognitionRate) &&
			!isDefined(correctionValue.purchaseQuantity) &&
			!isDefined(correctionValue.localRatio) &&
			!isDefined(correctionValue.inboundRatio);
		const recognitionRate = isDefined(correctionValue.recognitionRate) ? correctionValue.recognitionRate : value.g;
		const purchaseQuantity = isDefined(correctionValue.purchaseQuantity) ? correctionValue.purchaseQuantity : value.h;
		const localRatio = isDefined(correctionValue.localRatio) ? correctionValue.localRatio : initLocalRatio;
		const inboundRatio = isDefined(correctionValue.inboundRatio) ? correctionValue.inboundRatio : initInboundRatio;
		const baseTrial =
			(value.m * value.e * constValue.nonUser + value.n * value.f * constValue.user) * recognitionRate * 100;
		const missingDeclaration = value.v || 0;
		const missingDeclarationTrial = baseTrial * missingDeclaration;
		const trial = baseTrial + missingDeclarationTrial;
		const baseRepeat = baseTrial * (purchaseQuantity - 1);
		const missingDeclarationRepeat = baseRepeat * missingDeclaration;
		const repeat = baseRepeat + missingDeclarationRepeat;
		const sumTrialRepeat = trial + repeat;
		const local = sumTrialRepeat * localRatio;
		const inbound = sumTrialRepeat * inboundRatio;
		return [
			{
				label: "初期値（万個）",
				sellPotential: {
					total: value.u,
					trial: value.o,
					repeat: value.p,
					localOther: value.q + value.r + value.s,
					inbound: value.t,
				},
			},
			{
				label: "補整値（万個）",
				sellPotential: noChange
					? {
							total: undefined,
							trial: undefined,
							repeat: undefined,
							localOther: undefined,
							inbound: undefined,
					  }
					: {
							total: sumTrialRepeat + local + inbound,
							trial,
							repeat,
							localOther: local,
							inbound: inbound,
					  },
			},
		];
	}, [data, constValue, initLocalRatio, initInboundRatio]);
	const salesData = React.useMemo<StokedBarGroup[]>(() => {
		return sellPotentials.map(({ label, sellPotential }) => {
			return {
				label,
				total: sellPotential.total,
				bars: [
					{ label: "トライアル", value: sellPotential.trial, color: "#f56302" },
					{ label: "リピート", value: sellPotential.repeat, color: "#ffc000" },
					{ label: "ローカルその他", value: sellPotential.localOther, color: "#83db4d" },
					{ label: "インバウンド", value: sellPotential.inbound, color: "#6492e3" },
				],
			};
		});
	}, [sellPotentials]);
	const lines = React.useMemo<StandardLine[]>(() => {
		return [
			{
				value: goalSalesVolume,
				color: "red",
				label: "目標値（万個）",
				width: 2,
			},
			{
				value: (goalSalesVolume * 10 * 5) / 100,
				color: "red",
				label: "下限値（万個）",
				dashed: true,
				width: 2,
			},
		];
	}, [goalSalesVolume]);
	const graphBase = React.useMemo(() => {
		const tmp = Math.max(...salesData.map((d) => d.bars.reduce((a, b) => a + (b.value || 0), 0)), goalSalesVolume);
		if (tmp < 10) {
			const max = Math.floor(tmp + 1);
			const step = Math.floor((max / 10) * 100) / 100;
			return { max, step };
		}
		const max = Math.ceil(Math.round(tmp / 10) + 1) * 10;
		let step = Math.ceil(max / 10);
		if (step > 5 && step < 10) {
			step = 10;
		} else if (step % 10 !== 0) {
			step = Math.ceil(step / 10) * 10;
		}
		return { max, step };
	}, [data, goalSalesVolume]);
	return (
		<table className={"wrap-table"}>
			<tbody>
				<tr>
					<td className={"table-area"}>
						<UserTable salesVolumePrediction={data.value} constValue={constValue} />
						<GoalTable goalSalesVolume={goalSalesVolume} />
						<ResultTable sellPotentials={sellPotentials} goalSalesVolume={goalSalesVolume} />
					</td>
					<td>
						<StockedBarGraph
							hidePrecedent
							height={410}
							width={500}
							lines={lines}
							barGroups={salesData}
							max={graphBase.max}
							step={graphBase.step}
							unit={"（年間数量：単位　万個）"}
						/>
					</td>
				</tr>
			</tbody>
		</table>
	);
});
