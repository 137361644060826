import { Quota } from "muscat-library";
import { MessageInfo } from "../../../types/info";
import { QuotaAction } from "../../../types/actions";

export type State = {
	quotas: Quota[];
	loading: boolean;
	info: MessageInfo;
};

export const initState: State = {
	quotas: [],
	loading: true,
	info: {
		message: "",
		isSuccess: true,
	},
};

export const reducer = (state: State, action: QuotaAction): State => {
	switch (action.type) {
		case "setQuotas":
			return {
				...state,
				loading: false,
				quotas: action.payload,
			};
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return { ...state, loading: action.payload };
		default:
			return state;
	}
};
