import { ObjectId } from "mongodb";
import { CategoryGroup, Age, Gender } from "./activity";
import { Choice } from "muscat-library";
import { Option } from "../types/request";

export type CategoryType = "skincare" | "foundation" | "antiperspirant" | "liquid-foundation" | "other";

export interface GeneralGroupQuota {
	age: Age;
	gender: keyof typeof Gender;
	sampleSize: number;
	isUser: boolean;
}

type GenderKeys = keyof typeof Gender;

export const BigTitle = {
	menOnly: "男性用",
	womenOnly: "女性用",
	both: "男女共用／女性用",
} as const;

export type SurveyChoice = {
	value: number;
	label: string;
};

export type CategorySurveyChoice = SurveyChoice & {
	bigTitle?: keyof typeof BigTitle;
	smallTitle?: string; //「あ行、か行」等の小見出し
};
export type CategoryBrand = {
	brandName: string;
	surveyChoice: CategorySurveyChoice[];
};

export type CategoryRecognition = {
	nBrand: number;
	nCategoryUPI: number;
	sCategoryUPI: string;
	sBrandUPI2: string;
	bShiseido?: boolean; // 今後なくなるため、optionalにしておく。
	bMajor: boolean;
	gAwareness: number;
};

export interface Category {
	_id?: ObjectId | string;
	name: string;
	order: number;
	gender: GenderKeys;
	brandlist: CategoryBrand[];
	type: CategoryType;
	priceAppearance: CategoryGroup[];
	categoryRecognition: CategoryRecognition[];
	generalGroupQuota: GeneralGroupQuota[];
	surveyChoice: SurveyChoice[];
}

export const priceList = [100, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500, 6000, 8000, 10000];
export const priceChoices: (Choice & {
	from: number;
	to: number | undefined;
})[] = priceList
	.map((price, index) => {
		if (index === 0) {
			return {
				value: index + 1,
				text: `${price.toLocaleString()}円未満`,
				from: 0,
				to: price - 1,
			};
		}
		return {
			value: index + 1,
			text: `${priceList[index - 1].toLocaleString()}～${price.toLocaleString()}円未満`,
			from: priceList[index - 1],
			to: price - 1,
		};
	})
	.concat({
		value: priceList.length + 1,
		text: `${priceList[priceList.length - 1].toLocaleString()}円以上`,
		from: priceList[priceList.length - 1],
		to: undefined,
	});

export const toPriceOptions: Option[] = priceList
	.map((price) => {
		const p = price - 1;
		return {
			value: p,
			label: `${p.toLocaleString()}円`,
		};
	})
	.concat([{ value: undefined, label: "上限を設定しない" }]);
export const fromPriceOptions: Option[] = [{ value: 1, label: "1円" }].concat(
	priceList.map((price, index) => {
		return {
			value: price,
			label: `${price.toLocaleString()}円${index - 1 === priceList.length ? "以上" : ""}`,
		};
	})
);
