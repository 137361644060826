import * as React from "react";
import { ActivityCreatePage } from "../../../components/pages/activity/create";
import { SimpleActivity, CategoryGroup, StrategyGroup } from "../../../../../server/models/activity";
import { get, makeError, post, put } from "../../../lib/request";
import {
	GetActivityResponse,
	UpsertActivityResponse,
	UpsertActivityRequest,
} from "../../../../../server/types/request/activity";
import { endpoint } from "../../../../../server/router/endpoint";
import { useParams, useHistory, Prompt } from "react-router";
import { AxiosResponse } from "axios";
import { Loading } from "lu-component";
import { reducer, initState } from "../../../reducers/activity/create";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { clientEndpoint } from "../../../routes/endpoint";
import { AccountWithoutPassword } from "../../../../../server/models/account";

export const ActivityCreateContainer = (loginAccount: AccountWithoutPassword) => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const {
		activity,
		accounts,
		brands,
		categories,
		categoryTargetGroup,
		strategyTargetGroup,
		info,
		loading,
		cantEditMessage,
		hasStrategyGroup,
	} = state;
	const onChangeActivity = React.useCallback(
		(name: keyof SimpleActivity, value: SimpleActivity[keyof SimpleActivity]) => {
			dispatch({ type: "changeActivity", payload: { name, value } });
		},
		[]
	);
	const onChangeCategoryGroup = React.useCallback(
		(name: keyof CategoryGroup, value: CategoryGroup[keyof CategoryGroup]) => {
			dispatch({ type: "changeCategoryGroup", payload: { name, value } });
		},
		[]
	);
	const onChangeStrategyGroup = React.useCallback(
		(name: keyof StrategyGroup, value: StrategyGroup[keyof StrategyGroup]) => {
			dispatch({ type: "changeStrategyGroup", payload: { name, value } });
		},
		[]
	);
	const onChangeHasStrategy = React.useCallback((checked: boolean) => {
		dispatch({ type: "changeHasStrategy", payload: checked });
	}, []);

	const { activityId } = useParams<{ activityId: string }>();
	React.useEffect(() => {
		get<GetActivityResponse>(`${endpoint.activity}/${activityId}`)
			.then((response) => {
				dispatch({ type: "loadActivity", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);

	const onSubmit = React.useCallback(() => {
		dispatch({ type: "changeLoading", payload: true });
		let request: Promise<AxiosResponse<UpsertActivityResponse>>;
		// 作成後のURLもnewのままだが、activityがsetされると_idがつくので、参照するようにする。
		const id = state.activity._id ? state.activity._id.toString() : activityId;
		if (id === "new") {
			request = post(endpoint.activity, {
				...state.activity,
				categoryTargetGroup: state.categoryTargetGroup,
				strategyTargetGroup: state.strategyTargetGroup,
			} as UpsertActivityRequest);
		} else {
			request = put(`${endpoint.activity}/${id}`, {
				...state.activity,
				categoryTargetGroup: state.categoryTargetGroup,
				strategyTargetGroup: state.strategyTargetGroup,
			} as UpsertActivityRequest);
		}
		request
			.then((response) => {
				dispatch({ type: "updateActivity", payload: response.data.activity });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [activityId, state]);

	const history = useHistory();
	const onClose = React.useCallback(() => history.push(clientEndpoint.activity), []);
	return (
		<>
			<Prompt message={"変更が保存されていません。ページを移動してよろしいですか？"} when={state.prompt} />
			<Loading loading={loading} text={"processing..."} />
			<ConfirmBox info={info} onClose={onClose} />
			{/*<InfoMessage info={info} />*/}
			{activity && (
				<ActivityCreatePage
					errors={info.errors}
					activity={activity}
					strategyTargetGroup={strategyTargetGroup}
					categoryTargetGroup={categoryTargetGroup}
					brands={brands}
					categories={categories}
					accounts={accounts}
					loginAccount={loginAccount}
					cantEditMessage={cantEditMessage}
					onChangeActivity={onChangeActivity}
					onChangeCategoryGroup={onChangeCategoryGroup}
					onChangeStrategyGroup={onChangeStrategyGroup}
					onChangeHasStrategy={onChangeHasStrategy}
					onSubmit={onSubmit}
					hasStrategyGroup={hasStrategyGroup}
				/>
			)}
		</>
	);
};
