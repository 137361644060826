import * as React from "react";
import { BrowserRouter, Route, Switch, Redirect, Prompt } from "react-router-dom";
import { ErrorPage } from "../components/pages/error";
import { LoginContainter } from "../containers/login";
import { WithAuth } from "../containers/auth";
import { AccountContariner } from "../containers/account";
import { ActivityListContainer } from "../containers/activity/list";
import { ActivityCreateContainer } from "../containers/activity/create";
import { JobCreateContariner } from "../containers/job/create";
import { JobListContainer } from "../containers/job/list";
import { JobAllContainer } from "../containers/job/all";
import { ConceptContainer } from "../containers/concept/create";
import { ConceptPreviewContainer } from "../containers/concept/preview";
import { QuotaContainer } from "../containers/job/quota";
import { ApplicationContainer } from "../containers/job/application";
import { ApproveContainer } from "../containers/job/approve";
import { JobStatusContainer } from "../containers/job/status";
import { ActivityStatusContainer } from "../containers/activity/status";
import { PasswordResetMailContainer } from "../containers/password-reset-mail";
import { PasswordResetContainer } from "../containers/password-reset";
import { MessagePage } from "../components/pages/message-page";
import { SuccessPasswordResetContainer } from "../containers/success-password-reset";
import { LogoutContainter } from "../containers/logout";
import { PreviewContainer } from "../containers/preview";
import { InvoiceContainer } from "../containers/job/invoice";
import { JobDownloadContainer } from "../containers/job/download";
import { clientEndpoint } from "./endpoint";
import { OutlineContainer } from "../containers/report/outline";
import { ExectiveReportContainer } from "../containers/report/exective";
/*/
import { EvaluationReportContainer } from "../containers/report/evaluation";
import { ConceptEvaluationReportContainer } from "../containers/report/concept-evaluation";
import { SkuReportContainer } from "../containers/report/sku";
import { PurchaseIntentProfileReportContainer } from "../containers/report/purchase-intent-profile";
/*/
import { OAReportContainer } from "../containers/report/oa";
import { ResultByTargetReportContainer } from "../containers/report/result-by-target";
import { KeyMeasurementsReportContainer } from "../containers/report/key-measurements";
import { JobQuestionContainer } from "../containers/job/question";
import { InfoContainer } from "../containers/info";
import { ActivityMemberContainer } from "../containers/activity/member";
import { ActivityProgress } from "../containers/activity-progress";
import { JobDetailContainer } from "../containers/job/detail";
import { DownloadReportContainer } from "../containers/report/download";
import { SimulationContainer } from "../containers/report/simulation";
import { AggreagteCreateContainer } from "../containers/aggregate/create";
import { AggreagteContainer } from "../containers/aggregate/list";
import { AggregateViewConinar } from "../containers/aggregate/view";

export const Routes = () => {
	return (
		<BrowserRouter>
			<Switch>
				{/* login logout */}
				<Route path={clientEndpoint.login} exact>
					<LoginContainter />
				</Route>
				<Route path={clientEndpoint.logout} exact>
					<LogoutContainter />
				</Route>

				{/* password reset */}
				<Route path={clientEndpoint.passwordResetMail} exact>
					<PasswordResetMailContainer />
				</Route>
				<Route path={clientEndpoint.passwordReset} exact>
					<PasswordResetContainer />
				</Route>
				<Route path={clientEndpoint.successPasswordReset} exact>
					<SuccessPasswordResetContainer />
				</Route>
				<Route path={clientEndpoint.successPasswordResetMail} exact>
					<MessagePage>
						{"パスワード変更URLを送りました。\nメールに記載のURLよりパスワードのへ変更をお願いします。"}
					</MessagePage>
				</Route>
				{/* info */}
				<Route path={clientEndpoint.info} exact>
					<WithAuth>
						<InfoContainer />
					</WithAuth>
				</Route>
				{/* account */}
				<Route path={clientEndpoint.account} exact>
					<WithAuth>
						<WithAuth>{(account) => <AccountContariner {...account} />}</WithAuth>
					</WithAuth>
				</Route>

				{/* activity */}
				<Route path={clientEndpoint.activity} exact>
					<WithAuth>{(account) => <ActivityListContainer {...account} />}</WithAuth>
				</Route>
				<Route path={clientEndpoint.activityCreate} exact>
					<WithAuth>
						{(account) => (
							<ActivityProgress>
								{(isEditable) =>
									isEditable ? <ActivityCreateContainer {...account} /> : <ActivityMemberContainer {...account} />
								}
							</ActivityProgress>
						)}
					</WithAuth>
				</Route>
				<Route path={clientEndpoint.activityStatus} exact>
					<WithAuth>
						<ActivityStatusContainer />
					</WithAuth>
				</Route>

				{/* job */}
				<Route path={clientEndpoint.job} exact>
					<WithAuth>{(account) => <JobListContainer {...account} />}</WithAuth>
				</Route>
				<Route path={clientEndpoint.jobAll} exact>
					<WithAuth>{(account) => <JobAllContainer {...account} />}</WithAuth>
				</Route>
				<Route path={clientEndpoint.jobDownload} exact>
					<WithAuth>
						<JobDownloadContainer />
					</WithAuth>
				</Route>
				<Route path={clientEndpoint.jobCreate} exact>
					<WithAuth>{(account) => <JobCreateContariner {...account} />}</WithAuth>
				</Route>
				<Route path={clientEndpoint.jobCreateRetest} exact>
					<WithAuth>{(account) => <JobCreateContariner {...account} />}</WithAuth>
				</Route>
				<Route path={clientEndpoint.jobDetail} exact>
					<WithAuth>
						<JobDetailContainer />
					</WithAuth>
				</Route>
				<Route path={clientEndpoint.jobStatus} exact>
					<WithAuth>
						<JobStatusContainer />
					</WithAuth>
				</Route>
				{/* 追加設問 */}
				<Route path={clientEndpoint.question} exact>
					<WithAuth>
						<JobQuestionContainer />
					</WithAuth>
				</Route>

				{/* invoice */}
				<Route path={clientEndpoint.invoice} exact>
					<WithAuth>
						<InvoiceContainer />
					</WithAuth>
				</Route>

				{/* concept */}
				<Route path={clientEndpoint.concept} exact>
					<WithAuth>
						<ConceptContainer />
					</WithAuth>
				</Route>
				<Route path={clientEndpoint.conceptPreview} exact>
					<WithAuth>
						<ConceptPreviewContainer />
					</WithAuth>
				</Route>

				{/* quota */}
				<Route path={clientEndpoint.quota} exact>
					<WithAuth>{(account) => <QuotaContainer {...account} />}</WithAuth>
				</Route>

				{/* application approval */}
				<Route path={clientEndpoint.application} exact>
					<WithAuth>{(account) => <ApplicationContainer {...account} />}</WithAuth>
				</Route>
				<Route path={clientEndpoint.approval} exact>
					<WithAuth>{(account) => <ApproveContainer {...account} />}</WithAuth>
				</Route>

				{/* preview */}
				<Route path={clientEndpoint.preview} exact>
					<WithAuth>
						<PreviewContainer />
					</WithAuth>
				</Route>

				{/* report */}
				<Route path={clientEndpoint.outline} exact>
					<WithAuth isReportPage>
						<OutlineContainer />
					</WithAuth>
				</Route>
				<Route path={clientEndpoint.exectiveReport} exact>
					<WithAuth isReportPage>
						<ExectiveReportContainer />
					</WithAuth>
				</Route>
				<Route path={clientEndpoint.resultByTargetReport} exact>
					<WithAuth isReportPage>
						<ResultByTargetReportContainer />
					</WithAuth>
				</Route>
				<Route path={clientEndpoint.downloadReport} exact>
					<WithAuth isReportPage>{(account) => <DownloadReportContainer {...account} />}</WithAuth>
				</Route>
				{/* 
					<Route path={clientEndpoint.evaluationReport} exact>
						<WithAuth isReportPage>
							<EvaluationReportContainer />
						</WithAuth>
					</Route>
					<Route path={clientEndpoint.conceptEvaluationReport} exact>
						<WithAuth isReportPage>
							<ConceptEvaluationReportContainer />
						</WithAuth>
					</Route>
					<Route path={clientEndpoint.skuReport} exact>
						<WithAuth isReportPage>
							<SkuReportContainer />
						</WithAuth>
					</Route>
					<Route path={clientEndpoint.purchaseIntentProfileReport} exact>
						<WithAuth isReportPage>
							<PurchaseIntentProfileReportContainer />
						</WithAuth>
					</Route>
				*/}
				<Route path={clientEndpoint.oaReport} exact>
					<WithAuth isReportPage>
						<OAReportContainer />
					</WithAuth>
				</Route>
				<Route path={clientEndpoint.keyMeasurementsReport} exact>
					<WithAuth isReportPage>
						<KeyMeasurementsReportContainer />
					</WithAuth>
				</Route>
				<Route path={clientEndpoint.simulationReport} exact>
					<WithAuth isReportPage>{(account) => <SimulationContainer {...account} />}</WithAuth>
				</Route>
				{/* aggtegate */}
				<Route path={clientEndpoint.aggregate} exact>
					<WithAuth>
						<AggreagteContainer />
					</WithAuth>
				</Route>
				<Route path={clientEndpoint.aggtegateCreate} exact>
					<WithAuth>
						<AggreagteCreateContainer />
					</WithAuth>
				</Route>
				<Route path={clientEndpoint.aggtegateView} exact>
					<WithAuth>
						<AggregateViewConinar />
					</WithAuth>
				</Route>
				<Route path={clientEndpoint.invalidUrl} exact>
					<MessagePage>無効なURLです。</MessagePage>
				</Route>
				<Route path={clientEndpoint.permissionDenied}>
					<ErrorPage text="Permission Denied" />
				</Route>
				<Route path="/">
					<Redirect to={clientEndpoint.activity} />
				</Route>
				<Route>
					<ErrorPage text="Not Found" />
				</Route>
			</Switch>
		</BrowserRouter>
	);
};
