import * as React from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { array2CsvBlob, downloadData } from "../../../lib/data";

export const CrossTableElement = styled.table`
	border: 1px solid #aaa;
	th,
	td {
		padding: 3px;
	}
	th {
		border: 1px solid #aaa;
		text-align: center;
	}
	td {
		border: 1px dotted #aaa;
	}
	td {
		min-width: 70px;
	}
	tr.total-row {
		border-top: 1px solid #aaa;
		border-bottom: 1px solid #aaa;
		// background-color: #ddd;
		td {
			text-align: right;
		}
	}
	.plus {
		color: blue;
	}
	.minus {
		color: red;
	}
	.number {
		text-align: right;
	}
	td,
	th {
		border-right: 1px solid #aaa;
	}
`;

const ButtonAreaCaption = styled.caption`
	caption-side: top;
	text-align: left;
	margin-left: 10px;
`;

export type CrossCellData =
	| { value: string | number; rowSpan?: number; colSpan?: number; className?: string }
	| undefined;
export type CrossTableProps = {
	filename?: string;
	headers: CrossCellData[][];
	data: CrossCellData[][];
};

export const CrossTable = React.memo((props: CrossTableProps) => {
	const { headers, data, filename } = props;
	const onDownload = React.useCallback(() => {
		const tmpHeaders = headers.map((ds) =>
			ds.map((d) => (d && d.value !== null && d.value !== undefined ? d.value.toString() : ""))
		);
		const tmpData: string[][] = data.map((ds) =>
			ds.map((d) => {
				if (d && d.value !== null && d.value !== undefined) {
					return d.value.toString().replace(/%$/, "");
				}
				return "";
			})
		);
		downloadData(array2CsvBlob([...tmpHeaders, ...tmpData]), `${filename || "cross-data"}.csv`);
	}, [data, headers, filename]);
	return (
		<>
			<CrossTableElement>
				<ButtonAreaCaption>
					<Button variant={"outline-secondary"} size={"sm"} onClick={onDownload}>
						Download
					</Button>
				</ButtonAreaCaption>
				{!!headers.length && (
					<thead>
						{headers.map((hs, row) => {
							return (
								<tr key={`header-${row}`}>
									{hs.map((h, col) => {
										if (!h) return <React.Fragment key={`header-${row}-${col}`} />;
										const { value, ...attribute } = h;
										return (
											<th key={`header-${row}-${col}`} {...attribute}>
												{value}
											</th>
										);
									})}
								</tr>
							);
						})}
					</thead>
				)}
				<tbody>
					{data.map((ds, row) => {
						const trClassName = ds.some((d) => d && d.value === "(n)") ? "total-row" : "data-row";
						return (
							<tr className={trClassName} key={`data-${row}`}>
								{ds.map((d, col) => {
									if (!d) return <React.Fragment key={`header-${row}-${col}`} />;
									const { value, className, ...attribute } = d;
									const classNames: string[] = ["value"];
									if (className) classNames.push(className);
									if (value) {
										if (/^\+/.test(value.toString())) classNames.push("plus");
										if (/^-/.test(value.toString())) classNames.push("minus");
										if (/^[-+]?(\d|\.)+[%|％]?$/.test(value.toString())) classNames.push("number");
									}
									return (
										<td key={`data-${row}-${col}`} {...attribute} className={classNames.join(" ")}>
											{value || ""}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</CrossTableElement>
		</>
	);
});
