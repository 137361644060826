import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { FunctionComponent, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import styled from "styled-components";
import { DeleteButton } from "../../../button";

type Prop = {
	key?: string;
	headerText: string;
	onDelete: () => void;
};

const AccordionHeader = styled.div`
	display: flex;
	align-items: center;

	span {
		flex: 1;
		margin-left: 16px;
		margin-eight: 16px;
	}

	.chevron {
		transform: rotate(0);
		transition: transform 0.35s ease-in-out;

		&--open {
			transform: rotate(90deg);
		}
	}
`;

export const GroupSelectionAccordion: FunctionComponent<Prop> = ({
	key,
	headerText,
	onDelete,
	children,
}): JSX.Element => {
	const [open, setOpen] = useState(true);

	return (
		<Accordion key={key} defaultActiveKey="0" style={{ marginBottom: "8px" }}>
			<Card style={{ overflow: "visible" }}>
				<Accordion.Toggle
					as={Card.Header}
					eventKey="0"
					onClick={() => {
						setOpen(!open);
					}}
					style={{ background: "none", borderBottom: "none" }}
				>
					<AccordionHeader>
						<FontAwesomeIcon icon={faChevronRight} className={classNames("chevron", { "chevron--open": open })} />
						<span>{headerText}</span>
						<DeleteButton onClick={onDelete}></DeleteButton>
					</AccordionHeader>
				</Accordion.Toggle>
				<Accordion.Collapse eventKey="0">
					<Card.Body style={{ paddingBottom: "1.25rem", paddingLeft: "1.25rem", paddingRight: "1.25rem" }}>
						{children}
					</Card.Body>
				</Accordion.Collapse>
			</Card>
		</Accordion>
	);
};
