import * as React from "react";
import { Row, Col, Button, FormControl, Alert } from "react-bootstrap";
import { ErrorObject } from "../../../../../server/validation/validation-base";
import Feedback from "react-bootstrap/Feedback";
import { NoMenuNavigationBar } from "../../parts/nomenu-navigation-bar";

export type PasswordResetPageProps = {
	onSubmit: (password: string) => void;
	errors?: ErrorObject;
};
export const PasswordResetPage = React.memo((props: PasswordResetPageProps) => {
	const { onSubmit, errors } = props;
	const [password, setPassword] = React.useState<string>("");
	const isDisabled = React.useMemo(() => !password, [password]);
	return (
		<>
			<NoMenuNavigationBar />
			<div className="m-3 clearfix">
				<div className="h3">■パスワード変更</div>
				<Row className="mt-3">
					<Col md={{ span: 8, offset: 2 }}>
						<Alert variant={"info"}>
							新しいパスワードを入力してください。
							<br />
							　※パスワードはアルファベットの大文字小文字・数字・記号すべてを含む8-16文字で設定してください。
						</Alert>
					</Col>
				</Row>
				<Row className="mt-3">
					<Col md={3} className={"text-right"}>
						新しいパスワード：
					</Col>
					<Col md={5}>
						<FormControl
							placeholder="password"
							value={password}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
							isInvalid={!!errors && !!errors["password"]}
						/>
						{!!errors && !!errors["password"] && (
							<Feedback type={"invalid"} style={{ display: "block" }}>
								{errors["password"]}
							</Feedback>
						)}
					</Col>
				</Row>
				<Row className="mt-3">
					<Col md={{ span: 2, offset: 4 }}>
						<Button block onClick={() => onSubmit(password)} disabled={isDisabled}>
							変更
						</Button>
					</Col>
				</Row>
			</div>
		</>
	);
});
