/* eslint-disable @typescript-eslint/naming-convention */
import { WrapQuestion } from "muscat-library";
import { MonitasPoint, PointRange } from "monitas-library";
import { skuPurchaseQuename, elementEvaluationMAQuename, elementEvaluationSAQuename } from "../config";

const dummyArray = (length: number): number[] => {
	return new Array(length).fill(0);
};

const mekeExculdeQuenames = (wrapQuestions: WrapQuestion[]): Set<string> => {
	const conceptExcludeQuenames: string[] = dummyArray(3).reduce((a, _, currentIndex) => {
		const n = currentIndex + 2;
		// 要素評価の設問は必ず存在する。一般サンプルの設問のみをカウント
		return a.concat([`${elementEvaluationMAQuename}-${n}`, `${elementEvaluationSAQuename}-${n}`]);
	}, []);
	const skuQuenames = new Set<string>(dummyArray(4).map((_, index) => `${skuPurchaseQuename}-${index + 1}`));
	const tmp: string[] = wrapQuestions.reduce(
		(a, b) =>
			a.concat(b.questions.filter((question) => skuQuenames.has(question.quename)).map((question) => question.quename)),
		[]
	);
	if (tmp.length < 2) return new Set(conceptExcludeQuenames);
	// skuの設問がある場合は、重複カウントになるのを防ぐため、一つをのぞいてカウント除外設問とする。
	tmp.shift();
	return new Set([...conceptExcludeQuenames, ...tmp]);
};

/**
 * アンケートのポイントを計算する関数
 *  1.スクリーニングポイントは5問ごとに1point
 *  2.本調査は1問ごとに1point
 * @param {WrapQuestion[]} wrapQuestions アンケートの設定
 * @returns {PointRange} アンケートのポイント
 */
export const makeEnquetePoint = (wrapQuestions: WrapQuestion[]): PointRange => {
	const excludeQuenames = mekeExculdeQuenames(wrapQuestions);
	return MonitasPoint.makeEnquetePoint(wrapQuestions, excludeQuenames);
};
