import * as React from "react";
import { AccountWithoutPassword } from "../../../../../server/models/account";
import { reducer, initState } from "../../../reducers/activity/member";
import { Loading } from "lu-component";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { SwitchConfirm, SwitchConfirmEvent } from "../../../components/parts/switch-confirm";
import { useHistory, useParams } from "react-router";
import { ActivityMemberPage } from "../../../components/pages/activity/member";
import { get, makeError, put } from "../../../lib/request";
import { GetActivityMemberResponse } from "../../../../../server/types/request/activity";
import { endpoint } from "../../../../../server/router/endpoint";

export const ActivityMemberContainer = (loginAccount: AccountWithoutPassword) => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const history = useHistory();
	const { activityId } = useParams<{ activityId: string }>();
	const { loading, info, ...activityMemberPageProps } = state;
	React.useEffect(() => {
		get<GetActivityMemberResponse>(`${endpoint.activityMember}/${activityId}`)
			.then((response) => {
				dispatch({ type: "loadActivity", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	const events = React.useMemo<SwitchConfirmEvent[]>(() => {
		return [
			{
				label: "アクティビティ一覧に戻る",
				variant: "outline-secondary",
				func: () => history.push(`/activity`),
			},
		];
	}, []);
	const onSubmit = React.useCallback(() => {
		dispatch({ type: "changeLoading", payload: true });
		put<GetActivityMemberResponse>(`${endpoint.activityMember}/${activityId}`, {
			accountIds: activityMemberPageProps.activityMembers,
		})
			.then(() => {
				dispatch({ type: "updateMembers", payload: undefined });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [activityMemberPageProps.activityMembers]);
	const onChange = React.useCallback((value: string[]) => dispatch({ type: "changeMembers", payload: value }), []);
	return (
		<>
			<Loading loading={loading} text={"processing..."} />
			{!info.isSuccess && <ConfirmBox info={info} />}
			<SwitchConfirm
				show={info.isSuccess && !!info.message}
				title={"アクティビティ登録者の更新"}
				body={info.message}
				events={events}
			/>
			{activityMemberPageProps.activity && (
				<ActivityMemberPage
					{...activityMemberPageProps}
					onChange={onChange}
					onSubmit={onSubmit}
					loginAccount={loginAccount}
				/>
			)}
		</>
	);
};
