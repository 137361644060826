export const endpoint = {
	login: "/api/login",
	logout: "/api/logout",
	info: "/api/info",
	authentication: "/api/auth",
	account: "/api/account",
	accountDownload: "/api/download_account",
	accountUpload: "/api/uplooad_account",
	brand: "/api/brand",

	laodActivity: "/api/load_activity",
	activity: "/api/activity",
	activityMember: "/api/activity-member",
	activityProgressStatus: "/api/activity-progress-status",
	activityStatus: "/api/activity-status",

	job: "/api/activity/:activityId/job",
	jobAll: "/api/job/all",
	jobDownload: "/api/job-download",
	jobStatus: "/api/job-status",
	jobDetail: "/api/job-detail",
	loadJob: "/api/activity/:activityId/load_job",

	question: "/api/question",

	application: "/api/application",
	checkApplication: "/api/check_application",
	approve: "/api/approve",

	concept: "/api/concept",
	conceptPreview: "/api/concept_preview",
	conceptImage: "/api/concept_image",

	staticImage: "/images",
	enquetePreview: "/enquete-preview",
	preview: "/api/preview",
	enqueteTest: "/api/enquete-test",

	quota: "/api/quota",
	invoice: "/api/invoice",

	// レポートページ
	outline: "/api/report/outline",
	exectiveReport: "/api/report/exective",
	evaluationReport: "/api/report/evaluation",
	conceptEvaluationReport: "/api/report/concept-evaluation",
	oaReport: "/api/report/oa",
	keyMeasurementsReport: "/api/report/key-measurements",
	resutByTargetReport: "/api/report/result-by-target",
	simulation: "/api/simulation",

	downloadRawdata: "/api/download/rawdata",
	downloadQuestionSheet: "/api/download/question-sheet",

	passwordResetPage: "/password-reset",
	passwordReset: "/api/password-reset",

	aggregate: "/api/aggregate",
	aggregateDownload: "/api/download/aggregate",
	aggregateView: "/api/view/aggregate",
} as const;

export type ServerEndpoint = keyof typeof endpoint;
export type ServerEndpointPath = typeof endpoint[ServerEndpoint];

export const replaceEndpointUrl = (url: string, params: { [key: string]: string }) => {
	let tmp = url;
	for (const [key, val] of Object.entries(params)) {
		const reg = new RegExp(":" + key);
		tmp = tmp.replace(reg, val);
	}
	return tmp;
};

export const publicApiEndpoint = {
	excludeEnqueteId: "/api/exclude-enquete",
} as const;
