import { ConceptKey } from "../../models/concept";
import { Choice } from "muscat-library";
import { Option } from "../../types/request";

export const choice2Option = (choices: Choice[]): Option[] => {
	return choices.map((choice) => {
		return {
			label: choice.text,
			value: choice.value,
		};
	});
};
export const array2Option = (arr: string[]): Option[] => {
	return arr.map((str) => {
		return {
			label: str,
			value: str,
		};
	});
};
// コンセプトは順番を決めておく。上からtest1、test2、競合品、現行品
export const conceptNumber: { [key in ConceptKey]: number } = {
	test1: 1,
	test2: 2,
	benchmark: 3,
	currentProduct: 4,
};

export const quotaCode = {
	test1: "A",
	test2: "B",
	category: "C",
	strategy: "D",
} as const;

export const countQuotaCode = {
	general: "X",
	category: "Y",
	strategy: "Z",
} as const;

export const occupationChoices: Choice[] = [
	{ value: 1, text: "会社役員・会社員・公務員" },
	{ value: 2, text: "派遣社員" },
	{ value: 3, text: "パート・アルバイト" },
	{ value: 4, text: "自営業" },
	{ value: 5, text: "自由業・フリーランス" },
	{ value: 6, text: "高校生・高専生" },
	{ value: 7, text: "大学（短大・専門学校）生・大学院生" },
	{ value: 8, text: "専業主婦・主夫" },
	{ value: 9, text: "無職（定年後退職を含む）・家事手伝い" },
	{ value: 10, text: "その他", other: {} },
];

export const marriedChoices: Choice[] = [
	{ value: 1, text: "未婚" },
	{ value: 2, text: "既婚" },
	{ value: 3, text: "離別・死別" },
];

export const purchaseIntentChoices: Choice[] = [
	{ value: 1, text: "非常に買いたい" },
	{ value: 2, text: "買いたい" },
	{ value: 3, text: "どちらともいえない" },
	{ value: 4, text: "買いたくない" },
	{ value: 5, text: "まったく買いたくない" },
];

export const evaluationChoices: Choice[] = [
	{ value: 1, text: "非常にそう思う" },
	{ value: 2, text: "そう思う" },
	{ value: 3, text: "どちらともいえない" },
	{ value: 4, text: "そう思わない" },
	{ value: 5, text: "まったくそう思わない" },
];

export const ageOptions = ["15〜19歳", "20〜29歳", "30〜39歳", "40〜49歳", "50〜59歳", "60〜69歳", "70〜79歳"];
export const purchaseIntentOptions = purchaseIntentChoices.map((pc) => pc.text);
export const evaluationOptions = evaluationChoices.map((ec) => ec.text);
