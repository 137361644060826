import { Concept, ConceptKey, Sku } from "../../../../server/models/concept";
import { MessageInfo } from "../../types/info";
import { ConceptAction } from "../../types/actions";
import { deepCopy } from "../../../../server/lib/common";
import { RetestConcepts } from "../../../../server/types/request/concept";

export type State = {
	retestConcepts?: RetestConcepts;
	concepts: Concept[];
	loading: boolean;
	info: MessageInfo;
	cantEditMessage?: string;
	prompt: boolean;
};

export const initState: State = {
	concepts: [],
	loading: true,
	info: {
		message: "",
		isSuccess: true,
	},
	prompt: false,
};
const addSku = (sku: Sku[], index: number) => {
	const tmp = sku.concat();
	tmp.splice(index, 0, {
		name: "",
		price: undefined,
		includeTaxPrice: undefined,
	});
	return tmp;
};
const deleteSku = (sku: Sku[], index: number) => {
	const tmp = sku.concat();
	tmp.splice(index, 1);
	return tmp;
};
const copyConcept = (concepts: Concept[], original: ConceptKey, copyTo: ConceptKey): Concept[] => {
	const originalConcept = deepCopy(concepts.find((concept) => concept.type === original));
	return concepts.map((concept) => {
		if (concept.type === copyTo) {
			if (concept._id) {
				originalConcept._id = concept._id;
			} else {
				delete originalConcept._id;
			}
			return { ...originalConcept, type: copyTo };
		}
		return concept;
	});
};
const copyRetest = (
	concepts: Concept[],
	original: ConceptKey,
	copyTo: ConceptKey,
	retestConcepts: RetestConcepts
): Concept[] => {
	if (!retestConcepts || !retestConcepts[original]) return concepts;
	const originalConcept = deepCopy(retestConcepts[original]);
	originalConcept.type = copyTo;
	return concepts.map((concept) => {
		if (concept.type === copyTo) {
			return originalConcept;
		}
		return concept;
	});
};

export const reducer = (state: State, action: ConceptAction): State => {
	switch (action.type) {
		case "initConcepts":
			return { ...state, ...action.payload, loading: false };
		case "updateConcepts":
			return {
				...state,
				...action.payload,
				loading: false,
				info: {
					message: (state.concepts.some((c) => !!c._id) ? "更新" : "作成") + "が完了しました。",
					isSuccess: true,
				},
				prompt: false,
			};
		case "temporarySaveConcepts":
			return {
				...state,
				...action.payload,
				loading: false,
				info: {
					message: "一時保存が完了しました。",
					isSuccess: true,
				},
				prompt: false,
			};
		case "changeConcept":
			return {
				...state,
				concepts: state.concepts.map((concept) => {
					if (concept.type === action.payload.type) {
						return {
							...concept,
							[action.payload.name]: action.payload.value,
						};
					}
					return concept;
				}),
				prompt: true,
			};

		case "changeConceptFile":
			return {
				...state,
				loading: false,
				concepts: state.concepts.map((concept) => {
					if (concept.type === action.payload.type) {
						return {
							...concept,
							[action.payload.name]: action.payload.value,
						};
					}
					return concept;
				}),
			};
		case "changeConceptFileWidth":
			return {
				...state,
				loading: false,
				concepts: state.concepts.map((concept) => {
					if (concept.type === action.payload.type) {
						return {
							...concept,
							[action.payload.name]: {
								...concept[action.payload.name],
								width: action.payload.width,
							},
						};
					}
					return concept;
				}),
				prompt: true,
			};
		case "changeSku":
			return {
				...state,
				concepts: state.concepts.map((concept) => {
					if (concept.type === action.payload.type) {
						return {
							...concept,
							sku: concept.sku.map((s, index) => {
								if (action.payload.index === index) {
									return {
										...s,
										[action.payload.name]: action.payload.value,
									};
								}
								return s;
							}),
						};
					}
					return concept;
				}),
				prompt: true,
			};
		case "addSku":
			return {
				...state,
				concepts: state.concepts.map((concept) => {
					if (concept.type === action.payload.type) {
						return {
							...concept,
							sku: addSku(concept.sku, action.payload.index),
						};
					}
					return concept;
				}),
				prompt: true,
			};
		case "deleteSku":
			return {
				...state,
				concepts: state.concepts.map((concept) => {
					if (concept.type === action.payload.type) {
						return {
							...concept,
							sku: deleteSku(concept.sku, action.payload.index),
						};
					}
					return concept;
				}),
				prompt: true,
			};
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
		case "onCopy":
			return {
				...state,
				concepts: copyConcept(state.concepts, action.payload.original, action.payload.copyTo),
			};
		case "onRetestCopy":
			return {
				...state,
				concepts: copyRetest(state.concepts, action.payload.original, action.payload.copyTo, state.retestConcepts),
			};
		default:
			return state;
	}
};
