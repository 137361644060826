import { Loading } from "lu-component";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import { endpoint, replaceEndpointUrl } from "../../../../../server/router/endpoint";
import { GetAggregateResponse } from "../../../../../server/types/request/aggregate";
import { AggregateViewPage } from "../../../components/pages/aggregate/view";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { fileDownloadStream, get, makeError } from "../../../lib/request";
import { reducer, initState } from "../../../reducers/aggregate/view";
import { clientEndpoint } from "../../../routes/endpoint";

export const AggregateViewConinar = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { jobId, aggregateId } = useParams<{ jobId: string; aggregateId: string }>();
	const { loading, info, crossAggregate: aggregateJob, ...pageProps } = state;
	const history = useHistory();
	const onList = React.useCallback(() => history.push(clientEndpoint.aggregate), []);
	const onEdit = React.useCallback(
		() => history.push(replaceEndpointUrl(clientEndpoint.aggtegateCreate, { jobId, aggregateId })),
		[]
	);
	const onDownload = React.useCallback(() => {
		dispatch({ type: "changeLoading", payload: true });
		fileDownloadStream(`${endpoint.aggregateDownload}/${aggregateId}`, `クロス集計-${aggregateJob.name}.csv`)
			.then(() => {
				dispatch({ type: "changeLoading", payload: false });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [aggregateJob]);
	React.useEffect(() => {
		get<GetAggregateResponse>(`${endpoint.aggregateView}/${aggregateId}`)
			.then((response) => {
				dispatch({ type: "loadAggreagteJob", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	return (
		<>
			<Loading loading={loading} text={"processing..."} />
			<ConfirmBox info={info} />
			{!!pageProps.label && (
				<AggregateViewPage {...pageProps} onDownload={onDownload} onEdit={onEdit} onList={onList} />
			)}
		</>
	);
};
