import { TabSetting, TabViewer } from "lu-component";
import * as React from "react";
import { ConceptKey } from "../../../../../../server/models/concept";
import { GetSimulationResponse } from "../../../../../../server/types/request/report/simulation";
import styled from "styled-components";
import { SalesVolumeCorrectionValue } from "../../../../../../server/models/simulation-value";
import { SimulationOne } from "./simulation-one";
import { AccountWithoutPassword } from "../../../../../../server/models/account";
import { ReportTitle } from "../parts/report-title";

const AreaWrap = styled.div`
	width: 1500px;
	margin: 0px auto;
`;
const AlertRed = styled.span`
	display: inline-block;
	color: red;
	font-size: 12px;
`;

export type SimulationPageProps = GetSimulationResponse & {
	loginAccount: AccountWithoutPassword;
	onChangeRatio: <K extends keyof SalesVolumeCorrectionValue>(
		target: ConceptKey,
		name: K,
		value: SalesVolumeCorrectionValue[K]
	) => void;
	onSimulation: (conceptType: "test1" | "test2") => void;
	onSimulationSave: (conceptType: "test1" | "test2") => void;
};

export const SimulationPage = React.memo((props: SimulationPageProps) => {
	const {
		loginAccount,
		data,
		onChangeRatio,
		goalSalesVolume,
		constValue,
		job,
		activity,
		oneNumberScoreNorm,
		oneNumberScoreData,
		onSimulation,
		onSimulationSave,
		maxRecognition,
	} = props;
	const { test1, test2 } = data;
	const tabs = React.useMemo<TabSetting[]>(() => {
		const tmp: TabSetting[] = [];
		if (test1) {
			tmp.push({
				key: "test1",
				title: "テスト品1",
				children: (
					<SimulationOne
						loginAccount={loginAccount}
						target={"test1"}
						job={job}
						activity={activity}
						data={test1}
						goalSalesVolume={goalSalesVolume}
						constValue={constValue}
						oneNumberScoreNorm={oneNumberScoreNorm}
						oneNumberScoreData={oneNumberScoreData}
						onSimulation={() => onSimulation("test1")}
						onSimulationSave={() => onSimulationSave("test1")}
						maxRecognition={maxRecognition}
						onChangeRatio={(name, value) => onChangeRatio("test1", name, value)}
					/>
				),
			});
		}
		if (test2) {
			tmp.push({
				key: "test2",
				title: "テスト品2",
				children: (
					<SimulationOne
						loginAccount={loginAccount}
						target={"test2"}
						job={job}
						activity={activity}
						data={test2}
						goalSalesVolume={goalSalesVolume}
						constValue={constValue}
						oneNumberScoreNorm={oneNumberScoreNorm}
						oneNumberScoreData={oneNumberScoreData}
						onSimulation={() => onSimulation("test2")}
						onSimulationSave={() => onSimulationSave("test2")}
						maxRecognition={maxRecognition}
						onChangeRatio={(name, value) => onChangeRatio("test2", name, value)}
					/>
				),
			});
		}
		return tmp;
	}, [test1, test2, onChangeRatio, job, onSimulation]);
	return (
		<AreaWrap>
			<ReportTitle>
				補整＆提案フォーマット<AlertRed>（補整値は、アクティビテイメンバーと承認者のみ入力可能です）</AlertRed>
			</ReportTitle>
			{tabs.length > 0 && <TabViewer id={"resutlByTarget"} tabs={tabs} />}
		</AreaWrap>
	);
});
