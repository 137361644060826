import * as React from "react";
import { SelectRow, SelectRangeRow, InputRow } from "../../../../parts/input-row";
import { Option } from "lu-component";
import { CategoryGroup, Price, Gender, borderAppearance } from "../../../../../../../server/models/activity";
import { ErrorObject } from "../../../../../../../server/validation/validation-base";
import { categoryTargetGroupLabels } from "../../../../../../../label/activity";
import { Category } from "../../../../../../../server/models/category";
import { Badge } from "react-bootstrap";
import { Help } from "../../../../parts/help";
import { hasNorm, canSettingCategoryTarget } from "../../../../../../../server/lib/norm";
import styled from "styled-components";

const Red = styled.div`
	color: red;
	font-weight: bold;
`;

export const fromAgeOptions = [
	{ label: "15歳", value: 15 },
	{ label: "20歳", value: 20 },
	{ label: "25歳", value: 25 },
	{ label: "30歳", value: 30 },
	{ label: "35歳", value: 35 },
	{ label: "40歳", value: 40 },
	{ label: "45歳", value: 45 },
	{ label: "50歳", value: 50 },
	{ label: "55歳", value: 55 },
	{ label: "60歳", value: 60 },
	{ label: "65歳", value: 65 },
];
export const toAgeOptions = [
	{ label: "19歳", value: 19 },
	{ label: "24歳", value: 24 },
	{ label: "29歳", value: 29 },
	{ label: "34歳", value: 34 },
	{ label: "39歳", value: 39 },
	{ label: "44歳", value: 44 },
	{ label: "49歳", value: 49 },
	{ label: "54歳", value: 54 },
	{ label: "59歳", value: 59 },
	{ label: "64歳", value: 64 },
	{ label: "69歳", value: 69 },
];

export type CategoryTargetProps = {
	errors?: ErrorObject;
	category?: Category;
	categoryGroup: CategoryGroup;
	onChange: (name: keyof CategoryGroup, value: any) => void;
};
//const basePriceOptions = ["低", "中", "高"];
export const CategoryTarget = React.memo((props: CategoryTargetProps) => {
	const { categoryGroup, category, onChange, errors } = props;
	const priceOptions = React.useMemo<(Option & Price)[]>(() => {
		if (!category) return undefined;
		if (!category.priceAppearance && !category.priceAppearance.length) return undefined;
		const labels = new Set<string>();
		const options = category.priceAppearance.reduce((a, b) => {
			if (!b.price) return a;
			if (b.price.label && !labels.has(b.price.label)) {
				a.push({ ...b.price, value: b.price.label } as Option & Price);
				labels.add(b.price.label);
			}
			return a;
		}, []);
		if (options.length) {
			return options;
		}
		return undefined;
	}, [category]);
	const onChangePrice = React.useCallback(
		(value: string) => {
			const price = priceOptions.find((priceOption) => priceOption.value === value);
			if (price) {
				onChange("price", { from: price.from, to: price.to, label: price.label });
			}
		},
		[onChange, priceOptions]
	);
	const hasNoNormMessage = React.useMemo(() => {
		if (!hasNorm(category, categoryGroup)) {
			return "ノームがないため、購入意向のノーム判定はできません。必要に応じて、ベンチマーク品（競合/現行）を設定して比較してください。";
		}
	}, [categoryGroup, category]);
	const cantSettingMessage = React.useMemo(() => {
		if (!canSettingCategoryTarget(category, categoryGroup)) {
			return "この価格帯は出現率が低いため、カテゴリターゲットＧを設定できません。";
		}
	}, [categoryGroup, category]);
	return (
		<>
			<InputRow name="gender" rowName={"カテゴリ"} errors={errors}>
				{category && category.name}
			</InputRow>
			<InputRow name="gender" rowName={"性別"} errors={errors}>
				{category && Gender[category.gender]}
			</InputRow>
			<SelectRangeRow
				name="age"
				id="targetAgeRange"
				rowName={categoryTargetGroupLabels.age}
				fromOptions={fromAgeOptions}
				toOptions={toAgeOptions}
				values={categoryGroup.age}
				onChange={(value) => onChange("age", value)}
				errors={errors}
			/>
			{priceOptions && (
				<SelectRow
					name="price"
					id="targetCategoryPrice"
					rowName={
						<>
							{categoryTargetGroupLabels.price}
							<Help
								id={"strategy-price"}
								explain={"発売する商品（複数SKUある場合中心商品）の価格が含まれる価格帯を選択してください。"}
							/>
						</>
					}
					options={priceOptions}
					value={categoryGroup.price ? categoryGroup.price.label || "" : ""}
					onChange={onChangePrice}
					errors={errors}
				/>
			)}
			<InputRow name="appearance" rowName="">
				{categoryGroup.appearance !== undefined && (
					<>
						<Badge
							style={{ margin: "0px 10px", fontSize: "0.9em" }}
							variant={categoryGroup.appearance >= 3 ? "primary" : "danger"}
						>
							{categoryGroup.appearance >= borderAppearance ? "回収可能想定" : "回収不可想定"}： （出現率：
							{categoryGroup.appearance}％）
						</Badge>
						{hasNoNormMessage && <Red>{hasNoNormMessage}</Red>}
						{cantSettingMessage && <Red>{cantSettingMessage}</Red>}
					</>
				)}
			</InputRow>
		</>
	);
});
