import * as React from "react";
import { decimalCalc } from "../../../../../../../server/lib/aggregate/common";
import { ActivityWithBrand } from "../../../../../../../server/models/activity";
import { ConceptKey, ConceptType } from "../../../../../../../server/models/concept";
import { OneNumberScoreData, OneNumberScoreNorm } from "../../../../../../../server/models/graph/demand-forecast";
import { NormType } from "../../../../../../../server/models/graph/norm";
import { Job } from "../../../../../../../server/models/job";
import { doJudge, judgeNorm, normJudgeRank } from "../../../../../lib/action-standard";
import { Judge } from "../../exective-report/action-standard/action-standard-table";

export type NormRawProps = {
	baseConcept: "test1" | "test2";
	targetConcept: ConceptKey;
	oneNumberScoreNorm: OneNumberScoreNorm;
	oneNumberScoreData: OneNumberScoreData[];
	job: Job;
};

type NormResult = {
	sampleSize: number | "-";
	score: number | "-";
	rank: number | NormType | "-";
	judge: Judge | "-";
};

const getValue = <T extends object, K extends keyof T>(key: K, obj?: T): T[K] | "-" => {
	if (!obj || !(key in obj)) return "-";
	return obj[key];
};

const fixed = (input: number | string): string => {
	if (typeof input === "string") return input;
	return input.toFixed(1);
};

const NormRaw = React.memo((props: NormRawProps) => {
	const { baseConcept, targetConcept, oneNumberScoreNorm, oneNumberScoreData, job } = props;
	const categroyResult = React.useMemo(() => {
		const category = oneNumberScoreData.find((ons) => {
			return ons.target === "category" && ons.conceptType === targetConcept;
		});
		const result: NormResult = {
			sampleSize: getValue("sampleSize", category),
			score: getValue("score", category),
			rank: "-",
			judge: "-",
		};
		if (!category || !oneNumberScoreNorm) return result;
		if (baseConcept === targetConcept) {
			result.rank = judgeNorm(category.score, oneNumberScoreNorm);
			result.judge = normJudgeRank(result.rank, job.endDatetime) ? "〇" : "×";
			return result;
		}
		const base = oneNumberScoreData.find((ons) => {
			return ons.target === "category" && ons.conceptType === baseConcept;
		});
		result.rank = decimalCalc(base.score - category.score, 10);
		result.judge = base.score > category.score ? "〇" : "×";
		return result;
	}, [baseConcept, targetConcept, oneNumberScoreNorm, oneNumberScoreData]);
	const strategyResult = React.useMemo(() => {
		const strategy = oneNumberScoreData.find((ons) => {
			return ons.target === "strategy" && ons.conceptType === targetConcept;
		});
		const result: NormResult = {
			sampleSize: getValue("sampleSize", strategy),
			score: getValue("score", strategy),
			rank: "-",
			judge: "-",
		};
		if (!strategy || result.sampleSize === "-") return result;
		if (baseConcept === targetConcept) return result;
		const base = oneNumberScoreData.find((ons) => {
			return ons.target === "strategy" && ons.conceptType === baseConcept;
		});
		result.rank = decimalCalc(base.score - strategy.score, 10);
		/**
		 * No.8 エグゼクティブサマリーで戦略ターゲットGがn30未満であればＡＳ判定しない
		 */
		if (doJudge(result.sampleSize, job.endDatetime)) {
			result.judge = result.rank > 0 ? "〇" : "×";
		}
		return result;
	}, [baseConcept, targetConcept, oneNumberScoreNorm, oneNumberScoreData, job]);
	return (
		<tr>
			<td>{ConceptType[targetConcept]}</td>
			<td>{categroyResult.sampleSize}</td>
			<td>{fixed(categroyResult.score)}</td>
			<td className={categroyResult.rank < 0 ? "minus" : "plus"}>{fixed(categroyResult.rank)}</td>
			<td className={categroyResult.judge === "-" ? "" : "action-standard"}>{fixed(categroyResult.judge)}</td>
			<td>{strategyResult.sampleSize}</td>
			<td>{fixed(strategyResult.score)}</td>
			<td className={strategyResult.rank < 0 ? "minus" : "plus"}>{fixed(strategyResult.rank)}</td>
			<td className={strategyResult.judge === "-" ? "" : "action-standard"}>{fixed(strategyResult.judge)}</td>
		</tr>
	);
});

export type NormTableProps = {
	target: "test1" | "test2";
	oneNumberScoreNorm: OneNumberScoreNorm;
	oneNumberScoreData: OneNumberScoreData[];
	activity: ActivityWithBrand;
	job: Job;
};

export const NormTable = React.memo((props: NormTableProps) => {
	const { oneNumberScoreNorm, oneNumberScoreData, activity, target, job } = props;

	return (
		<table className={"result-table"}>
			<thead>
				<tr>
					<th></th>
					<th colSpan={4}>カテゴリーターゲットグループ</th>
					<th colSpan={4}>戦略ターゲットグループ</th>
				</tr>
				<tr>
					<th></th>
					<th>n数</th>
					<th>購入意向(%)</th>
					<th>ランク ／リフト</th>
					<th className={"action-standard"}>AS判定</th>
					<th>n数</th>
					<th>購入意向(%)</th>
					<th>リフト</th>
					<th className={"action-standard"}>AS判定</th>
				</tr>
			</thead>
			<tbody>
				<NormRaw
					targetConcept={target}
					baseConcept={target}
					oneNumberScoreData={oneNumberScoreData}
					oneNumberScoreNorm={oneNumberScoreNorm}
					job={job}
				/>
				<NormRaw
					targetConcept={"benchmark"}
					baseConcept={target}
					oneNumberScoreData={oneNumberScoreData}
					oneNumberScoreNorm={oneNumberScoreNorm}
					job={job}
				/>
				<NormRaw
					targetConcept={"currentProduct"}
					baseConcept={target}
					oneNumberScoreData={oneNumberScoreData}
					oneNumberScoreNorm={oneNumberScoreNorm}
					job={job}
				/>
			</tbody>
		</table>
	);
});
