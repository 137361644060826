import { AccountWithoutPassword } from "../../../../../server/models/account";
import { ActivityWithBrand } from "../../../../../server/models/activity";
import { Job } from "../../../../../server/models/job";
import { Concept } from "../../../../../server/models/concept";
import { SimpleQuestion } from "muscat-library";
import { Application } from "../../../../../server/models/application";
import { MessageInfo } from "../../../types/info";
import { ApproveAction } from "../../../types/actions";

export type State = {
	accounts: AccountWithoutPassword[];
	activity: ActivityWithBrand;
	job: Job;
	evaluationItem?: string[];
	question?: SimpleQuestion;
	concepts: Concept[];
	application: Application;

	estimate: string;
	loading: boolean;
	info: MessageInfo;
};

export const initState: State = {
	accounts: [],
	activity: undefined,
	job: undefined,
	estimate: undefined,
	application: undefined,
	concepts: [],
	loading: true,
	info: {
		isSuccess: true,
		message: "",
	},
};

export const reducer = (state: State, action: ApproveAction): State => {
	switch (action.type) {
		case "loadApplication":
			return {
				...state,
				loading: false,
				...action.payload,
			};
		case "UpdateApplication":
			return {
				...state,
				loading: false,
				application: action.payload,
				info: {
					isSuccess: true,
					message: action.payload.rejectMessage ? "却下しました。" : "承認が完了しました。",
				},
			};
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return { ...state, loading: action.payload };
		default:
			return state;
	}
};
