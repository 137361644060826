import * as React from "react";
import { FormControl } from "react-bootstrap";
import { decimalCalc } from "../../../../../../../server/lib/aggregate/common";
import { SalesVolumeCorrectionValue } from "../../../../../../../server/models/simulation-value";
import { SalesVolumePredictionData } from "../../../../../../../server/types/request/report/simulation";
import { simulationValueLabels } from "../../../../../../../label/simlulation-value";
import { calcDiffPercent } from "../common";
import { isDefined } from "../../../../../lib/data";
import { Help } from "../../../../parts/help";
import styled from "styled-components";
import { EditableText } from "../editable-text";

type ValueRowProps = {
	hasEditRole: boolean;
	label: string | JSX.Element;
	init: number;
	value: number;
	reason: string;
	onChangeValue: (value: number) => void;
	onChangeReason: (value: string) => void;
	isPercent?: boolean;
	min?: number;
};
const isNumber = (v: any): boolean => {
	return typeof v === "number";
};

const calc = (value: number, isPercent?: boolean) => {
	if (!isNumber(value)) return undefined;
	if (isPercent) {
		return decimalCalc(value * 100, 10);
	}
	return decimalCalc(value, 10);
};

export const WrapArea = styled.div`
	padding: 6px 0px;
	margin-bottom: 5px;
	white-space: pre-wrap;
`;

const ValueRow = React.memo((props: ValueRowProps) => {
	const { label, init, value, onChangeValue, isPercent, reason, min = 0, onChangeReason, hasEditRole } = props;
	const onChangeReasonValue = React.useCallback(
		(e: React.ChangeEvent<HTMLTextAreaElement>) => {
			const value = e.target.value;
			onChangeReason(value.slice(0, 90));
		},
		[onChangeReason]
	);
	const initValue = React.useMemo(() => {
		return calc(init, isPercent);
	}, [isPercent, init]);
	const diffPercent = React.useMemo(() => {
		if (!isDefined(value)) return "-";
		return calcDiffPercent(init, value);
	}, [init, value]);
	const viewValue = React.useMemo(() => {
		const c = calc(value, isPercent);
		if (c === undefined) return "-";
		return c.toFixed(1);
	}, [value, isPercent]);
	return (
		<tr>
			<td className={"label-area"}>{label}</td>
			<td>{initValue.toFixed(1)}</td>
			<td>
				{hasEditRole ? (
					<EditableText value={value} initValue={init} onChange={onChangeValue} isPercent={isPercent} min={min} />
				) : (
					viewValue
				)}
			</td>
			<td className={diffPercent < 0 ? "minus" : "plus"}>
				{diffPercent === "-" ? diffPercent : diffPercent.toFixed(1)}
			</td>
			<td className={"reason-area"}>
				<FormControl as={"textarea"} value={reason} onChange={onChangeReasonValue} readOnly={!hasEditRole} />
			</td>
		</tr>
	);
});

export type ChangableRate = "local" | "inbound";
export type CorrectionValueSettingProps = {
	hasEditRole: boolean;
	data: SalesVolumePredictionData;
	initLocalRatio: number;
	initInboundRatio: number;
	maxRecognition: number;
	onChangeRatio: <K extends keyof SalesVolumeCorrectionValue>(name: K, value: SalesVolumeCorrectionValue[K]) => void;
};

export const CorrectionValueSetting = React.memo((props: CorrectionValueSettingProps) => {
	const { data, initLocalRatio, initInboundRatio, onChangeRatio, maxRecognition, hasEditRole } = props;
	const { value, correctionValue } = data;
	return (
		<table className={"result-table simulation"}>
			<thead>
				<tr>
					<th>補整変数</th>
					<th>
						初期値
						<Help id={"initial"} explain={`初期値は、表示されていない小数点2位以下の数値も計算には参照しています。`} />
					</th>
					<th>
						補整値
						<Help
							id={"correctionValue"}
							explain={`初期値から変更しない場合は、手入力ではなく、必ず「初期値から変更しない」ボタンを押してください。`}
						/>
					</th>
					<th>補整率（％）</th>
					<th>補整根拠（初期値から変更した場合、入力必須）</th>
				</tr>
			</thead>
			<tbody>
				<ValueRow
					label={
						<>
							{simulationValueLabels.recognitionRate}
							<Help
								id={"max-regognition"}
								explain={`ブランド知名率の上限：${maxRecognition}以下で入力してください。`}
							/>
						</>
					}
					hasEditRole={hasEditRole}
					init={value.g}
					value={correctionValue.recognitionRate}
					reason={correctionValue.recognitionRateReason}
					min={0.1}
					onChangeValue={(v) => onChangeRatio("recognitionRate", v)}
					onChangeReason={(v) => onChangeRatio("recognitionRateReason", v)}
					isPercent
				/>
				<ValueRow
					label={
						<>
							購入者あたり
							<br />
							年間購入個数
							<Help id={"max-purchaseQuantity"} explain={`1.0以上で入力ください。`} />
						</>
					}
					hasEditRole={hasEditRole}
					init={value.h}
					min={1}
					value={correctionValue.purchaseQuantity}
					reason={correctionValue.purchaseQuantityReason}
					onChangeValue={(v) => onChangeRatio("purchaseQuantity", v)}
					onChangeReason={(v) => onChangeRatio("purchaseQuantityReason", v)}
				/>
				<ValueRow
					label={
						<>
							{simulationValueLabels.localRatio}
							<br />
							<span className={"small-font"}>※トライアル+リピートに対する</span>
						</>
					}
					hasEditRole={hasEditRole}
					init={initLocalRatio}
					value={correctionValue.localRatio}
					reason={correctionValue.localRatioReason}
					onChangeValue={(v) => onChangeRatio("localRatio", v)}
					onChangeReason={(v) => onChangeRatio("localRatioReason", v)}
					isPercent
				/>
				<ValueRow
					label={
						<>
							{simulationValueLabels.inboundRatio}
							<br />
							<span className={"small-font"}>※トライアル+リピートに対する</span>
						</>
					}
					hasEditRole={hasEditRole}
					init={initInboundRatio}
					value={correctionValue.inboundRatio}
					reason={correctionValue.inboundRatioReason}
					onChangeValue={(v) => onChangeRatio("inboundRatio", v)}
					onChangeReason={(v) => onChangeRatio("inboundRatioReason", v)}
					isPercent
				/>
			</tbody>
		</table>
	);
});
