import { ObjectId } from "mongodb";

export enum AccountRole {
	"admin" = "システム管理者",
	"operation-manager" = "運用管理者",
	"viewer1" = "特権閲覧者",
	"approver" = "承認者",
	"applicant" = "申請者",
	"viewer2" = "閲覧者",
}
export const AccountOptions = Object.entries(AccountRole)
	.filter(([value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export type Account = {
	_id?: string | ObjectId;
	code: string;
	name: string;
	email: string;
	isActive: boolean;
	password: string;
	role: keyof typeof AccountRole;
	brand: (ObjectId | string)[];

	// ログイン失敗時の情報
	failCount?: number;
	lockedAt?: Date;
	loginedAt?: Date;
	prevLoginedAt?: Date;

	// passwordHistories
	passwordHistories?: string[];
	passwordUpdatedAt?: Date;
};

export type AccountWithoutPassword = Omit<Account, "password" | "passwordHistories">;
