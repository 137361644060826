import { Question } from "muscat-library";
import * as React from "react";
import { SubTitle, InputArea, AddSelectionGroupButtonArea } from "../common";
import { GroupSelectionItem } from "./item";
import { AddButton } from "../../button";
import { SelectionGroup } from "muscat-aggregate-library";
import { ErrorObject } from "../../../../../../server/validation/validation-base";
import { getErrorObject } from "../../../../lib/error";
import styled from "styled-components";

const Container = styled.div`
	margin-bottom: 64px;
`;

export type GroupSelectionsProps = {
	questions: Question[];
	selectionGroups: SelectionGroup[];
	onAddSelectionGroup: () => void;
	onDeleteSelectionGroup: (name: string) => void;
	errors?: ErrorObject;
	onChange: <T extends keyof SelectionGroup>(targetName: string, name: T, value: SelectionGroup[T]) => void;
};

export const GroupSelections = React.memo((props: GroupSelectionsProps) => {
	const { questions, selectionGroups, onAddSelectionGroup, onChange, errors, onDeleteSelectionGroup } = props;
	return (
		<Container>
			<SubTitle>選択肢グループ</SubTitle>
			<InputArea>
				{selectionGroups.map((selectionGroup, index) => (
					<GroupSelectionItem
						key={`group-selection-item-${selectionGroup.name}`}
						selectionGroup={selectionGroup}
						questions={questions}
						onDelete={() => onDeleteSelectionGroup(selectionGroup.name)}
						errors={getErrorObject(index.toString(), errors)}
						onChange={(name, value) => onChange(selectionGroup.name, name, value)}
					/>
				))}
			</InputArea>
			<AddSelectionGroupButtonArea>
				<AddButton onClick={onAddSelectionGroup} text="選択肢グループを追加" />
			</AddSelectionGroupButtonArea>
		</Container>
	);
});
