import { Job } from "../../../../../server/models/job";
import { Option } from "lu-component";
import { MessageInfo } from "../../../types/info";
import { SimpleQuestion } from "muscat-library";
import { JobCreateAction } from "../../../types/actions";

export type State = {
	job: Partial<Job>;
	jobOptions: Option[];
	evaluationItem: string[];
	hasStrategyGroup: boolean;
	hasCategoryGroup: boolean;
	// question?: SimpleQuestion;
	loading: boolean;
	info: MessageInfo;
	cantEditMessage?: string;
	prompt: boolean;
};

export const initState: State = {
	job: {},
	jobOptions: [],
	evaluationItem: [],
	loading: true,
	hasStrategyGroup: undefined,
	hasCategoryGroup: undefined,
	info: {
		message: "",
		isSuccess: true,
	},
	prompt: false,
};

/**
 * 戦略ターゲット設定
 * 	1. 再調査である
 * 		1-1. アクティビティが戦略ターゲットを持っている（前回調査も戦略ターゲットあり）・・・undefined
 *		1-2. アクティビティが戦略ターゲットを持っていない（またh前回調査で戦略ターゲットなし）・・・false
 *  2. 再調査でない場合・・・hasCategoryGroupの値に準ずる
 * @param {Partial<Job>} initJob
 * @param {Job | undefined} loadJob
 * @param {hasStrategyGroup} hasStrategyGroup
 * @returns {boolean | undefined}
 */
const makeHasStrategicTarget = (
	initJob: Partial<Job>,
	loadJob: Job | undefined,
	hasStrategyGroup: boolean
): boolean | undefined => {
	if (loadJob) return loadJob.hasStrategicTarget;
	if (initJob.retestJobId) {
		if (!hasStrategyGroup) return false;
		return undefined;
	}
	return hasStrategyGroup;
};

export const reducer = (state: State, action: JobCreateAction): State => {
	switch (action.type) {
		case "loadJob":
			return {
				...state,
				loading: false,
				...action.payload,
				job: {
					...state.job,
					...action.payload.job,
					hasStrategicTarget: makeHasStrategicTarget(
						state.job,
						action.payload.job,
						action.payload.hasStrategyGroup
					) /*!action.payload.hasStrategyGroup
						? false
						: action.payload.job
						? action.payload.job.hasStrategicTarget
						: undefined,*/,
					hasCurrentProduct:
						!action.payload.hasStrategyGroup && !action.payload.hasCategoryGroup
							? false
							: action.payload.job
							? action.payload.job.hasCurrentProduct
							: state.job.hasCurrentProduct,
					hasBenchmark:
						!action.payload.hasStrategyGroup && !action.payload.hasCategoryGroup
							? false
							: action.payload.job
							? action.payload.job.hasBenchmark
							: state.job.hasBenchmark,
					benchmarkName:
						!action.payload.hasStrategyGroup && !action.payload.hasCategoryGroup
							? ""
							: action.payload.job
							? action.payload.job.benchmarkName
							: "",
				},
			};
		case "updateJob":
			return {
				...state,
				...action.payload,
				loading: false,
				info: {
					isSuccess: true,
					message: `${state.job._id ? "更新" : "作成"}が完了しました。`,
				},
				prompt: false,
			};

		case "changeJob":
			return {
				...state,
				job: {
					...state.job,
					[action.payload.name]: action.payload.value,
				},
				prompt: true,
			};
		case "changeEvaluationItem":
			return {
				...state,
				evaluationItem: action.payload,
				prompt: true,
			};
		/*/
		case "changeQuestion":
			return {
				...state,
				question: action.payload,
			};
		/*/
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return { ...state, loading: action.payload };
		default:
			return state;
	}
};
