import * as React from "react";
import { AccountWithoutPassword } from "../../../../../server/models/account";
import { reducer, initState } from "../../../reducers/job/approve";
import { Loading } from "lu-component";
import { ApprovePage } from "../../../components/pages/approval/approve";
import { get, makeError, post } from "../../../lib/request";
import {
	GetApprovalResponse,
	UpdateApprovalResponse,
	UpdateApprovalRequest,
} from "../../../../../server/types/request/approval";
import { endpoint } from "../../../../../server/router/endpoint";
import { useParams, useHistory } from "react-router";
import { ConfirmBox } from "../../../components/parts/confirm-box";

export const ApproveContainer = (loginAccount: AccountWithoutPassword) => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, ...approveProps } = state;
	const { jobId, activityId } = useParams<{ jobId: string; activityId: string }>();
	const histroy = useHistory();
	React.useEffect(() => {
		get<GetApprovalResponse>(`${endpoint.approve}/${jobId}`)
			.then((response) => {
				dispatch({ type: "loadApplication", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	const onSubmit = React.useCallback((data: UpdateApprovalRequest) => {
		dispatch({ type: "changeLoading", payload: true });
		post<UpdateApprovalResponse>(`${endpoint.approve}/${jobId}`, data)
			.then((response) => {
				dispatch({ type: "UpdateApplication", payload: response.data.application });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	const onApprove = React.useCallback(() => {
		onSubmit({ status: "approve" });
	}, [onSubmit]);
	const onReject = React.useCallback(
		(rejectMessage: string) => {
			onSubmit({ status: "reject", rejectMessage });
		},
		[onSubmit]
	);
	const onClose = React.useCallback(() => histroy.push(`/activity/${activityId}/job`), []);
	return (
		<>
			<Loading loading={loading} text={"processing..."} />
			<ConfirmBox info={info} titleLabel={"処理"} onClose={onClose} />
			{approveProps.application && (
				<ApprovePage
					loginAccount={loginAccount}
					errors={info.errors}
					onApprove={onApprove}
					onReject={onReject}
					{...approveProps}
				/>
			)}
		</>
	);
};
