import { Application } from "../../../../../server/models/application";
import { MessageInfo } from "../../../types/info";
import { ApplicationAction } from "../../../types/actions";
import { Option } from "lu-component";
import { isOverCost } from "../../../../../server/lib/aggregate/estimate";

export type State = {
	overEstimateAgreement: boolean; // 100万超える場合に出すアラート。問題なければtrueになる。
	accountOptions: Option[];
	application: Application;
	loading: boolean;
	info: MessageInfo;
};

export const initState: State = {
	overEstimateAgreement: true,
	accountOptions: [],
	application: {
		accountId: "",
		jobId: "",
		applicationDate: "",
		applicationMessage: "",
		approve: [{ approver: "" }],
	},
	loading: true,
	info: {
		isSuccess: true,
		message: "",
	},
};

export const reducer = (state: State, action: ApplicationAction): State => {
	switch (action.type) {
		case "loadApplication":
			return {
				...state,
				loading: false,
				accountOptions: action.payload.accountOptions,
				application: action.payload.application || state.application,
				overEstimateAgreement:
					(!action.payload.application || action.payload.application.cancel) && isOverCost(action.payload.estimate)
						? false
						: true,
			};
		case "changeAgreement":
			return {
				...state,
				overEstimateAgreement: action.payload,
			};
		case "updateApplication":
			return {
				...state,
				loading: false,
				application: action.payload,
				info: {
					isSuccess: true,
					message: "申請が完了しました。",
				},
			};
		case "cancelApplication":
			return {
				...state,
				loading: false,
				application: action.payload,
				info: {
					isSuccess: true,
					message: "申請を取り消しました。",
				},
			};
		case "changeApplication":
			return {
				...state,
				application: { ...state.application, [action.payload.name]: action.payload.value },
			};
		case "changeApprover":
			return {
				...state,
				application: {
					...state.application,
					approve:
						!action.payload.value && action.payload.index === 1
							? [state.application.approve[0]]
							: state.application.approve.length <= action.payload.index
							? state.application.approve.concat({ approver: action.payload.value })
							: state.application.approve.map((ap, index) => {
									if (index === action.payload.index) {
										return {
											...ap,
											approver: action.payload.value,
										};
									}
									return ap;
							  }),
				},
			};
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return { ...state, loading: action.payload };
		default:
			return state;
	}
};
