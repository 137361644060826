import * as React from "react";
import { PasswordResetPage } from "../../components/pages/password-reset-form";
import { useParams, useHistory } from "react-router";
import { reducer, initState } from "../../reducers/password-reset";
import { Loading } from "lu-component";
import { post, makeError } from "../../lib/request";
import { endpoint } from "../../../../server/router/endpoint";
import { ConfirmBox } from "../../components/parts/confirm-box";
import { clientEndpoint } from "../../routes/endpoint";

export const PasswordResetContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info } = state;
	const history = useHistory();
	const { hash } = useParams<{ hash: string }>();
	const onSubmit = React.useCallback((password: string) => {
		post<{}>(`${endpoint.passwordReset}/${hash}`, { password })
			.then(() => {
				history.push(clientEndpoint.successPasswordReset);
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	return (
		<>
			<Loading loading={loading} />
			<ConfirmBox info={info} />
			<PasswordResetPage onSubmit={onSubmit} errors={info.errors} />
		</>
	);
};
