import * as React from "react";
import { FormCheck } from "react-bootstrap";

export type CheckboxProps = {
	id?: string;
	label?: string;
	onChange: (checked: boolean) => void;
	checked: boolean;
};

export const Checkbox = React.memo((props: CheckboxProps) => {
	const { onChange, ...checkProps } = props;
	const onChangeChecked = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			onChange(e.target.checked);
		},
		[onChange]
	);
	return <FormCheck onChange={onChangeChecked} {...checkProps} />;
});
