import * as React from "react";
import { ActivityWithBrand } from "../../../../../../server/models/activity";
import { ActivityPanel } from "../../report/outline/activity-panel";
import { TargetPanel } from "../../report/outline/test-panel/target-panel";
import { AccountWithoutPassword } from "../../../../../../server/models/account";
import { Option } from "lu-component";
import { MultipleSelect } from "../../../parts/select";
import { Role } from "../../../../../../server/lib/permission/role";
import { Row, Col, Button } from "react-bootstrap";
import { Help } from "../../../parts/help";

export type ChangeActivityMemberPageProps = {
	activity: ActivityWithBrand;
	loginAccount: AccountWithoutPassword;
	accounts: AccountWithoutPassword[];
	activityMembers: string[];
	onChange: (values: string[]) => void;
	onSubmit: () => void;
};
export const ActivityMemberPage = React.memo((props: ChangeActivityMemberPageProps) => {
	const { activity, accounts, loginAccount, activityMembers, onChange, onSubmit } = props;
	// 担当アカウントで絞り込み。
	const brandholders = React.useMemo<Option[]>(() => {
		if (!activity.brandId) return [];
		return accounts
			.filter(
				(account) =>
					!!account.brand &&
					account.brand.includes(activity.brandId) &&
					// loginAccount._id !== account._id && 自分を表示しないと外れてしまうので、コメントアウト
					Role.isBrander(account)
			)
			.map((account) => {
				return {
					value: account._id.toString(),
					label: account.name,
				};
			});
	}, [activity.brandId, accounts, loginAccount]);
	const hasEditRole = React.useMemo(() => Role.hasAcitivityEditRole(loginAccount, activity), [loginAccount, activity]);
	return (
		<>
			<div className="m-3">
				<div className="h3">■アクティビティ詳細</div>
			</div>
			<ActivityPanel activity={activity} />
			<TargetPanel activity={activity} hasStrategyGroup />
			<div className="m-3">
				<div className="h3">■アクティビティ登録者</div>
			</div>
			<Row className={"m-3"}>
				<Col md={{ span: 4, offset: 1 }}>
					アクティビティ登録者（複数選択可能）
					<Help
						id={"accountIds"}
						explain={"当アクティビティとそれに紐づくJOBを登録、更新、申請できるメンバーを選択してください。"}
					/>
					<MultipleSelect options={brandholders} value={activityMembers} onChange={onChange} />
				</Col>
			</Row>
			<Row className={"m-4"}>
				<Col md={{ span: 2, offset: 2 }}>
					{hasEditRole ? (
						<Button variant={"secondary"} block onClick={onSubmit}>
							更新
						</Button>
					) : (
						"アクティビティ登録者を更新できません。"
					)}
				</Col>
			</Row>
		</>
	);
});
