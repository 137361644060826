import * as React from "react";
import { OverlayTrigger, Badge, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { Placement } from "react-bootstrap/esm/Overlay";

export type HelpProps = {
	placement?: Placement;
	id: string;
	explain: React.ReactChild | React.ReactChild[] | React.ReactText;
};
export const Help = React.memo((props: HelpProps) => {
	const { id, explain, placement = "right" } = props;
	return (
		<OverlayTrigger
			placement={placement}
			overlay={
				<Popover id={`tooltip-${id}`} style={{ maxWidth: "400px" }}>
					<Popover.Content>{explain}</Popover.Content>
				</Popover>
			}
		>
			<Badge variant={"dark"} style={{ marginLeft: "5px" }}>
				<FontAwesomeIcon icon={faQuestion} />
			</Badge>
		</OverlayTrigger>
	);
});
