import * as React from "react";
import { Navbar } from "react-bootstrap";

export const NoMenuNavigationBar = () => {
	return (
		<Navbar bg={"dark"} variant="dark" expand="lg">
			<Navbar.Brand href="#home" className="font-weight-bold">
				FT Test it
			</Navbar.Brand>
		</Navbar>
	);
};
