import * as React from "react";
import { LoginPage } from "../../components/pages/login";
import { post } from "../../lib/request";
import { useHistory } from "react-router";
import { LoginRequest, LoginResponse } from "../../../../server/types/request/login";
import { ErrorResponse } from "../../../../server/types/request";
import { AxiosError } from "axios";
import { ErrorObject } from "../../../../server/validation/validation-base";
import { endpoint } from "../../../../server/router/endpoint";
import { Navbar } from "react-bootstrap";
import { NoMenuNavigationBar } from "../../components/parts/nomenu-navigation-bar";
import { clientEndpoint } from "../../routes/endpoint";

export const LoginContainter = () => {
	const history = useHistory();
	const [error, setError] = React.useState<string>();
	const [errors, setErrors] = React.useState<ErrorObject>();
	const onLogin = React.useCallback((email: string, password: string) => {
		const request: LoginRequest = { email, password };
		post<LoginResponse>(endpoint.login, request)
			.then((response) => {
				if (response.data.account) {
					history.push(response.data.redirectUrl || clientEndpoint.activity);
					setError(undefined);
					setErrors(undefined);
				}
			})
			.catch((error: AxiosError<ErrorResponse>) => {
				setError(error.response.data.message);
				setErrors(error.response.data.errors);
			});
	}, []);
	const onPasswordChangePage = React.useCallback(() => {
		history.push(clientEndpoint.passwordResetMail);
	}, []);
	return (
		<>
			<NoMenuNavigationBar />
			<LoginPage onLogin={onLogin} error={error} errors={errors} onPasswordChangePage={onPasswordChangePage} />
		</>
	);
};
