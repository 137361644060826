import * as React from "react";
import { PasswordResetMailPage } from "../../components/pages/password-reset-mail";
import { useHistory } from "react-router";
import { post, makeError } from "../../lib/request";
import { endpoint } from "../../../../server/router/endpoint";
import { reducer, initState } from "../../reducers/password-reset";
import { Loading } from "lu-component";
import { ConfirmBox } from "../../components/parts/confirm-box";
import { clientEndpoint } from "../../routes/endpoint";

export const PasswordResetMailContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info } = state;
	const history = useHistory();
	const onSubmit = React.useCallback((email: string) => {
		dispatch({ type: "changeLoading", payload: true });
		post<{}>(`${endpoint.passwordReset}`, { email })
			.then(() => {
				history.push(clientEndpoint.successPasswordResetMail);
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	return (
		<>
			<Loading loading={loading} />
			<ConfirmBox info={info} />
			<PasswordResetMailPage onSubmit={onSubmit} />
		</>
	);
};
