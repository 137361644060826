import { MultipleSelect } from "../../../../select";
import * as React from "react";
import { Grouping } from "muscat-aggregate-library";
import { Option } from "../../../../../../../../server/types/request";

export type FilteredMultipleSelectProps = {
	values: number[];
	options: Option[];
	grouping: Grouping[];
	onChange: (values: number[]) => void;
	error?: string;
};
export const FilteredMultipleSelect = React.memo((props: FilteredMultipleSelectProps) => {
	const { values, options, grouping, onChange, error } = props;
	const ops = React.useMemo<Option[]>(() => {
		return options.filter((op) => {
			if (values.includes(Number(op.value))) return true;
			return grouping.every((group) => {
				if (group.not) return true;
				return "values" in group && !group.values.includes(Number(op.value));
			});
		});
	}, [values, grouping]);
	return <MultipleSelect options={ops} onChange={onChange} value={values} error={error} />;
});
