import { MessageInfo } from "../../../types/info";
import { AggregateCreateAction } from "../../../types/actions";
import { Qtype, Question } from "muscat-library";
import { AggregateJob, SelectionGroup } from "muscat-aggregate-library";
import { CrossAggregate } from "../../../../../server/models/cross-aggregate";
import { Option } from "../../../../../server/types/request";
import { deleteSelectionGroup } from "../../../actions/aggregate/create";
import { Activity } from "../../../../../server/models/activity";

export type State = AggregateJob & {
	activity: Activity;
	crossAggregate: Partial<CrossAggregate>;
	loading: boolean;
	info: MessageInfo;
	questions: Question[];
	qMap: Map<string, Qtype>;
	title: string;
	prompt: boolean;

	// options
	targetGroupOptions: Option[];
	conceptOptions: Option[];
};

export const initState: State = {
	activity: undefined,
	crossAggregate: {},
	loading: true,
	info: {
		message: "",
		isSuccess: true,
	},
	prompt: false,
	jobNo: undefined,
	enqueteId: "",
	vertical: true,
	selectionGroups: [],
	quenames: [],
	axis: [],
	questions: [],
	title: "",
	qMap: undefined,

	targetGroupOptions: [],
	conceptOptions: [],
};

const addSelectionGroup = (selectionGroups: SelectionGroup[]) => {
	const len = selectionGroups.length;
	const lastNumber = len ? Number(selectionGroups[len - 1].name.replace("T", "")) : 0;
	return [
		...selectionGroups,
		{
			name: `T${lastNumber + 1}`,
			label: "",
			quename: "",
			grouping: [],
		},
	];
};
export const reducer = (state: State, action: AggregateCreateAction): State => {
	switch (action.type) {
		case "loadAggreagteJob":
			return {
				...state,
				...action.payload.aggregateJob,
				title: action.payload.title,
				activity: action.payload.activity,
				crossAggregate: action.payload.crossAggregate,
				targetGroupOptions: action.payload.targetGroupOptions,
				conceptOptions: action.payload.conceptOptions,
				loading: false,
				questions: action.payload.questions,
				qMap: action.payload.questions.reduce((a, b) => {
					a.set(b.quename, b.type);
					return a;
				}, new Map<string, Qtype>()),
			};
		case "upsertAggregate":
			return {
				...state,
				...action.payload,
				loading: false,
				info: {
					isSuccess: true,
					message: "集計設定を保存しました。",
				},
				prompt: false,
			};
		case "changeCrossAggregate":
			return {
				...state,
				crossAggregate: {
					...state.crossAggregate,
					[action.payload.name]: action.payload.value,
				},
				prompt: true,
			};
		case "changeGroupSelection":
			return {
				...state,
				selectionGroups: state.selectionGroups.map((selectionGroup) => {
					if (selectionGroup.name !== action.payload.targetName) return selectionGroup;
					const tmp = { ...selectionGroup, [action.payload.name]: action.payload.value };
					if (action.payload.name === "quename" && selectionGroup.quename !== action.payload.value) {
						tmp.grouping = [
							state.qMap.get(action.payload.value as string) === "I"
								? { label: "", from: undefined, to: undefined }
								: { label: "", values: [] },
						];
						delete tmp.childQuename;
					}
					return tmp;
				}),
				prompt: true,
			};
		case "changeAxis":
			return { ...state, axis: action.payload, prompt: true };
		case "changeQuenames":
			return { ...state, quenames: action.payload, prompt: true };
		case "changeVertical":
			return { ...state, vertical: action.payload, prompt: true };
		case "addSelectionGroup":
			return {
				...state,
				selectionGroups: addSelectionGroup(state.selectionGroups),
				prompt: true,
			};
		case "deleteSelectionGroup":
			return {
				...state,
				selectionGroups: state.selectionGroups.filter(({ name }) => name !== action.payload),
				axis: state.axis.filter((name) => name !== action.payload),
				quenames: state.quenames.filter((name) => name !== action.payload),
				prompt: true,
			};
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
		default:
			return state;
	}
};
