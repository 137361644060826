import { ErrorObject } from "../../../server/validation/validation-base";

export const getErrorMessage = (name: string, errors?: ErrorObject): string => {
	if (!errors) return undefined;
	if (name in errors) {
		const error = errors[name];
		if (typeof error === "string") {
			return error;
		}
	}
	return undefined;
};

export const getErrorObject = (name: string, errors?: ErrorObject): ErrorObject => {
	if (!errors) return undefined;
	if (name in errors) {
		const error = errors[name];
		if (typeof error === "object") {
			return error;
		}
	}
	return undefined;
};

export const getErrors = (name: string, errors?: ErrorObject): string | ErrorObject => {
	if (!errors) return undefined;
	if (name in errors) {
		return errors[name];
	}
	return undefined;
};
