import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Badge, Popover } from "react-bootstrap";

import styled from "styled-components";

const MenuArea = styled.div<{ width: number }>`
	background-color: #f7f7f7;
	position: fixed;
	width: ${({ width }) => width}px;
	top: 0px;
	bottom: 0px;
	left: 0px;
	display: block;
	z-index: 1;
`;
const Menus = styled.div<{ top?: number }>`
	${({ top }) => (top ? `margin-top: ${top}px;` : "")}
`;
const ContentArea = styled.div<{ left: number; top?: number }>`
	margin-left: ${({ left }) => left}px;
	${({ top }) => `margin-top: ${top}px;`}
`;
const MenuItem = styled.div`
	&:hover {
		background-color: #e8e8e8;
	}
`;
const IconButton = styled.div`
	margin: 2px 7px;
	padding: 5px;
	border-radius: 30px;
	width: 30px;
	text-align: center;
	&:hover {
		background-color: #eee;
	}
	display: inline-block;
`;

export type SideMenuItem = {
	icon: IconDefinition;
	label: string;
	onClick: () => void;
};
export type SideMenuProps = {
	top?: number;
	items: SideMenuItem[];
	children: React.ReactNode;
};

export const SideMenuPage = React.memo((props: SideMenuProps) => {
	const { items, children, top } = props;
	const [show, setShow] = React.useState(true);
	const width = React.useMemo(() => {
		return show ? 200 : 45;
	}, [show]);
	return (
		<>
			<MenuArea width={width}>
				<Menus top={top}>
					<IconButton onClick={() => setShow((show) => !show)}>
						<FontAwesomeIcon icon={show ? faChevronLeft : faChevronRight} />
					</IconButton>
					{items.map((item, index) => (
						<MenuItem key={`side-menu-${index}`} onClick={() => item.onClick()}>
							{!show && (
								<OverlayTrigger
									placement={"right"}
									overlay={
										<Popover id="popover-basic">
											<Popover.Content>{item.label}</Popover.Content>
										</Popover>
									}
								>
									<IconButton>
										<FontAwesomeIcon icon={item.icon} />
									</IconButton>
								</OverlayTrigger>
							)}

							{show && (
								<>
									<IconButton>
										<FontAwesomeIcon icon={item.icon} />
									</IconButton>
									{item.label}
								</>
							)}
						</MenuItem>
					))}
				</Menus>
			</MenuArea>
			<ContentArea left={width} top={top}>
				{children}
			</ContentArea>
		</>
	);
});
