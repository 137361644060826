import * as React from "react";
import { reducer, initState } from "../../../reducers/report/download";
import { Loading } from "lu-component";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { fileDownloadStream, makeError } from "../../../lib/request";
import { useParams } from "react-router";
import { ReportDownloadPage, DownloadType } from "../../../components/pages/report/download";
import { AccountWithoutPassword } from "../../../../../server/models/account";

export const DownloadReportContainer = (loginAccount: AccountWithoutPassword) => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info } = state;
	const { jobId } = useParams<{ jobId: string }>();
	const onDownload = React.useCallback((type: DownloadType) => {
		dispatch({ type: "changeLoading", payload: true });
		fileDownloadStream(`/api/download/${type}/${jobId}`, `${type}.csv`)
			.then(() => {
				dispatch({ type: "changeLoading", payload: false });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	return (
		<>
			<Loading loading={loading} />
			<ConfirmBox info={info} />
			<ReportDownloadPage onDownload={onDownload} loginAccount={loginAccount} />
		</>
	);
};
