import * as React from "react";
import { FormControl, FormControlProps } from "react-bootstrap";

export type InputTextProps = Omit<FormControlProps, "onChange" | "value"> & {
	placeholder?: string;
	value: string;
	onChange: (value: string) => void;
	error?: string;
};

export const InputText = React.memo((props: InputTextProps) => {
	const { onChange, error, ...iProps } = props;
	const onChangeEvent = React.useCallback<FormControlProps["onChange"]>(
		(e) => {
			onChange(e.target.value);
		},
		[onChange]
	);
	return (
		<>
			<FormControl {...iProps} onChange={onChangeEvent} isInvalid={!!error} />
			{!!error && <FormControl.Feedback type={"invalid"}>{error}</FormControl.Feedback>}
		</>
	);
});
