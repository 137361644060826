import { Loading } from "lu-component";
import * as React from "react";
import { Prompt, useHistory, useParams } from "react-router";
import { endpoint, replaceEndpointUrl } from "../../../../../server/router/endpoint";
import {
	GetAggregateConfigResponse,
	UpsertAggregateRequest,
	UpsertAggregateResponse,
} from "../../../../../server/types/request/aggregate";
import { AggregateCreatePage, AggregateCreatePageProps } from "../../../components/pages/aggregate/create";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { fileDownloadStream, get, makeError, post, put } from "../../../lib/request";
import { reducer, initState } from "../../../reducers/aggregate/create";
import { clientEndpoint } from "../../../routes/endpoint";

export const AggreagteCreateContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, prompt, qMap, crossAggregate, ...aProps } = state;
	const history = useHistory();
	const onChangeQuenames = React.useCallback<AggregateCreatePageProps["onChangeQuenames"]>(
		(payload) => dispatch({ type: "changeQuenames", payload }),
		[]
	);
	const onChangeAxis = React.useCallback<AggregateCreatePageProps["onChangeAxis"]>(
		(payload) => dispatch({ type: "changeAxis", payload }),
		[]
	);
	const onAddSelectionGroup = React.useCallback<AggregateCreatePageProps["onAddSelectionGroup"]>(
		() => dispatch({ type: "addSelectionGroup", payload: undefined }),
		[]
	);
	const onDeleteSelectionGroup = React.useCallback<AggregateCreatePageProps["onDeleteSelectionGroup"]>(
		(name) => dispatch({ type: "deleteSelectionGroup", payload: name }),
		[]
	);
	const onChangeSelectGroups = React.useCallback<AggregateCreatePageProps["onChangeSelectGroups"]>(
		(targetName, name, value) => dispatch({ type: "changeGroupSelection", payload: { targetName, name, value } }),
		[]
	);
	const onChangeVertical = React.useCallback<AggregateCreatePageProps["onChangeVertical"]>(
		(payload) => dispatch({ type: "changeVertical", payload }),
		[]
	);
	const onChangeAggregate = React.useCallback<AggregateCreatePageProps["onChangeAggregate"]>(
		(name, value) => dispatch({ type: "changeCrossAggregate", payload: { name, value } }),
		[]
	);
	const { aggregateId, jobId } = useParams<{ aggregateId: string; jobId: string }>();
	React.useEffect(() => {
		get<GetAggregateConfigResponse>(`${endpoint.aggregate}/${jobId}/${aggregateId}`)
			.then((response) => {
				dispatch({ type: "loadAggreagteJob", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	const onSave = React.useCallback(() => {
		const { selectionGroups, axis, quenames, vertical } = aProps;
		const requestData: UpsertAggregateRequest = {
			crossAggregate,
			aggregateJob: {
				selectionGroups,
				axis,
				quenames,
				vertical,
			},
		};
		dispatch({ type: "changeLoading", payload: true });
		const request =
			crossAggregate && crossAggregate._id
				? put<UpsertAggregateResponse>(`${endpoint.aggregate}/${crossAggregate._id}`, requestData)
				: post<UpsertAggregateResponse>(`${endpoint.aggregate}/${jobId}`, requestData);
		request
			.then((response) => {
				dispatch({ type: "upsertAggregate", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [crossAggregate, aProps]);
	const onDownload = React.useCallback(() => {
		dispatch({ type: "changeLoading", payload: true });
		fileDownloadStream(`${endpoint.aggregateDownload}/${crossAggregate._id}`, `クロス集計-${crossAggregate.name}.csv`)
			.then(() => {
				dispatch({ type: "changeLoading", payload: false });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [crossAggregate]);
	const onView = React.useCallback(
		() =>
			history.push(
				replaceEndpointUrl(clientEndpoint.aggtegateView, {
					jobId: jobId.toString(),
					aggregateId: crossAggregate._id.toString(),
				})
			),
		[crossAggregate]
	);
	const onList = React.useCallback(() => history.push(clientEndpoint.aggregate), []);
	return (
		<>
			<Prompt message={"変更が保存されていません。ページを移動してよろしいですか？"} when={prompt} />
			<Loading loading={loading} text={"processing..."} />
			<ConfirmBox info={info} />
			{!!aProps.questions.length && (
				<AggregateCreatePage
					{...aProps}
					crossAggregate={crossAggregate}
					onChangeQuenames={onChangeQuenames}
					onChangeAxis={onChangeAxis}
					onAddSelectionGroup={onAddSelectionGroup}
					onDeleteSelectionGroup={onDeleteSelectionGroup}
					onChangeSelectGroups={onChangeSelectGroups}
					onChangeVertical={onChangeVertical}
					onChangeAggregate={onChangeAggregate}
					onSave={onSave}
					errors={info.errors}
					onDownload={crossAggregate && crossAggregate._id ? onDownload : undefined}
					onView={crossAggregate && crossAggregate._id ? onView : undefined}
					onList={onList}
				/>
			)}
		</>
	);
};
