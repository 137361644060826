import * as React from "react";
import { reducer, initState } from "../../../reducers/report/exective";
import { Loading } from "lu-component";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { get, makeError } from "../../../lib/request";
import { endpoint, replaceEndpointUrl } from "../../../../../server/router/endpoint";
import { useHistory, useParams } from "react-router";
import { GetExectiveReportResponse } from "../../../../../server/types/request/report/exective";
import { ExectiveReportPage } from "../../../components/pages/report/exective-report";

import { clientEndpoint } from "../../../routes/endpoint";
import { ReportBigTitle } from "../../../components/pages/report/parts/report-title";

export const ExectiveReportContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, goalSalesVolume, data } = state;
	const history = useHistory();
	const { jobId, activityId } = useParams<{
		jobId: string;
		activityId: string;
	}>();
	React.useEffect(() => {
		get<GetExectiveReportResponse>(`${endpoint.exectiveReport}/${jobId}`)
			.then((response) => {
				dispatch({ type: "loadData", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	const onSimulationPage = React.useCallback(
		() => history.push(replaceEndpointUrl(clientEndpoint.simulationReport, { jobId, activityId })),
		[]
	);
	return (
		<>
			<Loading loading={loading} />
			<ConfirmBox info={info} />
			{goalSalesVolume &&
				data.map((d, index) => {
					return (
						<React.Fragment key={`exective-${index}`}>
							<ReportBigTitle>
								{index === 0 ? "エグゼクティブサマリー" : "前回テストのエグゼクティブサマリー"}
							</ReportBigTitle>
							<ExectiveReportPage {...d} goalSalesVolume={goalSalesVolume} onSimulationPage={onSimulationPage} />
						</React.Fragment>
					);
				})}
		</>
	);
};
