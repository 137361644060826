import * as React from "react";
import { Col, Row, Button, Badge, Form, Alert } from "react-bootstrap";
import { Job } from "../../../../../../server/models/job";
import { ActivityWithBrand } from "../../../../../../server/models/activity";
import { SimpleQuestion } from "muscat-library";
import { Concept } from "../../../../../../server/models/concept";
import { Application } from "../../../../../../server/models/application";
import { AccountWithoutPassword } from "../../../../../../server/models/account";
import { ApproveStatus } from "../../../../../../server/models/status";
import { ErrorObject } from "../../../../../../server/validation/validation-base";
import Feedback from "react-bootstrap/Feedback";
import { Role } from "../../../../../../server/lib/permission/role";
import { JobDetail, Contains } from "../../../parts/job-detail";
import { ConfirmButton } from "../../../parts/confirm-dropdown-button";
import { TextRed } from "../../../parts/font";

export type ApproveSetting = {
	loginAccount: AccountWithoutPassword;
	errors?: ErrorObject;
	onApprove: () => void;
	onReject: (rejectMessage: string) => void;

	accounts: AccountWithoutPassword[];
	activity: ActivityWithBrand;
	job: Job;
	evaluationItem?: string[];
	question?: SimpleQuestion;
	concepts: Concept[];
	application: Application;
	estimate: string;
};

export const ApprovePage = React.memo((props: ApproveSetting) => {
	const { loginAccount, application, accounts, onApprove, onReject, errors, ...jobDetailProps } = props;
	// 却下理由のみのなのでこのコンポーネント内のstateで管理。
	const [rejectMessage, setRejectMessage] = React.useState<string>("");
	const mapAccount = React.useMemo(() => {
		return accounts.reduce((a, b) => {
			a.set(b._id.toString(), b.name);
			return a;
		}, new Map<string, string>());
	}, [accounts]);
	const approveStatus = React.useMemo(() => {
		for (const ap of application.approve) {
			if (!ap.status) return "申請中";
			if (ap.status === "reject") return ApproveStatus[ap.status];
		}
		return ApproveStatus.approve;
	}, [application]);
	const canSubmit = React.useMemo(() => {
		if (approveStatus !== "申請中") {
			return false;
		}
		const target = application.approve.find((ap) => !ap.status);
		return Role.isApprover(loginAccount, target.approver);
	}, [loginAccount, approveStatus, application]);
	const isLastAppove = React.useMemo(() => {
		if (application.approve.length === 1) return true;
		const aps = application.approve.concat();
		aps.pop();
		return aps.every((ap) => ap.status === "approve");
	}, [application]);
	const agreementMessage = React.useMemo(() => {
		const messgage = isLastAppove
			? "承認すると、予定開始時間に調査が開始されます。よろしいですか。"
			: "承認します。よろしいですか。";
		if (jobDetailProps.estimate.split("\n").length >= 3) {
			return (
				<>
					{messgage}
					<br />
					<br />
					<TextRed>
						調査実施費用が100万円を超える可能性があります。
						<br />
						稟議書が承認されていることをご確認ください。
					</TextRed>
				</>
			);
		}
		return messgage;
	}, [isLastAppove, jobDetailProps.estimate]);
	return (
		<div className="m-3 clearfix">
			<Row>
				<Col md={12}>
					<div className="h3 float-left">■承認画面</div>
				</Col>
			</Row>
			<Row>
				<Col md={12} className="mb-3">
					<JobDetail {...jobDetailProps} />
					<Row>
						<Col md={12}>
							<div className="h4">■申請・承認情報</div>
							<Contains field="申請メッセージ：">{application.applicationMessage}</Contains>
							<Contains field="申請者（申請日時）：">
								{mapAccount.get(application.accountId.toString())}（{application.applicationDate}）
							</Contains>
							{application.approve.map((ap, index) => {
								const num = index + 1;
								return (
									<Contains key={`approve-${index}`} field={`Approver ${num}（${num}次承認者）`}>
										<>
											{mapAccount.get(ap.approver.toString())}
											{ap.status && <Badge variant="primary">{ApproveStatus[ap.status]}</Badge>}
											{ap.approver.toString() === loginAccount._id.toString() && <Badge variant="danger">あなた</Badge>}
										</>
									</Contains>
								);
							})}
							{canSubmit && (
								<Contains field="却下理由（却下の場合必須）：">
									<Form.Control
										as="textarea"
										rows={5}
										value={rejectMessage}
										onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setRejectMessage(e.target.value)}
										isInvalid={!!errors && !!errors["rejectMessage"]}
									/>
									{!!errors && !!errors["rejectMessage"] && (
										<Feedback type={"invalid"}>{errors["rejectMessage"]}</Feedback>
									)}
								</Contains>
							)}
							{!!application.rejectMessage && <Contains field="却下理由：">{application.rejectMessage}</Contains>}
						</Col>
					</Row>
					{canSubmit ? (
						<Row className="mt-5">
							<Col className="text-center">
								<ConfirmButton
									variant="outline-secondary"
									onOk={onApprove}
									style={{ marginRight: "5px" }}
									buttonText={"承認する"}
									title={"承認"}
									body={agreementMessage}
								/>
								<ConfirmButton
									variant="outline-secondary"
									buttonText={"却下する"}
									onOk={() => onReject(rejectMessage)}
									title={"申請却下"}
									body={"却下します。よろしいですか。"}
								/>
							</Col>
						</Row>
					) : (
						<Col md={{ offset: 2, span: 8 }} className="text-center">
							<Alert
								style={{ marginTop: "10px" }}
								variant={
									approveStatus === "申請中" || approveStatus === ApproveStatus.approve ? "secondary" : "warning"
								}
							>
								{approveStatus}
							</Alert>
						</Col>
					)}
				</Col>
			</Row>
		</div>
	);
});
