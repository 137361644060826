import { Loading } from "lu-component";
import * as React from "react";
import { useParams } from "react-router";
import { AccountWithoutPassword } from "../../../../../server/models/account";
import { endpoint } from "../../../../../server/router/endpoint";
import { GetSimulationResponse } from "../../../../../server/types/request/report/simulation";
import { SimulationPage, SimulationPageProps } from "../../../components/pages/report/simulation";
import { ConfirmBox } from "../../../components/parts/confirm-box";

import { get, makeError, makeErrorBlob, makeDwnloadImage, put } from "../../../lib/request";
import { reducer, initState } from "../../../reducers/report/simulation";

export const SimulationContainer = (loginAccount: AccountWithoutPassword) => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { info, loading, ...sProps } = state;
	const { jobId } = useParams<{ jobId: string }>();
	React.useEffect(() => {
		get<GetSimulationResponse>(`${endpoint.simulation}/${jobId}`)
			.then((response) => {
				dispatch({ type: "loadData", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	const onChangeRatio = React.useCallback<SimulationPageProps["onChangeRatio"]>((target, name, value) => {
		dispatch({ type: "changeRatio", payload: { target, name, value } });
	}, []);
	const onSimulation = React.useCallback<SimulationPageProps["onSimulation"]>(
		(conceptType) => {
			const html = document.getElementsByClassName("simulation-table").item(0).outerHTML;
			dispatch({ type: "changeLoading", payload: true });
			const correctionValue = sProps.data[conceptType].correctionValue;
			makeDwnloadImage(
				`${endpoint.simulation}/${jobId}`,
				{ html, correctionValue },
				`${sProps.job.jobNum}_executive_summary.png`
			)
				.then(() => dispatch({ type: "changeLoading", payload: false }))
				.catch(async (error) => {
					dispatch({ type: "changeMessageInfo", payload: await makeErrorBlob(error, true) });
				});
		},
		[sProps.job, sProps.data]
	);
	const onSimulationSave = React.useCallback<SimulationPageProps["onSimulationSave"]>(
		(conceptType) => {
			dispatch({ type: "changeLoading", payload: true });
			const correctionValue = sProps.data[conceptType].correctionValue;
			put(`${endpoint.simulation}/${jobId}`, { correctionValue })
				.then(() =>
					dispatch({ type: "changeMessageInfo", payload: { message: "保存が完了しました。", isSuccess: true } })
				)
				.catch((error) => {
					dispatch({ type: "changeMessageInfo", payload: makeError(error, true) });
				});
		},
		[sProps.job, sProps.data]
	);
	return (
		<>
			<Loading loading={loading} />
			<ConfirmBox info={info} />
			<SimulationPage
				{...sProps}
				loginAccount={loginAccount}
				onChangeRatio={onChangeRatio}
				onSimulation={onSimulation}
				onSimulationSave={onSimulationSave}
			/>
		</>
	);
};
