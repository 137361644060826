import * as React from "react";
import { ConceptType } from "../../../../../../server/models/concept";
import { Text, BarProps, Bar } from "../common";
import { OneNumberScoreNorm } from "../../../../../../server/models/graph/demand-forecast";
import { Job } from "../../../../../../server/models/job";
import { getAnalyticsVersion, AnalyticseVersion } from "../../../../lib/action-standard";

const areaWidth = 1000;
const precedentStart = 50;
const pad = 50;
const yPad = precedentStart + 50;
const startBarY = yPad + 10;
const barHeight = 100;
const minBarHeight = 40;
const startMinBarY = barHeight + startBarY + 20;
const lineHeight = 40;
const textHeight = 130;
const areaHeight = startMinBarY + 40 + textHeight;
const precedentRadius = 10;
const radius = 15;

export type Labels = { [key in Rank]: string };

const versionLabels: { [key in AnalyticseVersion]: Labels } = {
	v1: {
		D: "下位30%",
		C: "上位40～70% 以内",
		B: "上位20～40% 以内",
		A: "上位20%以内",
	},
	v2: {
		D: "下位20%",
		C: "上位50～80% 以内",
		B: "上位20～50% 以内",
		A: "上位20%以内",
	},
};

type Rank = "A" | "B" | "C" | "D";

type CustomBarProps = BarProps & {
	job: Job;
	value?: Rank;
};

const baseFontSize = "1.6em";
const boldFontSize = "1.7em";

const CustomBar = React.memo((props: CustomBarProps) => {
	const { x, y, width, height, value, color, job } = props;
	const labels = React.useMemo<Labels>(() => {
		const version = getAnalyticsVersion(job.endDatetime);
		return versionLabels[version];
	}, [job]);
	return (
		<g>
			<g>
				<rect x={x} y={y} width={width} height={height} style={{ fill: color, opacity: 0.4 }} />
				{value && <Text x={x + width / 2} y={y + height / 2} value={value} fontSize={boldFontSize} />}
			</g>
			{value && (
				<>
					<line x1={x + width} y1={y} x2={x + width} y2={y + height + lineHeight} stroke="#ccc" />
					<foreignObject x={x} y={y + height + 10} width={width - 2} height={textHeight}>
						<p style={{ textAlign: "center", fontSize: baseFontSize }}>{value in labels ? labels[value] : ""}</p>
					</foreignObject>
					{/*<Text
						x={x + width / 2}
						y={y + height + lineHeight / 2}
						value={value in labels ? labels[value] : ""}
						fontSize={"1.3em"}
					/>*/}
				</>
			)}
		</g>
	);
});

export type NormProps = {
	oneNumberScoreNorm: OneNumberScoreNorm;
	job: Job;
	value: number;
	label?: string;
	step?: number;
	width?: number;
};

export const Norm = React.memo((props: NormProps) => {
	const { oneNumberScoreNorm, value, width, step = 10, label, job } = props;
	const { rankA: a, rankB: b, rankC: c, rankD: d } = oneNumberScoreNorm;

	const max = React.useMemo(() => {
		return a + b + c + d;
	}, [a, b, c, d]);
	const BaseLineGroup = React.useMemo(() => {
		const tmp: JSX.Element[] = [];
		let v = 0;
		while (v <= max) {
			const x = (v * (areaWidth - pad * 2)) / max + pad;
			tmp.push(
				<g key={`line-${v}`}>
					{v === 0 && <Text x={x} y={yPad - 45} value={"（下限）"} fontSize={baseFontSize} />}
					{v === max && <Text x={x} y={yPad - 45} value={"（上限）"} fontSize={baseFontSize} />}
					<Text x={x} y={yPad - 20} value={v.toFixed(1)} fontSize={baseFontSize} />
					<line x1={x} y1={yPad - 10} x2={x} y2={yPad + 10} stroke="#ccc" />
				</g>
			);
			if (v != max && v + step > max) {
				v = max;
			} else {
				v += step;
			}
		}
		return tmp;
	}, [max, step]);
	const aWidth = React.useMemo(() => ((areaWidth - pad * 2) * a) / max, [a, max]);
	const bWidth = React.useMemo(() => ((areaWidth - pad * 2) * b) / max, [b, max]);
	const cWidth = React.useMemo(() => ((areaWidth - pad * 2) * c) / max, [c, max]);
	const dWidth = React.useMemo(() => ((areaWidth - pad * 2) * d) / max, [d, max]);
	const targetX = React.useMemo(() => ((areaWidth - pad * 2) * value) / max + pad, [value, max]);
	const attribute = React.useMemo(() => {
		if (width) {
			const height = Math.round((width / areaWidth) * areaHeight);
			return { width, height };
		}
		return {};
	}, [width]);
	return (
		<svg viewBox={`0 0 ${areaWidth} ${areaHeight}`} {...attribute}>
			<g>
				{!!label && (
					<g>
						<circle
							cx={areaWidth / 2 - 10}
							cy={precedentStart - 20}
							r={precedentRadius}
							style={{ fill: "#ccc", opacity: 0.8 }}
						/>
						<Text
							x={areaWidth / 2 + precedentRadius}
							y={precedentStart - 20}
							value={label}
							textAnchor={"start"}
							fontSize={boldFontSize}
							fontWeight={"bold"}
						/>
					</g>
				)}
				<line x1={pad} x2={areaWidth - pad} y1={yPad} y2={yPad} stroke="#ccc" />
				{BaseLineGroup}
				<Bar x={pad} y={startBarY} height={barHeight} width={dWidth} color={"#f56302"} />
				<Bar x={pad + dWidth} y={startBarY} height={barHeight} width={cWidth} color={"#ffc000"} />
				<Bar x={pad + dWidth + cWidth} y={startBarY} height={barHeight} width={bWidth} color={"#83db4d"} />
				<Bar x={pad + dWidth + cWidth + bWidth} y={startBarY} height={barHeight} width={aWidth} color={"#6492e3"} />

				<circle cx={targetX} cy={startBarY + barHeight / 2} r={radius} style={{ fill: "#ccc", opacity: 0.8 }} />
				<Text
					x={targetX + radius * 2 + 15}
					y={startBarY + barHeight / 2}
					value={value.toFixed(1)}
					fontSize={baseFontSize}
				/>

				<CustomBar
					x={pad}
					y={startMinBarY}
					height={minBarHeight}
					width={dWidth}
					color={"#f56302"}
					value={"D"}
					job={job}
				/>
				<CustomBar
					x={pad + dWidth}
					y={startMinBarY}
					height={minBarHeight}
					width={cWidth}
					color={"#ffc000"}
					value={"C"}
					job={job}
				/>
				<CustomBar
					x={pad + dWidth + cWidth}
					y={startMinBarY}
					height={minBarHeight}
					width={bWidth}
					color={"#83db4d"}
					value={"B"}
					job={job}
				/>
				<CustomBar
					x={pad + dWidth + cWidth + bWidth}
					y={startMinBarY}
					height={minBarHeight}
					width={aWidth}
					color={"#6492e3"}
					value={"A"}
					job={job}
				/>
			</g>
		</svg>
	);
});
