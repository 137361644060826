import * as React from "react";
import styled from "styled-components";
import { OneNumberScoreData } from "../../../../../../../server/models/graph/demand-forecast";
import { targetGroup } from "../../../../../../../server/models/graph/target-group";
import { OneNumberScoreDetailGraph } from "./one-number-score-detail-graph";

export const SubTitle = styled.div`
	margin: 35px 0px 20px 0px;
	padding-left: 10px;
	font-weight: bold;
`;
export const TitleBox = styled.div`
	font-size: 0.9em;
	border: 1px solid #aaa;
	display: flex;
	align-items: center;
	width: 90px;
	height: 90px;
	padding: 5px;
	text-align: center;
	div {
		text-align: center;
		width: 100%;
	}
`;

export type OneNumberScoreProps = {
	oneNumberScores: OneNumberScoreData[];
};
const max = 75;
export const OneNumberScoreDetail = React.memo((props: OneNumberScoreProps) => {
	const { oneNumberScores } = props;
	const targetMap = React.useMemo(() => {
		return oneNumberScores.reduce((a, b) => {
			const target = a.get(b.target) || [];
			a.set(b.target, [...target, b]);
			return a;
		}, new Map<"category" | "strategy", OneNumberScoreData[]>());
	}, [oneNumberScores]);
	return (
		<>
			<SubTitle>購入意向　ワンナンバースコア　結果詳細</SubTitle>
			{targetMap.has("category") && (
				<table>
					<tbody>
						<tr>
							<td>
								<TitleBox>
									<div>
										カテゴリ
										<br />
										ターゲット
										<br />
										グループ
									</div>
								</TitleBox>
							</td>
							<td>
								<OneNumberScoreDetailGraph data={targetMap.get("category")} max={max} />
							</td>
						</tr>
					</tbody>
				</table>
			)}
			{targetMap.has("strategy") && (
				<table>
					<tbody>
						<tr>
							<td>
								<TitleBox>
									<div>
										戦略
										<br />
										ターゲット
										<br />
										グループ
									</div>
								</TitleBox>
							</td>
							<td>
								<OneNumberScoreDetailGraph data={targetMap.get("strategy")} max={max} />
							</td>
						</tr>
					</tbody>
				</table>
			)}
		</>
	);
});
