import * as React from "react";
import { NormalQuestion, Choice } from "muscat-library";
import styled from "styled-components";

const ChoiceArea = styled.div`
	margin-top: 2rem;
	div {
		padding: 0.2rem;
	}
`;

export type ConfigViewerProps = {
	childQuestions?: NormalQuestion[];
	choices: Choice[];
};

export const ConfigViewer = React.memo((props: ConfigViewerProps) => {
	const { choices, childQuestions } = props;
	return (
		<ChoiceArea>
			{choices.map(({ value, text }) => (
				<div key={`choice-${value}`}>
					{value}.{text}
				</div>
			))}
		</ChoiceArea>
	);
});
