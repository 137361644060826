import * as json2csv from "json2csv";

export const array2CsvBlob = (input: (string | number | boolean)[][]): Blob => {
	const json2csvParser = new json2csv.Parser({ header: false });
	const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
	const blob = new Blob([bom, json2csvParser.parse(input)], { type: "text/csv" });
	return blob;
};

export const downloadData = (blob: Blob, filename: string): void => {
	if (window.navigator.msSaveBlob) {
		window.navigator.msSaveBlob(blob, filename);
	} else {
		const link = document.createElement("a");
		link.href = window.URL.createObjectURL(blob);
		link.setAttribute("download", filename);
		document.body.appendChild(link);
		link.click();
		link.remove();
	}
};

export const isDefined = (v: any) => {
	return v !== undefined;
};
