import styled from "styled-components";

export const ScrollWrap = styled.div``;
export const AggregateConfigTable = styled.table`
	display: block;
	max-height: 500px;
	overflow-y: auto;

	thead {
		background-color: white;
		border-bottom: 1px solid #ced4da;
		display: block;
		position: sticky;
		top: 0;
	}

	th,
	td {
		padding: 8px;
	}

	tbody {
		tr {
			border-bottom: 1px solid #ced4da;

			&:hover {
				background-color: #f8f9fa;
			}
		}
		td {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	td.check {
		.form-check {
			padding: 0;
			position: static;

			input {
				margin: 0;
			}
		}
	}

	tr {
		cursor: default;

		> *:first-child {
			text-align: center;
			width: 72px;
		}
		> *:nth-child(2) {
			text-align: center;
			width: 88px;
		}
	}

	.tr--focused {
		background-color: #e9ecef;

		&:hover {
			background-color: #e9ecef;
		}
	}
`;
