import * as React from "react";
import { Container, Row, Col, Button, FormGroup, Badge, Alert } from "react-bootstrap";
import { TextRow, SelectRow, ReactMultiSelectRow, InputRow } from "../../../parts/input-row";
import { CategoryTarget } from "./category-target-group";
import {
	CategoryGroup,
	StrategyGroup,
	SimpleActivity,
	grpOptions,
	salesScaleOptions,
	productTypeOptions,
} from "../../../../../../server/models/activity";
import { Radios, Option } from "lu-component";
import { StrategicTargetForm } from "./strategic-target";
import { castString } from "../../../../lib/cast";
import { ErrorObject } from "../../../../../../server/validation/validation-base";
import { Category } from "../../../../../../server/models/category";
import { Brand } from "../../../../../../server/models/brand";
import { AccountWithoutPassword } from "../../../../../../server/models/account";
import { activityLabels } from "../../../../../../label/activity";
import { ObjectId } from "mongodb";
import { Help } from "../../../parts/help";
import { BrandRecognitionTable } from "../../../parts/recognition-table/brand";
import { CategoryRecognitionTable } from "../../../parts/recognition-table/category";
import Feedback from "react-bootstrap/esm/Feedback";
import { Title } from "../../../parts/title";
import styled from "styled-components";
import { Role } from "../../../../../../server/lib/permission/role";
import { getRecognition } from "../../../../../../server/lib/common";
import { ConfirmButton } from "../../../parts/confirm-dropdown-button";
import { TextRed } from "../../../parts/font";

const AlertText = styled.span`
	color: red;
`;

const TargetArea = styled.div`
	margin-left: 30px;
`;

export type ActivityCreatePageProps = {
	errors?: ErrorObject;
	categories: Category[];
	brands: Brand[];
	accounts: AccountWithoutPassword[];
	loginAccount: AccountWithoutPassword;

	// 構造化したものは分解してreducerで管理
	activity: SimpleActivity;
	categoryTargetGroup?: CategoryGroup;
	strategyTargetGroup?: StrategyGroup;
	hasStrategyGroup: boolean;

	cantEditMessage?: string;

	onChangeActivity: (name: keyof SimpleActivity, value: SimpleActivity[keyof SimpleActivity]) => void;
	onChangeHasStrategy: (checked: boolean) => void;

	onChangeCategoryGroup: (name: keyof CategoryGroup, value: CategoryGroup[keyof CategoryGroup]) => void;
	onChangeStrategyGroup: (name: keyof StrategyGroup, value: StrategyGroup[keyof StrategyGroup]) => void;

	onSubmit: () => void;
};

export const ActivityCreatePage = React.memo((props: ActivityCreatePageProps) => {
	const {
		activity,
		categoryTargetGroup,
		strategyTargetGroup,
		onChangeActivity,
		onChangeHasStrategy,
		onChangeCategoryGroup,
		onChangeStrategyGroup,
		categories,
		brands,
		accounts,
		loginAccount,
		errors,
		onSubmit,
		cantEditMessage,
		hasStrategyGroup,
	} = props;
	// 担当アカウントで絞り込み。
	const brandholders = React.useMemo<Option[]>(() => {
		if (!activity.brandId) return [];
		const ids = activity.accountIds.map((id) => id.toString());
		return accounts
			.filter(
				(account) =>
					!!account.brand &&
					account.brand.includes(activity.brandId) &&
					loginAccount._id !== account._id &&
					Role.isBrander(account) &&
					(account.isActive || ids.includes(account._id.toString()))
			)
			.map((account) => {
				return {
					value: account._id.toString(),
					label: account.name,
				};
			});
	}, [activity.brandId, accounts, categories, loginAccount, activity.accountIds]);
	const categoryOptions = React.useMemo<Option[]>(
		() =>
			categories.map((c) => {
				return { label: c.name, value: c._id.toString() };
			}),
		[categories]
	);
	const brandOptions = React.useMemo<Option[]>(() => {
		return brands.map((brand) => {
			return { value: brand._id.toString(), label: brand.name };
		});
	}, [brands]);
	const onChangeCategory = React.useCallback(
		(value: string | ObjectId) => {
			const category = categories.find((cate) => cate._id === value);
			if (category) {
				onChangeActivity("category", category);
			}
		},
		[categories, onChangeActivity]
	);
	const brand = React.useMemo(() => {
		if (!activity.brandId) return undefined;
		const brandId = activity.brandId.toString();
		return brands.find((b) => b._id.toString() === brandId);
	}, [brands, activity.brandId]);
	const maxRate = React.useMemo(() => {
		return getRecognition(activity, categories, brands);
	}, [activity.brandId, activity.category, brands, categories]);
	const mustCategoyTarget = React.useMemo(() => {
		if (!activity.category) return false;
		return activity.category.priceAppearance.reduce((a, b) => a + b.appearance, 0) >= 3;
	}, [activity.category]);
	const hasAlert = React.useMemo(
		() => strategyTargetGroup && strategyTargetGroup.category && strategyTargetGroup.category.length >= 8,
		[strategyTargetGroup]
	);
	return (
		<>
			{!!cantEditMessage && (
				<div className="m-3">
					<Alert variant={"danger"}>{cantEditMessage}</Alert>
				</div>
			)}
			<div className="m-3">
				<div className="h3">■アクティビティ{activity._id ? "更新" : "新規登録"}</div>
			</div>
			<Container fluid>
				<Row>
					<Col md={12}>
						<Title>
							基本設定
							<Badge pill variant={"danger"} style={{ marginLeft: "10px" }}>
								必須
							</Badge>
						</Title>
					</Col>
				</Row>
				<Row>
					<Col md={11}>
						<SelectRow
							id="activityBrand"
							name={"brandId"}
							rowName={activityLabels.brandId}
							options={brandOptions}
							value={castString(activity.brandId)}
							onChange={(value) => onChangeActivity("brandId", value)}
							errors={errors}
						/>
						<SelectRow
							id="activityCategory"
							rowName={activityLabels.category}
							name={"categoryId"}
							options={categoryOptions}
							value={activity.category ? castString(activity.category._id) : undefined}
							onChange={onChangeCategory}
							errors={errors}
						/>
						<ReactMultiSelectRow
							id="activityAccounts"
							name={"accountIds"}
							rowName={
								<>
									他のアクティビティ登録者
									<br />
									（複数選択可能）
									<Help
										id={"accountIds"}
										explain={
											"当アクティビティとそれに紐づくJOBを登録、更新、申請できる他のメンバーを選択してください。"
										}
									/>
									<Badge pill variant={"primary"} style={{ marginLeft: "10px" }}>
										任意
									</Badge>
								</>
							}
							options={brandholders}
							values={activity.accountIds as string[]}
							onChange={(value) => onChangeActivity("accountIds", value)}
							errors={errors}
						/>
						<TextRow
							id="releaseYear"
							name={"releaseYear"}
							type="number"
							rowName={activityLabels.releaseYear}
							value={activity.releaseYear ? activity.releaseYear.toString() : ""}
							onChange={(value) => onChangeActivity("releaseYear", Number(value))}
							errors={errors}
						/>
						<SelectRow
							name={"releaseMonth"}
							id="releaseMonth"
							type="number"
							rowName={activityLabels.releaseMonth}
							value={activity.releaseMonth}
							onChange={(value) => onChangeActivity("releaseMonth", value ? Number(value) : undefined)}
							options={[
								{ label: "1月", value: 1 },
								{ label: "2月", value: 2 },
								{ label: "3月", value: 3 },
								{ label: "4月", value: 4 },
								{ label: "5月", value: 5 },
								{ label: "6月", value: 6 },
								{ label: "7月", value: 7 },
								{ label: "8月", value: 8 },
								{ label: "9月", value: 9 },
								{ label: "10月", value: 10 },
								{ label: "11月", value: 11 },
								{ label: "12月", value: 12 },
							]}
							errors={errors}
						/>
						<SelectRow
							name={"productType"}
							id="productType"
							rowName={activityLabels.productType}
							value={activity.productType}
							onChange={(value) => onChangeActivity("productType", value)}
							options={productTypeOptions}
							errors={errors}
						/>
						<InputRow name={"salesScale"} rowName={activityLabels.salesScale} errors={errors}>
							<Radios
								name={"salesScale"}
								choices={salesScaleOptions}
								checked={activity.salesScale}
								onChange={(value) => onChangeActivity("salesScale", value as string)}
							/>
						</InputRow>
						<InputRow name={"grp"} rowName={activityLabels.grp} errors={errors}>
							<Radios
								name={"grp"}
								choices={grpOptions}
								checked={activity.grp}
								onChange={(value) => onChangeActivity("grp", value as string)}
							/>
						</InputRow>
						<TextRow
							id="goalRecognitionRate"
							type="number"
							name="goalRecognitionRate"
							rowName={
								<div>
									日本人（当該性の）15～69歳における
									<br />
									想定商品認知率(%)<AlertText>（{maxRate}％以下の整数で入力）</AlertText>
									<Help id={"goalRecognitionRate"} explain={"アウトプット時に、ポテンシャル需要の予測に使用されます"} />
								</div>
							}
							value={activity.goalRecognitionRate ? activity.goalRecognitionRate.toString() : ""}
							onChange={(value) => onChangeActivity("goalRecognitionRate", Number(value))}
							errors={errors}
							subText={
								<>
									{brand && <BrandRecognitionTable brand={brand} />}
									{activity.category && <CategoryRecognitionTable category={activity.category} />}
								</>
							}
						/>
						<TextRow
							id="goalSalesVolume"
							type="decimal"
							name="goalSalesVolume"
							rowName={
								<div>
									発売後12か月の目標お客さま購買
									<br />
									（万個）
									<Help
										id={"goalSalesVolume"}
										explain={
											"アウトプット時に、ポテンシャル需要予測結果のアクションスタンダードに使用されます。インバウンド等も含む、トータルの目標お客さま購買個数を入力します。小数点第1位まで入力できます。"
										}
									/>
								</div>
							}
							value={activity.goalSalesVolume ? activity.goalSalesVolume.toString() : ""}
							onChange={(value) => onChangeActivity("goalSalesVolume", Math.round(Number(value) * 10) / 10)}
							errors={errors}
						/>
						<TextRow
							id="memo"
							name="memo"
							rowName={
								<>
									{activityLabels.memo}
									<Help
										id={"memo"}
										explain={
											"アクティビティ一覧に反映されます。同発売時期、同カテゴリの商品を複数登録する場合など、アクティビティの区別のために必要な情報をメモできます。（20文字以内）"
										}
									/>
									<Badge pill variant={"primary"} style={{ marginLeft: "10px" }}>
										任意
									</Badge>
								</>
							}
							value={activity.memo || ""}
							onChange={(value) => onChangeActivity("memo", value)}
							errors={errors}
						/>
					</Col>
				</Row>
			</Container>

			<Container fluid>
				<Row>
					<Col md={12}>
						<Title>
							カテゴリターゲットグループ定義
							{mustCategoyTarget && (
								<Badge pill variant={"danger"} style={{ marginLeft: "10px" }}>
									必須
								</Badge>
							)}
							　※市場における出現率3%以上になるよう設定してください。
						</Title>
					</Col>
				</Row>
				<Row>
					{!!errors && !!errors["categoryTargetGroup"] && typeof errors["categoryTargetGroup"] === "string" && (
						<Col md={{ span: 7, offset: 2 }}>
							<Feedback type={"invalid"} style={{ display: "block" }}>
								{errors["categoryTargetGroup"]}
							</Feedback>
						</Col>
					)}
					<Col md={12}>
						{mustCategoyTarget ? (
							<FormGroup as={Row}>
								<Col md={11}>
									<CategoryTarget
										category={activity.category}
										categoryGroup={categoryTargetGroup}
										onChange={onChangeCategoryGroup}
										errors={
											!!errors && !!errors["categoryTargetGroup"] && typeof errors["categoryTargetGroup"] === "object"
												? errors["categoryTargetGroup"]
												: undefined
										}
									/>
								</Col>
							</FormGroup>
						) : (
							<Col md={7}>
								<TargetArea>
									{activity.category
										? "ノームがないため、カテゴリターゲットＧを設定できません。"
										: "カテゴリが選択されると表示されます。"}
								</TargetArea>
							</Col>
						)}
					</Col>
				</Row>
			</Container>
			<Container fluid>
				<Row>
					<Col md={12}>
						<Title>戦略ターゲットグループ設定</Title>
					</Col>
					<Col md={7}>
						<TargetArea>
							<Radios
								name={"hasStrategy"}
								checked={hasStrategyGroup}
								choices={[
									{ value: true, label: "設定する" },
									{ value: false, label: "設定しない" },
								]}
								onChange={onChangeHasStrategy}
							/>
						</TargetArea>
					</Col>
				</Row>
			</Container>
			<Container fluid>
				{strategyTargetGroup && (
					<>
						<Row>
							<Col md={12}>
								<Title>戦略ターゲットグループ定義</Title>
							</Col>
						</Row>
						<TargetArea>
							<StrategicTargetForm
								gender={activity.category ? activity.category.gender : undefined}
								strategyGroup={strategyTargetGroup}
								onChange={onChangeStrategyGroup}
								categories={categories}
								errors={
									!!errors && !!errors["strategyTargetGroup"] && typeof errors["strategyTargetGroup"] === "object"
										? errors["strategyTargetGroup"]
										: undefined
								}
							/>
						</TargetArea>
					</>
				)}
				<Row>
					<Col md={{ span: 2, offset: 4 }}>
						{!cantEditMessage ? (
							hasAlert ? (
								<ConfirmButton
									block
									buttonText={activity._id ? "更新" : "登録"}
									onOk={onSubmit}
									title={activity._id ? "更新" : "登録"}
									body={
										<TextRed>
											調査実施費用が100万円を超える可能性があります。
											<br />
											戦略ターゲットGの選択カテゴリに間違いがないか確認をお願いします。
										</TextRed>
									}
									variant={"secondary"}
								/>
							) : (
								<Button variant="secondary" block onClick={onSubmit}>
									{activity._id ? "更新" : "登録"}
								</Button>
							)
						) : (
							<Button variant="secondary" block disabled>
								{activity._id ? "更新" : "登録"}できません
							</Button>
						)}
					</Col>
				</Row>
			</Container>
		</>
	);
});
