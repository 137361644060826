import { MessageInfo } from "../../../types/info";
import { SimulationAction } from "../../../types/actions";
import { GetSimulationResponse } from "../../../../../server/types/request/report/simulation";
import { SalesVolumePrediction } from "../../../../../server/analytics/sales-volume";
import { SalesVolumeCorrectionValue } from "../../../../../server/models/simulation-value";

export type SalesVolumePredictionData = {
	value: SalesVolumePrediction;
	correctionValue: SalesVolumeCorrectionValue;
};

export type State = GetSimulationResponse & {
	loading: boolean;
	info: MessageInfo;
};

export const initState: State = {
	oneNumberScoreNorm: undefined,
	oneNumberScoreData: [],
	job: undefined,
	activity: undefined,
	data: {},
	goalSalesVolume: 0,
	constValue: undefined,
	loading: true,
	info: {
		isSuccess: true,
		message: "",
	},
	maxRecognition: undefined,
};

export const reducer = (state: State, action: SimulationAction): State => {
	switch (action.type) {
		case "loadData":
			return {
				...state,
				loading: false,
				...action.payload,
			};
		case "changeRatio":
			return {
				...state,
				data: {
					...state.data,
					[action.payload.target]: {
						...state.data[action.payload.target],
						correctionValue: {
							...state.data[action.payload.target].correctionValue,
							[action.payload.name]: action.payload.value,
						},
					},
				},
			};
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return { ...state, loading: action.payload };
		default:
			return state;
	}
};
