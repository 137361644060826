import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Button } from "react-bootstrap";
import { ButtonEvent } from ".";

/*/
import styled from "styled-components";
const AddBtn = styled.button`
	border-radius: 25px;
	border: 0px;
	width: 25px;
	height: 25px;
	font-size: 10px;
`;
/*/

export const AddButton = React.memo((props: ButtonEvent) => {
	const { onClick, text } = props;
	return (
		<Button variant="secondary" size="sm" onClick={onClick} style={{ display: "inline-flex", alignItems: "center" }}>
			<FontAwesomeIcon icon={faPlus} style={text ? { marginRight: "8px" } : {}} />
			{text}
		</Button>
	);
});
