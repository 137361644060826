import * as React from "react";
import { SalesVolumePrediction } from "../../../../../../../../server/analytics/sales-volume";
import { decimalCalc } from "../../../../../../../../server/lib/aggregate/common";
import { SalesVolumeConstValue } from "../../../../../../../../server/types/request/report/simulation";

export type UserTableProps = {
	salesVolumePrediction: SalesVolumePrediction;
	constValue: SalesVolumeConstValue;
};

export const UserTable = React.memo((props: UserTableProps) => {
	const { salesVolumePrediction, constValue } = props;
	return (
		<table className={"result-table"}>
			<thead>
				<tr>
					<th>固定変数</th>
					<th>基人口(万人)</th>
					<th>購入意向(％)</th>
					<th>定数(v1.0)</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>カテゴリユーザー</td>
					<td>{decimalCalc(salesVolumePrediction.f * 100).toFixed(1)}</td>
					<td>{decimalCalc(salesVolumePrediction.n * 100).toFixed(1)}</td>
					<td>{decimalCalc(constValue.user * 100).toFixed(1)}</td>
				</tr>
				<tr>
					<td>カテゴリノンユーザー</td>
					<td>{decimalCalc(salesVolumePrediction.e * 100).toFixed(1)}</td>
					<td>{decimalCalc(salesVolumePrediction.m * 100).toFixed(1)}</td>
					<td>{decimalCalc(constValue.nonUser * 100).toFixed(1)}</td>
				</tr>
			</tbody>
		</table>
	);
});
