import * as React from "react";
import { FormControl, FormLabel, FormGroup, Row, Button, Alert, Col, Card } from "react-bootstrap";
import Feedback from "react-bootstrap/Feedback";
import { ErrorObject } from "../../../../../server/validation/validation-base";
import styled from "styled-components";
import { Message } from "./message";

const ForgotPasswordArea = styled.div`
	color: blue;
	cursor: pointer;
	margin-top: 10px;
	margin-bottom: 20px;
	text-decoration: underline;
`;

// #c20131
export type LoginPageProps = {
	error: string;
	errors?: ErrorObject;
	onLogin: (email: string, password: string) => void;
	onPasswordChangePage: () => void;
};
export const LoginPage = React.memo((props: LoginPageProps) => {
	const { onLogin, onPasswordChangePage, error, errors } = props;
	const [email, setEmail] = React.useState<string>("");
	const [password, setPassword] = React.useState<string>("");
	const changeID = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value), []);
	const changePassword = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value),
		[]
	);
	const isDisabled = React.useMemo<boolean>(() => {
		return email === "" || password === "";
	}, [email, password]);
	const onSubmit = React.useCallback(() => onLogin(email, password), [email, password]);
	return (
		<>
			<Card className={"m-5 pt-5 pb-5"}>
				<Card.Body>
					{
						<Row className="justify-content-md-center">
							{/*<Message />*/}
							{/*<Col sm={6}>
								<Alert variant={"warning"} style={{ textAlign: "center" }}>
									4月15日（木）09:00～10:00にTest itサービスのサーバメンテナンス実施いたします。
									<br />
									この間に更新した内容は保存されない可能性がありますのでご注意ください。
								</Alert>
							</Col>
							<Col sm={11}>
								<Alert variant={"secondary"}>
									<li>
										調査にふさわしくない時期となりますので、
										<TextUnderDecorate>
											<TextRed>Test itによる配信申請と承認は、8/13（土）～8/21（日）の期間行えません。</TextRed>
										</TextUnderDecorate>
									</li>
									<li>
										お盆時に実査を行う場合、<TextRed>「調査開始日時」を必ず8/12までで設定</TextRed>
										し、<TextRed>8/12中に申請者の「配信申請」と承認者の「承認」が終わるように</TextRed>してください。
									</li>
									<li>
										※調査開始日時が8/13~8/21となっていたり、8/12中に「配信申請」と承認者の「承認」が終わらない場合は、この期間に実査は行われない、または、十分な回収が完了しません。
									</li>
									<li>
										上記はFTの夏季休暇中とも重なりますが、その前後のタイミングにおいて、
										<TextRed>
											万一システムトラブル等があっても当部が速やかにサポートできない可能性があります。事前にご了承ください。
										</TextRed>
										<br />
										なお、システムトラブルの際は、専用アドレス（
										<a href="mailto:ft-testit-info@cmi-testit.com">ft-testit-info@cmi-testit.com</a>
										）にご連絡ください。システム管理会社および当部に通知されます。
									</li>
									<li>8/13（土）～8/21（日）の期間も、調査結果の閲覧・ダウンロードなどは通常通り行えます。</li>
								</Alert>
							</Col>*/}
							{/*<Col sm={11}>
								<Alert variant={"secondary"}>
									<li>
										ゴールデンウィークは調査にふさわしくない時期となりますので、
										<TextUnderDecorate>
											<TextRed>
												Test itによる実査（配信申請と承認）は、2022/4/26（火）～2022/5/8（日）の期間行えません。
											</TextRed>
										</TextUnderDecorate>
									</li>
									<li>
										ゴールデンウイーク前は、<TextRed>「調査開始日時」を必ず4/25までで設定</TextRed>
										し、<TextRed>4/25中に申請者の「配信申請」と承認者の「承認」が終わるように</TextRed>してください。
									</li>
									<li>
										ゴールデンウイーク明けは、<TextRed>5/9以降の日付で、「調査開始日時」を設定</TextRed>
										し、「配信申請」を行ってください。
										<br />
										※調査開始日時が4/26-5/8となっていたり、4/25中に「配信申請」と承認者の「承認」が終わらない場合は、この期間に実査は行われない、または、十分な回収が完了しません。
									</li>
									<li>
										調査結果の閲覧・ダウンロードはゴールデンウイーク中も通常通り行えます。（付与されている権限に準ずる）
									</li>
								</Alert>
							</Col>*/}
							<Col sm={"auto"}>
								{/*<ul>
									<li>
										Test itは、新組織での権限付与のため、メンテナンスを行います。
										<br />
										<TextRed>
											<TextUnderDecorate>7/6（火）15:00～18:00の間は、システムにログインできません。</TextUnderDecorate>
										</TextRed>
									</li>
									<li>このメンテナンス以降、旧権限での閲覧・申請はできなくなります。</li>
									<li>
										なお、<TextUnderDecorate>7/1以降もメンテナンス完了まで、旧組織の権限での運用</TextUnderDecorate>
										となります。
										<br />
										<TextRed>
											<TextUnderDecorate>
												この期間中は、異動の対象となる方は旧組織での申請を行わないよう、ご注意ください。
												<br />
												また、配信申請時は正しい承認者が設定されているかをご確認の上、申請してください。
											</TextUnderDecorate>
										</TextRed>
									</li>
									<li>
										このメンテナンスに際して、ログインパスワードの強化を行います。
										<br />
										<TextUnderDecorate>
											メンテナンス後最初にログインする際は、パスワードの再設定をお願いします。
										</TextUnderDecorate>
									</li>
								</ul>
								<Alert variant={"secondary"}>
									【実査（調査の配信）について】
									<br />
									<li>
										年末年始は調査にふさわしくない時期となりますので、
										<TextRed>
											<TextUnderDecorate>
												Test itによる実査（配信申請と承認）は、2021/12/19（日）～2022/1/12（水）の期間行えません。
											</TextUnderDecorate>
										</TextRed>
									</li>
									<li>
										年内は、
										<TextRed>
											「調査開始日時」を必ず12/18までで設定し、12/18中に申請者の「配信申請」と承認者の「承認」が終わるように
										</TextRed>
										してください。
									</li>
									<li>
										年明けは、
										<TextRed>1/13以降の日付で、「調査開始日時」を設定</TextRed>
										し、「配信申請」を行ってください。
										<br />
										※調査開始日時が12/19-1/12となっていたり、12/18中に「配信申請」と承認者の「承認」が終わらない場合は、この期間に実査は行われない、または、十分な回収が完了しません。
									</li>
									<br />
									【調査結果の閲覧・ダウンロードについて】
									<br />
									<li>
										システムメンテナンスのため、
										<TextRed>
											<TextUnderDecorate>
												2021/12/23（木）～2021/12/31（金）までは、Test itにアクセスできなくなります。
											</TextUnderDecorate>
										</TextRed>
									</li>
									<li>
										2022/1/1（土）～2022/1/12（水）の期間は、調査結果の閲覧・ダウンロードが可能です。（付与されている権限に準ずる）
										<br />
										※ただし、1/12に新組織での権限付与を行います。1/1-1/12に限り、旧組織の権限での運用となります、ご了承ください。
									</li>
								</Alert>*/}
								<Alert variant={"secondary"}>
									【初回ログイン方法について】
									<br />
									初回ログインの際は、パスワードを再設定する必要があります。
									<br />
									「パスワードを忘れた・変更の方はこちら」をクリックし、パスワードを再設定しログインして下さい。
								</Alert>
							</Col>
						</Row>
					}
					{error && (
						<div className="justify-content-md-center">
							<Alert variant={"danger"}>{error}</Alert>
						</div>
					)}
					<FormGroup as={Row} className="justify-content-md-center">
						<FormLabel column sm={2}>
							メールアドレス
						</FormLabel>
						<Col sm={6}>
							<FormControl
								placeholder="****@example.com"
								onChange={changeID}
								isInvalid={!!error || (!!email && errors && "email" in errors)}
							/>
							{errors && "email" in errors && <Feedback type={"invalid"}>{errors["email"]}</Feedback>}
						</Col>
					</FormGroup>
					<FormGroup as={Row} className="justify-content-md-center">
						<FormLabel column sm={2}>
							password
						</FormLabel>
						<Col sm={6}>
							<FormControl
								type="password"
								placeholder="Password"
								onChange={changePassword}
								isInvalid={!!error || (!!password && errors && "password" in errors)}
							/>
							{errors && "password" in errors && <Feedback type={"invalid"}>{errors["password"]}</Feedback>}
						</Col>
					</FormGroup>
					<Row>
						<Col md={{ span: 4, offset: 6 }}>
							<ForgotPasswordArea onClick={onPasswordChangePage}>
								パスワードを忘れた・変更の方はこちら
							</ForgotPasswordArea>
						</Col>
					</Row>

					<Row className="justify-content-md-center">
						<Col sm={4}>
							<Button variant={"dark"} block disabled={isDisabled} onClick={onSubmit}>
								Login
							</Button>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
});
