import * as React from "react";
import { ReportTitle } from "../../parts/report-title";
import { ActionStandardTable, SummaryProps } from "./action-standard-table";
import { ConceptType, ConceptKey } from "../../../../../../../server/models/concept";
import {
	DemandForecast,
	OneNumberScoreNorm,
	OneNumberScoreData,
	OneNumberTarget,
} from "../../../../../../../server/models/graph/demand-forecast";
import { judgeNorm, judgeOneNumberScore } from "../../../../../lib/action-standard";
import { Job } from "../../../../../../../server/models/job";

export type ActionStandardData = SummaryProps & {
	conceptType: keyof typeof ConceptType;
};
export type ActionStandardProps = {
	demandForecast: DemandForecast[];
	oneNumberScoreNorm?: OneNumberScoreNorm;
	goalSalesVolume: number;
	oneNumberScores: OneNumberScoreData[];
	job: Job;
};

export const ActionStandard = React.memo((props: ActionStandardProps) => {
	const { demandForecast, oneNumberScoreNorm, oneNumberScores, goalSalesVolume, job } = props;
	const data = React.useMemo<ActionStandardData[]>(() => {
		const baseLine = (goalSalesVolume * 50) / 100;
		const map = oneNumberScores.reduce(
			(a, b) => {
				if (!(b.conceptType in a)) a[b.conceptType] = {};
				a[b.conceptType][b.target] = b;
				return a;
			},
			{} as {
				[concept in ConceptKey]?: { [target in OneNumberTarget]?: OneNumberScoreData };
			}
		);
		return demandForecast.map((df) => {
			const tmp: ActionStandardData = {
				label: ConceptType[df.conceptType],
				conceptType: df.conceptType,
				potential: df.sellPotential.total >= baseLine ? "〇" : "×",
				job,
			};
			const categoryConceptScore =
				map[df.conceptType] && map[df.conceptType].category ? map[df.conceptType].category : undefined;
			const strategyConceptScore =
				map[df.conceptType] && map[df.conceptType].strategy ? map[df.conceptType].strategy : undefined;
			if (!categoryConceptScore && !strategyConceptScore) return tmp;
			if (categoryConceptScore && oneNumberScoreNorm) {
				tmp.norm = judgeNorm(categoryConceptScore.score, oneNumberScoreNorm);
			}
			if (map.benchmark) {
				const { category, strategy } = map.benchmark;
				tmp.vsBenchmark = {};
				if (category) {
					tmp.vsBenchmark.category = judgeOneNumberScore(
						categoryConceptScore.score,
						category.score,
						category.sampleSize
					);
				}
				if (strategy) {
					tmp.vsBenchmark.strategy = judgeOneNumberScore(
						strategyConceptScore.score,
						strategy.score,
						strategy.sampleSize
					);
				}
			}
			if (map.currentProduct) {
				const { category, strategy } = map.currentProduct;
				tmp.vsCurrent = {};
				if (category) {
					tmp.vsCurrent.category = judgeOneNumberScore(categoryConceptScore.score, category.score, category.sampleSize);
				}
				if (strategy) {
					tmp.vsCurrent.strategy = judgeOneNumberScore(strategyConceptScore.score, strategy.score, strategy.sampleSize);
				}
			}
			return tmp;
		});
	}, [demandForecast, oneNumberScoreNorm, oneNumberScores, goalSalesVolume, job]);
	return (
		<>
			<ReportTitle>アクションスタンダード判定結果</ReportTitle>
			{data.map((d) => (
				<ActionStandardTable key={d.conceptType} {...d} />
			))}
		</>
	);
});
