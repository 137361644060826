import * as React from "react";
import { FormControl } from "react-bootstrap";
import styled from "styled-components";

export type Event = {
	label: string;
	event: () => void;
};

export type SelectableButtonProps = {
	events: Event[];
	onClose: () => void;
};

const EventButton = styled.div`
	padding: 5px;
	margin: 3px 18px 3px 3px;
	background-color: #ddd;
	border-radius: 10px;
	display: inline-block;
	width: 150px;
`;
const CloseBotton = styled.div`
	position: absolute;
	right: 0px;
	font-size: 12px;
	border-radius: 18px;
	width: 18px;
	height: 18px;
	color: white;
	background-color: black;
`;

export const SelectableButton = React.memo((props: SelectableButtonProps) => {
	const { onClose, events } = props;
	return (
		<>
			<CloseBotton onClick={onClose}>×</CloseBotton>
			{events.map(({ label, event }, index) => (
				<EventButton key={`event-${index}`} onClick={event}>
					{label}
				</EventButton>
			))}
		</>
	);
});
