import { ObjectId } from "mongodb";

export const ConceptType = {
	test1: "テスト品1",
	test2: "テスト品2",
	benchmark: "競合品",
	currentProduct: "現行品",
} as const;

export type ConceptKey = keyof typeof ConceptType;
export type ConceptLabel = typeof ConceptType[ConceptKey];

export const conceptOptions = Object.entries(ConceptType).map(([value, label]) => {
	return { label, value } as { label: string; value: ConceptKey };
});

export type ImageInfo = {
	url: string;
	width: number; // heightは自動調整
};

export type Concept = {
	_id?: ObjectId | string;
	jobId: ObjectId | string;
	type: keyof typeof ConceptType;
	logo: ImageInfo; // 画像のurl
	package?: ImageInfo; //パッケージ画像のurl
	sentences: Sentence[]; //コンセプト文の選択肢
	sku: Sku[]; // sku
	conseptUrl: string; //コンセプト画像URL
	// rtb: string; // reason to believe のフラグ
};

export interface Sentence {
	sentence: string;
	bold?: boolean;
}
export interface Sku {
	name: string;
	price: number;
	includeTaxPrice: number;
}
