import { Enquete, Language, EnqueteStatus, QuotaQueTag, WrapQuestion, Choice } from "muscat-library";
import {
	skinAttributeOptions,
	sensitiveSkinOptions,
	channelOptions,
	beautyTypeOptions,
	fragranceOptions,
	Children,
	foundationTypeOptions,
} from "../../models/activity";
import { priceChoices } from "../../models/category";
import { occupationChoices, marriedChoices, purchaseIntentChoices, evaluationChoices } from "./static";
export const generalMinAge = 15;
export const generalMaxAge = 69;
export const genderQuename = "S3";
export const ageQuename = "S4";
export const marriedQuename = "S5";
export const familyQuename = "S6";
export const occupationQuename = "S7";
export const skinAttributeQuename = "S8";
export const sensitiveSkinQuename = "S9";
export const categoryQuename = "S10";
export const foundationTypeQuename = "SQ10";
export const priceQuename = "S11";
export const channelQuename = "S12";
export const usedBrandQuename = "S13";
export const useBrandQuename = "S14";
export const beautyTypeQuename = "S15";
export const fregranceQuename = "S16";
export const lastFaceQuename = "E7";
export const sourceOfInfoQuename = "Q8";
export const conceptQuename = "C1";
export const isBoostQuename = "C2";
export const purchaseIntentQuename = "Q1";
export const skuPurchaseQuename = "Q2";
export const conceptImpressionQuename = "Q3";
export const evaluationQuename = "Q4";
export const elementEvaluationMAQuename = "Q5";
export const elementEvaluationSAQuename = "Q6";

// 最大回収は60000だが、足が出るとその分rangeがあがるので-1000する。
export const maxAnsweredLimit = 60000;
export const maxAnswered = maxAnsweredLimit - 1000;

export const familyChoices: Choice[] = [
	{
		value: 1,
		text: "配偶者（夫・妻）",
		condition_script: `${marriedQuename}= 2`,
	},
	{ value: 2, text: Children.preschool },
	{ value: 3, text: Children.elementarySchool },
	{ value: 4, text: Children.juniorHighSchool },
	{ value: 5, text: Children.highSchool },
	{ value: 6, text: Children.university },
	{ value: 7, text: "父親（義父含む）" },
	{ value: 8, text: "母親（義母含む）" },
	{ value: 9, text: "その他の家族（兄弟・姉妹、祖父母、孫など）" },
	{ value: 10, text: "その他（友人、恋人・パートナーなど）" },
	{ value: 11, text: "ひとり暮らし（同居している人はいない）", exclusive: true },
];

export const baseWrapQuestions: WrapQuestion[] = [
	{
		terminate_condition: "S1=2",
		terminate_page: "fin1",
		questions: [
			{
				quename: "S1",
				quelabel: "守秘義務",
				queCount: 0,
				quetitle: "こちらの内容にご同意いただき、本アンケートにご参加いただけますか。",
				afterText: `
					<div align="center">
						<div style="border:2px solid black;text-align:center;margin:5px;padding:5px;background-color:white;max-width:980px;">
							<div style="font-weight:bold;text-align:center;font-size:16px;padding:10px;">【調査内容についての守秘義務遵守のお願い】</div>
							<div style="color:red;text-align:center;padding:10px;">
								当アンケートの内容および当アンケートで知り得た情報は、決して第三者に開示または漏えいしないようお願いいたします。<br>
								また、<u>調査で提示された内容や画像等の写真を撮ること、<br>調査で提示された内容や画像等を、掲示板やホームページへ書き込む行為も固く禁止</u>しております。
							</div>
						</div>
					</div>
					`,
				type: "S",
				choice_group: [
					{
						id: 1,
						choices: [
							{ value: 1, text: "同意する" },
							{ value: 2, text: "同意しない" },
						],
					},
				],
			},
		],
	},
	{
		terminate_condition: "S2=1~3,9,10",
		terminate_page: "fin1",
		questions: [
			{
				quename: "S2",
				quetitle:
					"あなたご自身を含めて、ご家族の中で以下のご職業に従事している方はいらっしゃいますか。（あてはまるものすべて）",
				quelabel: "除外職業",
				queCount: 0,
				type: "M",
				choice_group: [
					{
						id: 1,
						choices: [
							{ value: 1, text: "テレビ、ラジオ、新聞、雑誌などのマスコミ関係" },
							{ value: 2, text: "広告代理店、マーケティング会社、市場調査会社" },
							{ value: 3, text: "インターネット広告／流通／販売会社" },
							{ value: 4, text: "食品関係の製造会社、卸/問屋、販売店" },
							{ value: 5, text: "清涼飲料関係の製造会社、卸/問屋、販売店" },
							{ value: 6, text: "アルコール飲料関係の製造会社、卸/問屋、販売店" },
							{ value: 7, text: "自動車関係の製造会社、販売店、代理店" },
							{ value: 8, text: "病院・薬局など医療･保健関係" },
							{ value: 9, text: "化粧品・トイレタリー関係の製造会社、卸/問屋、販売店" },
							{ value: 10, text: "デパート、スーパー、コンビニ、ドラッグストアなどの流通関係" },
							{ value: 11, text: "あてはまるものはない", exclusive: true },
						],
					},
				],
			},
		],
	},
	{
		terminate_condition: "",
		terminate_page: "fin1",
		questions: [
			{
				quename: genderQuename,
				quelabel: "性別",
				quota_que_tag: QuotaQueTag.sex_type,
				queCount: 0,
				quetitle: "あなたの性別をお知らせください。",
				type: "S",
				choice_group: [
					{
						id: 1,
						choices: [
							{ value: 1, text: "男性" },
							{ value: 2, text: "女性" },
						],
					},
				],
			},
			{
				quename: ageQuename,
				quota_que_tag: QuotaQueTag.age,
				quelabel: "年齢",
				quetitle: "あなたの年齢をお知らせください。",
				type: "I",
				suffix: "歳",
			},
		],
	},
	{
		questions: [
			{
				quename: marriedQuename,
				quelabel: "未既婚",
				quetitle: "あなたはご結婚されていますか。",
				queCount: 0,
				type: "S",
				choice_group: [
					{
						id: 1,
						choices: marriedChoices,
					},
				],
			},
		],
	},
	{
		questions: [
			{
				quename: familyQuename,
				quelabel: "同居家族",
				queCount: 0,
				quetitle:
					"あなたが現在同居されているご家族をお答えください。<br><font color='blue'>※「子ども」についてはご自身のお子さまについてお答えください。</font>",
				type: "M",
				choice_group: [
					{
						id: 1,
						choices: familyChoices,
					},
				],
			},
		],
	},
	{
		questions: [
			{
				quename: occupationQuename,
				quelabel: "職業",
				quetitle: "あなたの職業をお知らせください。",
				type: "S",
				choice_group: [
					{
						id: 1,
						choices: occupationChoices,
					},
				],
			},
		],
	},
	{
		questions: [
			{
				quename: skinAttributeQuename,
				type: "M",
				quelabel: "肌悩み",
				quetitle: "ご自身の<u>顔の肌悩み<u>に関して、あてはまるものをお選びください。",
				choice_random: true,
				choice_group: [
					{
						id: 1,
						choices: skinAttributeOptions.map((s) => {
							if (s.label === "その他") {
								return {
									keep_position: true,
									value: Number(s.value) + 1,
									text: s.label as string,
								};
							}
							return {
								value: Number(s.value) + 1,
								text: s.label as string,
							};
						}),
					},
					{
						id: 2,
						choices: [{ value: 99, text: "特にない", exclusive: true }],
					},
				],
			},
		],
	},
	{
		questions: [
			{
				quename: sensitiveSkinQuename,
				quetitle: "ご自身の<u>顔の肌タイプ</u>をお知らせください。",
				quelabel: "肌タイプ",
				type: "S",
				choice_group: [
					{
						id: 1,
						choices: sensitiveSkinOptions.map((s) => {
							return {
								value: Number(s.value) + 1,
								text: s.label as string,
							};
						}),
					},
				],
			},
		],
	},
	// S10〜S16は作成しなければならない。
	{
		htmlheader: `
				<style type="text/css">
					#S10_area dl {
						margin-top: 40px;
					}
				</style>
			`,
		questions: [
			{
				quename: categoryQuename,
				quelabel: "使用カテゴリ",
				quetitle:
					"あなたが<u>過去1年以内にご自分で購入</u>し、<u>お使いになったことがある化粧品</u>をお知らせください。",
				type: "M",
				// TODO: カテゴリはDBより生成
				choice_group: [
					{
						id: 1,
						choices: [],
					},
					{
						id: 2,
						choices: [{ value: 99, text: "この中にはひとつもない", exclusive: true }],
					},
				],
			},
		],
	},
	{
		display_condition: "", // 対象のカテゴリによって内容を変更
		questions: [
			{
				quename: foundationTypeQuename,
				quelabel: "リキッドファンデーションタイプ",
				type: "M",
				quetitle: `「リキッドファンデーション（クッション・クリーム・エマルジョン含む）」を過去1年以内に購入し、お使いになったことがある方にうかがいます。<br>あなたが過去1年以内に購入し、お使いになったファンデーションは、次のどのタイプでしたか。あてはまるものを<u>すべて</u>お選びください。`,
				choice_group: [
					{
						id: 1,
						choices: foundationTypeOptions.map<Choice>(({ value, label }) => ({
							value: Number(value),
							text: label as string,
						})),
					},
				],
			},
		],
	},
	{
		display_condition: "", //.対象のカテゴリによって内容を変更
		questions: [
			{
				quename: priceQuename,
				quetitle:
					"以下について、あなたが過去1年以内に購入された商品は、<u>1個あたり</u>（税抜き）いくらでしたか。<br>複数購入されている場合は、あてはまるものを<u>すべて</u>お選びください。",
				quelabel: "カテゴリ×価格帯",
				type: "MT",
				vertical: true,
				child_questions_group: [{ id: 1, questions: [] }],
				choice_group: [
					{
						id: 1,
						choices: priceChoices
							.map((choice) => {
								return {
									value: choice.value,
									text: choice.text,
								} as Choice;
							})
							.concat({ value: 99, text: "わからない／覚えていない", exclusive: true }),
					},
				],
			},
		],
	},
	{
		display_condition: "", //.対象のカテゴリによって内容を変更
		questions: [
			{
				quename: channelQuename,
				quetitle: "以下について、あなたは<b>過去1年以内</b>に、どちらでお買いになりましたか。（あてはまるものすべて）",
				quelabel: "カテゴリ×購入チャネル",
				type: "MT",
				vertical: true,
				child_questions_group: [{ id: 1, questions: [] }],
				choice_group: [
					{
						id: 1,
						choices: channelOptions.map((s) => {
							return {
								value: Number(s.value) + 1,
								text: s.label as string,
							};
						}),
					},
				],
			},
		],
	},
	/**
	 * S13〜S14はカテゴリごとにブランドを取得。
	 */
	// 以下消される可能性のある設問
	{
		questions: [
			{
				quename: beautyTypeQuename,
				quetitle:
					"あなたが<u>過去1年以内</u>に使用したスキンケアアイテムは、どのような効果や機能があるものですか。（あてはまるものすべて）",
				type: "MT",
				vertical: false,
				quelabel: "美類タイプ",
				child_questions_group: [{ id: 1, questions: [] }],
				choice_group: [
					{
						id: 1,
						choices: beautyTypeOptions.map((s) => {
							return {
								value: Number(s.value) + 1,
								text: s.label as string,
							};
						}),
					},
				],
			},
		],
	},
	{
		questions: [
			{
				quename: fregranceQuename,
				quetitle: "あなたが過去1年以内に使用した制汗剤は、どのようなタイプですか。（あてはまるものすべて）",
				type: "M",
				quelabel: "無香/賦香",
				choice_group: [
					{
						id: 1,
						choices: fragranceOptions.map((s) => {
							return {
								value: Number(s.value) + 1,
								text: s.label as string,
							};
						}),
					},
				],
			},
		],
	},
	{
		htmlheader: `
				<style type="text/css">
					#${conceptQuename}_area,#${isBoostQuename}_area{
						display:none;
					}
				</style>
				<script type="text/javascript" src="/script/testit/index.js?a=${Math.random()}"/>
			`,
		sc_fin: true,
		terminate_page: "fin1",
		terminate_condition: "C1=99",
		questions: [
			{
				quename: conceptQuename,
				quelabel: "表示コンセプト",
				queCount: 0,
				quetitle:
					"この画面はテスト回答時のみ表示されます。本調査に表示するコンセプトを選択してください。（実査時は自動で選択されます。）",
				type: "S",
				// コンセプトを入れ込む。最大4つとなる。
				choice_group: [
					{
						id: 1,
						choices: [
							{ value: 1, text: "" },
							{ value: 2, text: "" },
							{ value: 3, text: "" },
							{ value: 4, text: "" },
							{ value: 99, text: "該当なし" },
						],
					},
				],
			},
			{
				quename: isBoostQuename,
				quelabel: "ブーストかどうか",
				quetitle: "",
				queCount: 0,
				type: "M",
				isoptional: true,
				choice_group: [
					{
						id: 1,
						choices: [{ value: 1, text: "ブーストサンプル" }],
					},
				],
			},
		],
	},
	/*
	 * スクリーニング調査はここまで
	 */
	// 本調査
	{
		questions: [
			{
				afterText: "[[[C1回答]]]",
				quename: purchaseIntentQuename,
				type: "S",
				quetitle: "この説明文の商品について、あなたはどの程度買いたいと思いますか。",
				quelabel: "購入意向",
				choice_group: [
					{
						id: 1,
						choices: purchaseIntentChoices,
					},
				],
			},
		],
	},
	// 以下は不要なものは削除
	{
		display_condition: "C1=1 and Q1=1,2",
		questions: [
			{
				quename: `${skuPurchaseQuename}-1`,
				quetitle: "この中で、どの商品を買いたいと思われましたか。（あてはまるものすべて）",
				afterText: "[[[C1回答]]]",
				quelabel: "購入したい新商品（テスト品1）",
				type: "M",
				choice_group: [
					{
						id: 1,
						choices: [{ value: 1, text: "未設定" }],
					},
				],
			},
		],
	},
	{
		display_condition: "C1=2 and Q1=1,2",
		questions: [
			{
				quename: `${skuPurchaseQuename}-2`,
				quetitle: "この中で、どの商品を買いたいと思われましたか。（あてはまるものすべて）",
				afterText: "[[[C1回答]]]",
				quelabel: "購入したい新商品（テスト品2）",
				type: "M",
				choice_group: [
					{
						id: 1,
						choices: [{ value: 1, text: "未設定" }],
					},
				],
			},
		],
	},
	{
		display_condition: "C1=3 and Q1=1,2",
		questions: [
			{
				quename: `${skuPurchaseQuename}-3`,
				afterText: "[[[C1回答]]]",
				quetitle: "この中で、どの商品を買いたいと思われましたか。（あてはまるものすべて）",
				quelabel: "購入したい新商品（競合品）",
				type: "M",
				choice_group: [
					{
						id: 1,
						choices: [{ value: 1, text: "未設定" }],
					},
				],
			},
		],
	},
	{
		display_condition: "C1=4 and Q1=1,2",
		questions: [
			{
				afterText: "[[[C1回答]]]",
				quename: `${skuPurchaseQuename}-4`,
				quetitle: "この中で、どの商品を買いたいと思われましたか。（あてはまるものすべて）",
				quelabel: "購入したい新商品（現行品）",
				type: "M",
				choice_group: [
					{
						id: 1,
						choices: [{ value: 1, text: "未設定" }],
					},
				],
			},
		],
	},
	{
		htmlheader: `
			<script type="text/javascript">
				if(/preview/.test(location.pathname) === false) {
					var $submit = $("#submit");
					var minLength = 5;
					var maxLength = 400;
					var waitSec = 10;
					var $input = $("[name='${conceptImpressionQuename}']");
					function addKeyupEvent () {
						$input.before($("<div>").html("<div><span class='moji-count'>" + count() + "</span>文字</div>"));
						$input.on("keyup", function () {
							$("span.moji-count").text(count());
						});
					}
					function checkMojisuu() {
						$("div.error_message").remove();
						var mCount = count();
						if (mCount < minLength) {
							$input.before($("<div>").addClass("error_message").text("もう少し詳しく記載ください。"));
						} else if(mCount > maxLength){
							$input.before($("<div>").addClass("error_message").text(maxLength + "文字以内で入力してください。"));
						} else {
							$submit.off("click", checkMojisuu);
							submitManager.forceSubmit();
						}
					}
					if($("div.error_message").length === 0){
						console.log("load page");
						$submit.hide();
						$input.hide();
						$input.before($("<div>").addClass("wait-message").text(waitSec + "秒後に入力欄が表示されます。"));
						setTimeout(function () {
							$submit.show();
							$input.show();
							$(".wait-message").remove();
							// addKeyupEvent();
						}, waitSec * 1000);
					} else {
						console.log("error page");
						// addKeyupEvent();
					}
					submitManager.changeCanSubmit(false);
					function count() {
						return $input.val().replace(/[\\n\\t\\s\t　]/g, "").length;
					}
					$submit.on("click", checkMojisuu);
				}
			</script>
		`,
		questions: [
			{
				afterText: "[[[C1回答]]]",
				quename: conceptImpressionQuename,
				type: "O",
				quelabel: "印象",
				quetitle:
					"この説明書をご覧になって、あなたはこの商品に対して、どのような印象を持ちましたか。<br>「どのような商品だと思ったか」「興味をひかれた点」「○○だから使ってみたい／使いたくない」「期待する効果や機能」など、ご自由にお答え下さい。",
				rows: 4,
				cols: 40,
			},
		],
	},
	{
		htmlheader: `
		<style type="text/css">
			table#Q4 .names_area{
				width:300px;
			}
			@media screen and (max-width:600px) { 
				table#Q4 .names_area{
					width:220px;
				}
			}
			@media screen and (max-width:460px) { 
				table#Q4 .names_area{
					width:180px;
				}
			}
		</style>
		<script type="text/javascript">
			if(/preview/.test(location.pathname) === false) {
				$("#submit").hide();
				var $tbody = $("table#Q4 tbody");
				// $trを消す。
				var $tr = $tbody.find("tr:not(.arrow-area)");
				$tr.hide();
				var $nextBUtton = $("<button>").text("次の項目");
				var $nextArea = $("<div>").css({ "text-align": "center", "margin-top" : "10px" });
				$nextArea.append($nextBUtton);
				$("#Q4_area").find(".IAREA").after($nextArea);
				var open = 4;
				var opened = open;
				var trLength = $tr.length;
				var $targetTr = $tbody.find("tr:not(.arrow-area):lt(" + opened + ")");
				$targetTr.show();
				$nextBUtton.click(function () {
					$("div.error_message").remove();
					var errors = checkAnswered($targetTr);
					if (errors.length) {
						$("table#Q4").before($("<div>").addClass("error_message").text(errors.join("\\n")));
					} else if (opened >= trLength) { 
						$nextArea.remove();
						$("#submit").click();
						$("#submit").show();
					}else { 
						$targetTr.hide();
						var tmp = opened >= 12 ? 5 : open;
						opened += tmp;
						$targetTr = $tbody.find("tr:not(.arrow-area):lt(" + opened + "):gt(" + (opened - tmp - 1) + ")");
						$targetTr.show();
					}
					return false;
				});
				function checkAnswered($targetTr) { 
					var errors = [];
					for (var i = 0; i < $targetTr.length; i++){
						var $target = $targetTr.eq(i);
						if ($target.find(":radio:checked").length === 0) { 
							errors.push($target.find(".names_area").text() + "について回答を選択してください。");
						}
					}
					return errors;
				}
			}
		</script>`,
		questions: [
			{
				afterText: "[[[C1回答]]]",
				quename: evaluationQuename,
				queCount: 3,
				quelabel: "印象評価",
				quetitle: "この説明文の商品について、次のそれぞれについて、最もお気持ちに近いものをお選びください。",
				type: "MT",
				vertical: false,
				child_questions_random: true,
				child_questions_group: [
					{
						id: 1,
						questions: [
							{ quename: "s1", quetitle: "新しさを感じる", type: "S" },
							{ quename: "s2", quetitle: "他の商品とは違っている", type: "S" },
							{ quename: "s3", quetitle: "自分向けの商品だと思う", type: "S" },
							{ quename: "s4", quetitle: "効果が期待できそう", type: "S" },
							{ quename: "s5", quetitle: "自分の魅力を高めてくれそう", type: "S" },
							{ quename: "s6", quetitle: "自分の悩みや不安を解消してくれそう", type: "S" },
							{ quename: "s7", quetitle: "使うと気分が上がりそう", type: "S" },
							{ quename: "s8", quetitle: "商品の特徴がわかりやすい", type: "S" },
							{ quename: "s9", quetitle: "このブランドの他の商品も使ってみたくなる", type: "S" },
							{ quename: "s10", quetitle: "信じられる", type: "S" },
							{ quename: "s11", quetitle: "価格が高すぎる", type: "S" },
							{ quename: "s12", quetitle: "買い求めやすい", type: "S" },
						],
					},
					/* 追加項目があればgroupを作る。
					{
						id: 2,
						questions: [],
					},
					*/
				],
				choice_group: [
					{
						id: 1,
						choices: evaluationChoices,
					},
				],
			},
		],
	},
	// コンセプト1品目
	{
		display_condition: "C1=1",
		questions: [
			{
				afterText: "[[[C1回答]]]",
				quename: `${elementEvaluationMAQuename}-1`,
				quetitle:
					"この説明文をご覧になって、あなたが<u>印象に残ったところ</u>をすべてお選びください。（あてはまるものすべて）",
				quelabel: "印象要素MA（テスト品1）",
				type: "M",
				choice_group: [
					{
						id: 1,
						choices: [{ value: 1, text: "未設定" }],
					},
					{
						id: 2,
						choices: [{ value: 99, text: "ひとつもない", exclusive: true }],
					},
				],
			},
		],
	},
	{
		display_condition: "C1=1 and Q5-1!=99", // 排他の選択肢は99とする。
		questions: [
			{
				afterText: "[[[C1回答]]]",
				quename: `${elementEvaluationSAQuename}-1`,
				quetitle: "その中で、あなたが最も印象に残ったところをお選びください。（ひとつだけ）",
				quelabel: "印象要素SA（テスト品1）",
				type: "S",
				choice_group: [
					{
						id: 1,
						choices: [{ value: 1, text: "未設定" }],
					},
				],
			},
		],
	},
	// コンセプト2品目
	{
		display_condition: "C1=2",
		questions: [
			{
				afterText: "[[[C1回答]]]",
				quename: `${elementEvaluationMAQuename}-2`,
				quetitle:
					"この説明文をご覧になって、あなたが<u>印象に残ったところ</u>をすべてお選びください。（あてはまるものすべて）",
				quelabel: "印象要素MA（テスト品2）",
				type: "M",
				choice_group: [
					{
						id: 1,
						choices: [{ value: 1, text: "未設定" }],
					},
					{
						id: 2,
						choices: [{ value: 99, text: "ひとつもない", exclusive: true }],
					},
				],
			},
		],
	},
	{
		display_condition: "C1=2 and Q5-2!=99", // 排他の選択肢は99とする。
		questions: [
			{
				afterText: "[[[C1回答]]]",
				quename: `${elementEvaluationSAQuename}-2`,
				quetitle: "その中で、あなたが最も印象に残ったところをお選びください。（ひとつだけ）",
				quelabel: "印象要素SA（テスト品2）",
				type: "S",
				choice_group: [
					{
						id: 1,
						choices: [{ value: 1, text: "未設定" }],
					},
				],
			},
		],
	},
	// コンセプト3品目
	{
		display_condition: "C1=3",
		questions: [
			{
				afterText: "[[[C1回答]]]",
				quename: `${elementEvaluationMAQuename}-3`,
				quetitle:
					"この説明文をご覧になって、あなたが<u>印象に残ったところ</u>をすべてお選びください。（あてはまるものすべて）",
				quelabel: "印象要素MA（競合品）",
				type: "M",
				choice_group: [
					{
						id: 1,
						choices: [{ value: 1, text: "未設定" }],
					},
					{
						id: 2,
						choices: [{ value: 99, text: "ひとつもない", exclusive: true }],
					},
				],
			},
		],
	},
	{
		display_condition: "C1=3 and Q5-3!=99", // 排他の選択肢は99とする。
		questions: [
			{
				afterText: "[[[C1回答]]]",
				quename: `${elementEvaluationSAQuename}-3`,
				quetitle: "その中で、あなたが最も印象に残ったところをお選びください。（ひとつだけ）",
				quelabel: "印象要素SA（競合品）",
				type: "S",
				choice_group: [
					{
						id: 1,
						choices: [{ value: 1, text: "未設定" }],
					},
				],
			},
		],
	},
	// コンセプト4品目
	{
		display_condition: "C1=4",
		questions: [
			{
				afterText: "[[[C1回答]]]",
				quename: `${elementEvaluationMAQuename}-4`,
				quetitle:
					"この説明文をご覧になって、あなたが<u>印象に残ったところ</u>をすべてお選びください。（あてはまるものすべて）",
				quelabel: "印象要素MA（現行品）",
				type: "M",
				choice_group: [
					{
						id: 1,
						choices: [{ value: 1, text: "未設定" }],
					},
					{
						id: 2,
						choices: [{ value: 99, text: "ひとつもない", exclusive: true }],
					},
				],
			},
		],
	},
	{
		display_condition: "C1=4 and Q5-4!=99", // 排他の選択肢は99とする。
		questions: [
			{
				afterText: "[[[C1回答]]]",
				quename: `${elementEvaluationSAQuename}-4`,
				quetitle: "その中で、あなたが最も印象に残ったところをお選びください。（ひとつだけ）",
				quelabel: "印象要素SA（現行品）",
				type: "S",
				choice_group: [
					{
						id: 1,
						choices: [{ value: 1, text: "未設定" }],
					},
				],
			},
		],
	},
	// 設問を埋め込むとしたらここになる。
	// E7の前に埋め込む処理とする。
	{
		questions: [
			{
				quename: lastFaceQuename,
				quetitle: "最後に、あなたご自身のことについて伺います。",
				type: "E",
			},
			{
				quename: "Q7",
				type: "S",
				quelabel: "年間化粧品支出額",
				quetitle: "あなたは、<b>化粧品</b>の購入に<u>年間</u>どれくらいお金を使っていますか。",
				choice_group: [
					{
						id: 1,
						choices: [
							{ value: 1, text: "0円" },
							{ value: 2, text: "1円～5,000円未満" },
							{ value: 3, text: "5,000円～10,000円未満" },
							{ value: 4, text: "10,000円～20,000円未満" },
							{ value: 5, text: "20,000円～30,000円未満" },
							{ value: 6, text: "30,000円～40,000円未満" },
							{ value: 7, text: "40,000円～50,000円未満" },
							{ value: 8, text: "50,000円～60,000円未満" },
							{ value: 9, text: "60,000円～70,000円未満" },
							{ value: 10, text: "70,000円～80,000円未満" },
							{ value: 11, text: "80,000円～90,000円未満" },
							{ value: 12, text: "90,000円～100,000円未満" },
							{ value: 13, text: "100,000円以上" },
						],
					},
				],
			},
		],
	},
	{
		// display_conditionはアンケート作成時に作る。
		questions: [
			{
				quename: sourceOfInfoQuename,
				quetitle:
					"あなたは「〇〇〇（対象カテゴリ）」について、普段どのような情報源を参考にしていますか。（あてはまるものすべて）",
				quelabel: "情報源",
				type: "M",
				choice_group: [
					{
						id: 1,
						choices: [
							{ value: 1, text: "ドラッグストアやバラエティストアの店頭" },
							{ value: 2, text: "化粧品専門店の店頭" },
							{ value: 3, text: "百貨店の店頭" },
							{ value: 4, text: "お店の美容部員" },
							{ value: 5, text: "お店のテスター" },
							{ value: 6, text: "お店のPOP" },
							{ value: 7, text: "無料サンプル" },
							{ value: 8, text: "有料お試しサンプル" },
							{ value: 9, text: "美容師" },
							{ value: 10, text: "皮膚科医" },
							{ value: 11, text: "薬剤師" },
							{ value: 12, text: "テレビのコマーシャル" },
							{ value: 13, text: "テレビ番組の中での商品紹介、特集" },
							{ value: 14, text: "雑誌の広告、記事、商品紹介、特集" },
							{ value: 15, text: "新聞広告、記事、商品紹介、特集" },
							{ value: 16, text: "屋外広告（壁面広告、ビルの看板、街頭ビジョンなど）" },
							{ value: 17, text: "交通広告（電車・バス・タクシー・飛行機などにある広告）" },
							{ value: 18, text: "折込チラシ" },
							{ value: 19, text: "カタログ・通販冊子" },
							{ value: 20, text: "メンバーズ向け冊子（クレジットカードや不動産、デパートなど）" },
							{ value: 21, text: "ダイレクトメール" },
							{ value: 22, text: "店内・屋外でのイベント" },
							{ value: 23, text: "友人・知人・家族との会話（メール・ブログを含む）" },
							{ value: 24, text: "ネットの広告" },
							{ value: 25, text: "メールマガジン・eメール" },
							{ value: 26, text: "メーカーやブランドのホームページ" },
							{ value: 27, text: "メーカーやブランドのSNS公式アカウント" },
							{ value: 28, text: "＠コスメなどの化粧品専門クチコミサイト" },
							{ value: 29, text: "キュレーションサイト・まとめサイト（NAVERまとめ、アンテナ、グノシーなど）" },
							{ value: 30, text: "動画系サービス（YouTube、C CHANNELなど）" },
							{ value: 31, text: "化粧品専門家・ユーチューバー・ブロガー・タレントなどのブログ記事やサイト" },
							{ value: 32, text: "一般人のブログ記事やサイト" },
							{ value: 33, text: "SNS（Facebook、Twitter、mixi、LINE、Instagramなど）" },
							{ value: 34, text: "その他" },
							{ value: 35, text: "あてはまるものはない", exclusive: true },
						],
					},
				],
			},
		],
	},
	{
		questions: [
			{
				quename: "Q9",
				quelabel: "居住地",
				quetitle: "あなたがお住まいの地域をお知らせください。",
				type: "S",
				selectbox: true,
				choice_group: [
					{
						id: 1,
						header: "都道府県",
						choices: [
							{ value: 1, text: "北海道" },
							{ value: 2, text: "青森県" },
							{ value: 3, text: "岩手県" },
							{ value: 4, text: "宮城県" },
							{ value: 5, text: "秋田県" },
							{ value: 6, text: "山形県" },
							{ value: 7, text: "福島県" },
							{ value: 8, text: "茨城県" },
							{ value: 9, text: "栃木県" },
							{ value: 10, text: "群馬県" },
							{ value: 11, text: "埼玉県" },
							{ value: 12, text: "千葉県" },
							{ value: 13, text: "東京都" },
							{ value: 14, text: "神奈川県" },
							{ value: 15, text: "新潟県" },
							{ value: 16, text: "富山県" },
							{ value: 17, text: "石川県" },
							{ value: 18, text: "福井県" },
							{ value: 19, text: "山梨県" },
							{ value: 20, text: "長野県" },
							{ value: 21, text: "岐阜県" },
							{ value: 22, text: "静岡県" },
							{ value: 23, text: "愛知県" },
							{ value: 24, text: "三重県" },
							{ value: 25, text: "滋賀県" },
							{ value: 26, text: "京都府" },
							{ value: 27, text: "大阪府" },
							{ value: 28, text: "兵庫県" },
							{ value: 29, text: "奈良県" },
							{ value: 30, text: "和歌山県" },
							{ value: 31, text: "鳥取県" },
							{ value: 32, text: "島根県" },
							{ value: 33, text: "岡山県" },
							{ value: 34, text: "広島県" },
							{ value: 35, text: "山口県" },
							{ value: 36, text: "徳島県" },
							{ value: 37, text: "香川県" },
							{ value: 38, text: "愛媛県" },
							{ value: 39, text: "高知県" },
							{ value: 40, text: "福岡県" },
							{ value: 41, text: "佐賀県" },
							{ value: 42, text: "長崎県" },
							{ value: 43, text: "熊本県" },
							{ value: 44, text: "大分県" },
							{ value: 45, text: "宮崎県" },
							{ value: 46, text: "鹿児島県" },
							{ value: 47, text: "沖縄県" },
						],
					},
				],
			},
			{
				quename: "Q10",
				quelabel: "個人年収",
				quetitle: "あなたご自身の年収（税込み。ボーナス、臨時収入など含む）をお知らせください。",
				type: "S",
				choice_group: [
					{
						id: 1,
						choices: [
							{ value: 1, text: "300万円未満" },
							{ value: 2, text: "300万円～400万円未満" },
							{ value: 3, text: "400万円～500万円未満" },
							{ value: 4, text: "500万円～600万円未満" },
							{ value: 5, text: "600万円～800万円未満" },
							{ value: 6, text: "800万円～1,000万円未満" },
							{ value: 7, text: "1,000万円～1,500万円未満" },
							{ value: 8, text: "1,500万円以上" },
							{ value: 9, text: "分からない／答えたくない" },
						],
					},
				],
			},
			{
				quename: "Q11",
				quelabel: "世帯年収",
				quetitle:
					"あなた自身の収入を含む、ご家族での世帯年収（税込み。ボーナス、臨時収入など含む）をお知らせください。",
				type: "S",
				choice_group: [
					{
						id: 1,
						choices: [
							{ value: 1, text: "300万円未満" },
							{ value: 2, text: "300万円～400万円未満" },
							{ value: 3, text: "400万円～500万円未満" },
							{ value: 4, text: "500万円～600万円未満" },
							{ value: 5, text: "600万円～800万円未満" },
							{ value: 6, text: "800万円～1,000万円未満" },
							{ value: 7, text: "1,000万円～1,500万円未満" },
							{ value: 8, text: "1,500万円以上" },
							{ value: 9, text: "分からない／答えたくない" },
						],
					},
				],
			},
		],
	},
];

const completeMessage = `最後までご回答頂きありがとうございます。
調査ID：「LK●●●（●には数字が入ります）」アンケート名：「日用品に関するアンケート」は、一つ回答を頂くと同一名称のアンケートは「回答済みです」と表示がされ回答ができなくなるものがございます。
こちらは、不具合ではなく本アンケートの仕様によるものでございます。
お客様にはご迷惑をおかけいたしますが、ご了承を頂けますと幸いです。`;

export const baseEnquete: Enquete = {
	enqueteId: "",
	title: "日用品に関するアンケート",
	use_monitas_delivery_system: true,
	// ポイント設定は決まったら変更する。
	point: {
		max_point: 20,
		min_point: 1,
	},
	language: Language.japanese,
	status: EnqueteStatus.debug, // デバッグモードで作成。
	terminate_page: {
		fin1: {
			body: "ご協力いただきありがとうございます。",
			status: "SC",
			redirect: "",
		},
		complete: {
			body: completeMessage,
			status: "COMP",
			redirect: "",
		},
	},
	questionnaire: [],
	max_answers: maxAnswered,
};
