import * as React from "react";
import { GetKeyMeasurementsResponse } from "../../../../../../server/types/request/report/key-mesurements";
import { CrossTable } from "../../../parts/cross-table";
import { ReportTitle } from "../parts/report-title";
import { GraphWrapArea } from "../common";

export type KeyMeasurementsReportPageProps = GetKeyMeasurementsResponse;
export const KeyMeasurementsReportPage = React.memo((props: KeyMeasurementsReportPageProps) => {
	const { data, job } = props;
	const filename = React.useMemo(() => (job ? `${job.jobNum}-keyMeasurements` : undefined), [job]);
	return (
		<>
			<GraphWrapArea>
				<ReportTitle>集計表</ReportTitle>
				<CrossTable filename={filename} data={data} headers={[]} />
			</GraphWrapArea>
		</>
	);
});
