export type Norm = "A" | "B" | "C" | "D";
import moment from "moment";
import { OneNumberScoreNorm } from "../../../server/models/graph/demand-forecast";
import { Judge, SummaryResult } from "../components/pages/report/exective-report/action-standard/action-standard-table";

export const judgeNorm = (score: number, oneNumberScoreNorm: OneNumberScoreNorm): Norm => {
	const { rankB, rankC, rankD } = oneNumberScoreNorm;
	if (score <= rankD) return "D";
	if (score <= rankD + rankC) return "C";
	if (score <= rankD + rankC + rankB) return "B";
	return "A";
};

export const judgeOneNumberScore = (baseScore: number, comparisonScore: number, sampleSize: number): SummaryResult => {
	const judge: Judge = baseScore > comparisonScore ? "〇" : "×";
	return { judge, sampleSize };
};

export const notJudgeSampleSize = 30;
export const oldJudgeDate = "2021-01-08";
export const isLack = (sampleSize: number): boolean => {
	return sampleSize < notJudgeSampleSize;
};
/**
 * 戦略ターゲットを判定をするかどうか。上から判定
 * 1. サンプルが30以上 ・・・　判定
 * 2. サンプルが30未満かつ2021-01-08以前　・・・　判定
 * 3. それ以外　・・・　判定しない
 * @param {number} sampleSize サンプルサイズ
 * @param {string} findate 終了日
 */
export const doJudge = (sampleSize: number, findate: string): boolean => {
	if (!isLack(sampleSize)) return true;
	if (moment(oldJudgeDate).isAfter(findate)) return true;
	return false;
};

export type AnalyticseVersion = "v1" | "v2";
/**
 * 判定バージョン
 * @param {string} enddatetime
 * @returns {AnalyticseVersion}
 */
export const getAnalyticsVersion = (enddatetime: string): AnalyticseVersion => {
	if (moment(enddatetime).isBefore(moment("2022-01-11"))) {
		console.log("v1");
		return "v1";
	}
	console.log("v2");
	return "v2";
};

/**
 * 終了日からノーム判定の基準を取得する
 * @param {string} enddatetime
 * @returns {Norm}
 */
export const normJudgeStandard = (enddatetime: string): Norm => {
	switch (getAnalyticsVersion(enddatetime)) {
		case "v2":
			return "B";
		default:
			return "C";
	}
};

/**
 * Norm判定の結果（true=〇,false=×）
 * @param {Norm} norm ノーム（A,B,C,D）
 * @param {string} enddatetime 調査終了日時
 * @returns {boolean} 判定結果
 */
export const normJudgeRank = (norm: Norm, enddatetime: string): boolean => {
	const baseNorm = normJudgeStandard(enddatetime);
	const norms: Norm[] = ["A", "B", "C", "D"];
	return norms.indexOf(norm) <= norms.indexOf(baseNorm);
};
