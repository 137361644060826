import * as React from "react";
import { reducer, initState } from "../../../reducers/concept";
import { useParams, useHistory, Prompt } from "react-router";
import { ConceptPage, ConceptPageProps } from "../../../components/pages/concept/create";
import { get, makeError, postFormData, post, remove } from "../../../lib/request";
import { GetConceptResponse, UpsertConceptResponse } from "../../../../../server/types/request/concept";
import { endpoint, replaceEndpointUrl } from "../../../../../server/router/endpoint";
import { Loading, ErrorObject } from "lu-component";
import { ConceptType, Concept, Sku } from "../../../../../server/models/concept";
import { UploadImageResponse } from "../../../../../server/types/request/image";
import { AxiosResponse } from "axios";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { clientEndpoint } from "../../../routes/endpoint";
import { replaceBreakAndSpace } from "../../../../../server/lib/common";

export const conceptSentensError = (concepts: Concept[]) => {
	const errors: ErrorObject = {};
	concepts.forEach((concept, index) => {
		if (!concept.sentences[0].sentence) {
			errors[index] = { sentences: "「タイトル」は入力必須です。" };
		}
	});
	if (Object.keys(errors).length > 0) {
		return { concepts: errors };
	}
	return undefined;
};

export const ConceptContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { info, loading, ...conceptState } = state;
	const { jobId, activityId } = useParams<{ jobId: string; activityId: string }>();
	const history = useHistory();
	// request群
	React.useEffect(() => {
		get<GetConceptResponse>(`${endpoint.concept}/${jobId}`)
			.then((response) => {
				dispatch({ type: "initConcepts", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	const onChangeFile = React.useCallback((type: keyof typeof ConceptType, name: "logo" | "package", file: File) => {
		// byteなので1mbまでを許容
		if (file.size > 1 * 1000 * 1000) {
			return dispatch({
				type: "changeMessageInfo",
				payload: { isSuccess: false, message: "アップロードするファイルのサイズは1MBまでです。" },
			});
		}
		const formData = new FormData();
		formData.append("type", type);
		formData.append("name", name);
		formData.append("file", file);
		dispatch({ type: "changeLoading", payload: true });
		postFormData<UploadImageResponse>(`${endpoint.conceptImage}/${jobId}`, formData)
			.then((response) => {
				const image = new Image();
				const url = `${response.data.url}?a=${Math.random()}`;
				image.src = url;
				image.onload = () => {
					let width = 150;
					const widthPercent = Math.floor(image.width / 150) || 1;
					const height = Math.floor(image.height / widthPercent);
					if (height > 250) {
						width = Math.floor(image.width / Math.ceil(image.height / 250));
					}
					dispatch({
						type: "changeConceptFile",
						payload: { type, name, value: { url, width } },
					});
				};
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	const onResized = React.useCallback((type: keyof typeof ConceptType, name: "logo" | "package", width: number) => {
		dispatch({ type: "changeConceptFileWidth", payload: { type, name, width: Math.floor(width) } });
	}, []);
	const onDeleteFile = React.useCallback((type: keyof typeof ConceptType, name: "logo" | "package") => {
		dispatch({ type: "changeLoading", payload: true });
		remove<{}>(`${endpoint.conceptImage}/${jobId}/${type}/${name}`)
			.then(() => {
				dispatch({
					type: "changeConceptFile",
					payload: { type, name, value: name === "logo" ? { url: undefined, width: undefined } : undefined },
				});
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	const onAddSku = React.useCallback((type: keyof typeof ConceptType, index: number) => {
		dispatch({ type: "addSku", payload: { type, index } });
	}, []);
	const onDeleteSku = React.useCallback((type: keyof typeof ConceptType, index: number) => {
		dispatch({ type: "deleteSku", payload: { type, index } });
	}, []);
	const onChangeSku = React.useCallback(
		(type: keyof typeof ConceptType, index: number, name: keyof Sku, value: Sku[keyof Sku]) => {
			dispatch({ type: "changeSku", payload: { type, index, name, value } });
		},
		[]
	);
	const onChangeConcept = React.useCallback(
		(type: keyof typeof ConceptType, name: keyof Concept, value: Concept[keyof Concept]) => {
			dispatch({ type: "changeConcept", payload: { type, name, value } });
		},
		[]
	);
	// TODO: サーバへリクエストするが仮置き
	const setTaxIncluded = React.useCallback(
		(type: keyof typeof ConceptType, index: number) => {
			const target = conceptState.concepts.find((c) => c.type === type);
			if (!target) return;
			const sku = target.sku[index];
			if (!sku) return;
			dispatch({
				type: "changeSku",
				payload: { type, index, name: "includeTaxPrice", value: Math.floor((sku.price * 110) / 100) },
			});
		},
		[conceptState.concepts]
	);
	const upsertRequestTemporary = React.useCallback(
		(cb: (response: AxiosResponse<UpsertConceptResponse>) => void) => {
			dispatch({ type: "changeLoading", payload: true });
			post<UpsertConceptResponse>(`${endpoint.concept}/${jobId}?temporary=true`, {
				concepts: conceptState.concepts.map((concept) => {
					return {
						...concept,
						sentences: concept.sentences.map((sentence) => {
							const tmp = replaceBreakAndSpace(sentence.sentence);
							if (tmp.length > 0) return sentence;
							return { ...sentence, sentence: "" };
						}),
					};
				}),
			})
				.then((response) => {
					dispatch({ type: "updateConcepts", payload: response.data });
					cb(response);
				})
				.catch((error) => dispatch({ type: "changeMessageInfo", payload: makeError(error) }));
		},
		[conceptState]
	);
	const onSubmit = React.useCallback(() => {
		dispatch({ type: "changeLoading", payload: true });
		post<UpsertConceptResponse>(`${endpoint.concept}/${jobId}`, {
			concepts: conceptState.concepts.map((concept) => {
				return {
					...concept,
					sentences: concept.sentences.map((sentence) => {
						const tmp = replaceBreakAndSpace(sentence.sentence);
						if (tmp.length > 0) return sentence;
						return { ...sentence, sentence: "" };
					}),
				};
			}),
		})
			.then((response) => {
				dispatch({ type: "updateConcepts", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [conceptState]);

	const onTemporarySave = React.useCallback(() => {
		upsertRequestTemporary((response) => dispatch({ type: "temporarySaveConcepts", payload: response.data }));
	}, [upsertRequestTemporary, conceptState]);
	const previewPage = React.useCallback(
		() => history.push(replaceEndpointUrl(clientEndpoint.conceptPreview, { activityId, jobId })),
		[]
	);
	const onPreview = React.useCallback(() => {
		if (conceptState.cantEditMessage) {
			previewPage();
		} else {
			upsertRequestTemporary(previewPage);
		}
	}, [upsertRequestTemporary, previewPage, conceptState.cantEditMessage]);
	const onClose = React.useCallback(() => history.push(replaceEndpointUrl(clientEndpoint.job, { activityId })), []);
	const onCopy = React.useCallback<ConceptPageProps["onCopy"]>(
		(original, copyTo) => dispatch({ type: "onCopy", payload: { original, copyTo } }),
		[]
	);
	const onRetestCopy = React.useCallback<ConceptPageProps["onRetestCopy"]>(
		(original, copyTo) => dispatch({ type: "onRetestCopy", payload: { original, copyTo } }),
		[]
	);
	return (
		<>
			<Prompt message={"変更が保存されていません。ページを移動してよろしいですか？"} when={state.prompt} />
			<Loading loading={loading} />
			<ConfirmBox info={info} onClose={onClose} titleLabel={"保存"} />
			<ConceptPage
				{...conceptState}
				errors={info.errors}
				onSubmit={onSubmit}
				onTemporarySave={onTemporarySave}
				onAddSku={onAddSku}
				onDeleteSku={onDeleteSku}
				onChangeConcept={onChangeConcept}
				onChangeSku={onChangeSku}
				onChangeFile={onChangeFile}
				onResized={onResized}
				onDeleteFile={onDeleteFile}
				setTaxIncluded={setTaxIncluded}
				onPreview={onPreview}
				onCopy={onCopy}
				onRetestCopy={onRetestCopy}
			/>
		</>
	);
};
