import * as React from "react";
import { CrossData } from "muscat-aggregate-library";
import styled from "styled-components";
import { CrossRow } from "./row";

const CrossTable = styled.table`
	margin: 0px auto;
	td,
	th {
		border: 1px solid #ddd;
		padding: 1px;
	}
	border-collapse: collapse;
	th div {
		text-align: center;
	}
	tr.title-area th {
		border: 0px;
		padding: 10px;
		white-space: pre-wrap;
	}
	td.value-area,
	td.none-value {
		font-size: 0.9rem;
		text-align: right;
	}
	td.label-area {
		max-width: 400px;
	}
	tr.bottom-axis-header {
		th {
			vertical-align: top;
			text-align: center;
			div {
				-webkit-writing-mode: vertical-rl;
				-ms-writing-mode: tb-rl;
				writing-mode: vertical-rl;
				word-break: keep-all;
				text-align: left;
				margin: 0px auto;
				display: inline;
			}
		}
	}
	.color-red {
		color: red;
	}
`;

export type CrossProps = CrossData;

export const VerticalCross = React.memo((props: CrossProps) => {
	const { label, data } = props;
	return (
		<CrossTable>
			<tbody>
				{data.map((d) => (
					<CrossRow headerLabel={label} key={`data-${d.quename}`} {...d} />
				))}
			</tbody>
		</CrossTable>
	);
});
