import { JobDownloadAction } from "../../../types/actions";
import { MessageInfo } from "../../../types/info";
import { BetweenDateValues } from "lu-component";

export type State = {
	loading: boolean;
	info: MessageInfo;
	filteredValue: BetweenDateValues;
};
export const initState: State = {
	filteredValue: { from: undefined, to: undefined },
	loading: false,
	info: {
		message: "",
		isSuccess: true,
	},
};

export const reducer = (state: State, action: JobDownloadAction): State => {
	switch (action.type) {
		case "changeFiltered":
			return {
				...state,
				filteredValue: action.payload,
			};
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
		default:
			return state;
	}
};
