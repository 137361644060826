import * as React from "react";
import { OneNumberScoreNorm, OneNumberScoreData } from "../../../../../../../server/models/graph/demand-forecast";
import { ReportTitle } from "../../parts/report-title";
import { Norm } from "../../../../parts/graph/norm";
import { ConceptType } from "../../../../../../../server/models/concept";
import styled from "styled-components";
import { Job } from "../../../../../../../server/models/job";

const OneNumberScoreExplain = styled.div`
	position: relative;
`;
const ExplainArea = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	width: 230px;
	font-size: 0.8em;
`;
const ExplainTitleArea = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 230px;
	font-weight: bold;
	font-size: 1.2em;
`;
const SampleSizeArea = styled.div`
	text-align: center;
`;
export type OneNumberScoreProps = {
	oneNumberScores: OneNumberScoreData[];
	oneNumberScoreNorm: OneNumberScoreNorm;
	job: Job;
};
export const OneNumberScore = React.memo((props: OneNumberScoreProps) => {
	const { oneNumberScores, oneNumberScoreNorm, job } = props;
	const data = React.useMemo(() => {
		return oneNumberScores.filter(
			(df) => (df.conceptType === "test1" || df.conceptType === "test2") && df.target === "category"
		);
	}, [oneNumberScores]);
	return (
		<>
			<OneNumberScoreExplain>
				<ReportTitle>購入意向　ワンナンバースコア</ReportTitle>
				<ExplainArea>
					*ワンナンバースコアは、
					<br />
					購入意向のTop Box値に0.75、
					<br />
					2nd Box値に0.25の係数を掛けて合算した数値
				</ExplainArea>
			</OneNumberScoreExplain>
			<OneNumberScoreExplain>
				<ExplainTitleArea>ノーム判定</ExplainTitleArea>
				<SampleSizeArea>カテゴリーターゲットGベース（200s）</SampleSizeArea>
			</OneNumberScoreExplain>
			{data.map((d) => (
				<Norm
					key={`one-number-score-${d.conceptType}`}
					oneNumberScoreNorm={oneNumberScoreNorm}
					value={d.score}
					label={ConceptType[d.conceptType]}
					job={job}
				/>
			))}
		</>
	);
});
