import * as React from "react";
import { reducer, initState } from "../../../reducers/job/question";
import { JobQuestionPage } from "../../../components/pages/job/question";
import { Loading } from "lu-component";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { SimpleQuestion, Choice } from "muscat-library";
import { get, makeError, post, remove } from "../../../lib/request";
import { endpoint } from "../../../../../server/router/endpoint";
import { useParams, useHistory, Prompt } from "react-router";
import {
	GetQuestionResponse,
	UpsertQuestionResponse,
	DeleteQuestionResponse,
} from "../../../../../server/types/request/question";
import { SwitchConfirm, SwitchConfirmEvent } from "../../../components/parts/switch-confirm";

export const JobQuestionContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, question } = state;
	const { jobId, activityId } = useParams<{ jobId: string; activityId: string }>();
	const history = useHistory();
	React.useEffect(() => {
		get<GetQuestionResponse>(`${endpoint.question}/${jobId}`)
			.then((response) => {
				dispatch({ type: "loadQuestion", payload: response.data.question });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	const onSubmit = React.useCallback(() => {
		post<UpsertQuestionResponse>(`${endpoint.question}/${jobId}`, { question })
			.then((response) => {
				dispatch({ type: "updateQuestion", payload: response.data.question });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [question]);
	const onDelete = React.useCallback(() => {
		remove<DeleteQuestionResponse>(`${endpoint.question}/${jobId}`)
			.then((response) => {
				dispatch({ type: "deleteQuestion", payload: response.data.question });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [question]);
	const onChangeQuestion = React.useCallback(
		(name: keyof SimpleQuestion, value: SimpleQuestion[keyof SimpleQuestion]) => {
			dispatch({ type: "changeQuestion", payload: { name, value } });
		},
		[]
	);
	const onAddChoice = React.useCallback((index: number) => dispatch({ type: "addChoice", payload: index }), []);
	const onDeleteChoice = React.useCallback((index: number) => dispatch({ type: "deleteChoice", payload: index }), []);
	const onChangeChoice = React.useCallback(
		(index: number, name: keyof Choice, value: Choice[keyof Choice]) =>
			dispatch({ type: "changeChoice", payload: { index, name, value } }),
		[]
	);
	const onJobPage = React.useCallback(() => history.push(`/activity/${activityId}/job`), []);
	const events = React.useMemo<SwitchConfirmEvent[]>(() => {
		return [
			{
				label: "ジョブ一覧に戻る",
				variant: "outline-secondary",
				func: onJobPage,
			},
		];
	}, [onJobPage]);
	return (
		<>
			<Prompt message={"変更が保存されていません。ページを移動してよろしいですか？"} when={state.prompt} />
			<Loading loading={loading} />
			{!info.isSuccess && <ConfirmBox info={info} />}
			<SwitchConfirm show={info.isSuccess && !!info.message} title={"設問の設定"} body={info.message} events={events} />
			<JobQuestionPage
				errors={
					info.errors && info.errors["question"] && typeof info.errors["question"] === "object"
						? info.errors["question"]
						: undefined
				}
				onChangeChoice={onChangeChoice}
				onChange={onChangeQuestion}
				onAddChoice={onAddChoice}
				onDeleteChoice={onDeleteChoice}
				question={question}
				onSubmit={onSubmit}
				onDelete={onDelete}
			/>
		</>
	);
};
