import * as React from "react";
import { targetGroup } from "../../../../../../server/models/graph/target-group";
import { TabViewer, TabSetting } from "lu-component";
import { EvaluationGraph } from "../evaluation-report/evaluation-graph";
import { GetResultByTargetResponse } from "../../../../../../server/types/request/report/result-by-target";
import { SKUGraphs } from "../sku-report/sku-graphs";
import { ConceptEvaluationGraphs } from "../concept-evaluation-report/concept-evaluation-graphs";
// import { CrossTabulationTable } from "../../../parts/graph/cross-tabulation-table";
import styled from "styled-components";
import { CrossTable } from "../../../parts/cross-table";
import { ReportTitle } from "../parts/report-title";

const GraphWrapArea = styled.div`
	max-width: 1200px;
	margin: 0px auto;
`;
const CrossTableArea = styled.div`
	table td {
		max-width: 200px;
	}
`;

export type ResultByTargetReportPageProps = GetResultByTargetResponse;

export const ResultByTargeReporttPage = React.memo((props: ResultByTargetReportPageProps) => {
	const { data, job } = props;
	const tabs = React.useMemo<TabSetting[]>(() => {
		const { jobNum } = job;
		const tmp: TabSetting[] = [];
		if (data.category) {
			const { elementEvaluationData, conceptEvaluationData, purchaseIntentProfileData, sampleSize } = data.category;
			tmp.push({
				key: "category",
				title: targetGroup.category,
				children: (
					<>
						{/*skuData && <SKUGraphs data={skuData} />*/}
						{elementEvaluationData && (
							<EvaluationGraph
								data={elementEvaluationData}
								sampleSize={sampleSize}
								filename={`${jobNum}-${targetGroup.category}-印象評価`}
							/>
						)}
						{conceptEvaluationData && (
							<ConceptEvaluationGraphs
								data={conceptEvaluationData}
								filename={`${jobNum}-${targetGroup.category}-印象に残った点`}
							/>
						)}
						{purchaseIntentProfileData && (
							<CrossTableArea>
								<ReportTitle>購入意向者のプロフィール</ReportTitle>
								<CrossTable
									filename={`${jobNum}-${targetGroup.category}-購入意向者のプロフィール`}
									data={purchaseIntentProfileData}
									headers={[]}
								/>
							</CrossTableArea>
						)}
					</>
				),
			});
		}
		if (data.strategy) {
			const { elementEvaluationData, conceptEvaluationData, purchaseIntentProfileData, sampleSize } = data.strategy;
			tmp.push({
				key: "strategy",
				title: targetGroup.strategy,
				children: (
					<>
						{/*skuData && <SKUGraphs data={skuData} />*/}
						{elementEvaluationData && (
							<EvaluationGraph
								data={elementEvaluationData}
								sampleSize={sampleSize}
								filename={`${jobNum}-${targetGroup.strategy}-印象評価`}
							/>
						)}
						{conceptEvaluationData && (
							<ConceptEvaluationGraphs
								data={conceptEvaluationData}
								filename={`${jobNum}-${targetGroup.strategy}-印象に残った点`}
							/>
						)}
						{purchaseIntentProfileData && (
							<CrossTableArea>
								<ReportTitle>購入意向者のプロフィール</ReportTitle>
								<CrossTable
									filename={`${jobNum}-${targetGroup.strategy}-購入意向者のプロフィール`}
									data={purchaseIntentProfileData}
									headers={[]}
								/>
							</CrossTableArea>
						)}
					</>
				),
			});
		}
		if (data.general) {
			const {
				skuData,
				elementEvaluationData,
				conceptEvaluationData,
				purchaseIntentProfileData,
				sampleSize,
			} = data.general;
			tmp.push({
				key: "general",
				title: targetGroup.general,
				children: (
					<>
						{skuData && <SKUGraphs data={skuData} />}
						{elementEvaluationData && (
							<EvaluationGraph
								data={elementEvaluationData}
								sampleSize={sampleSize}
								filename={`${jobNum}-${targetGroup.general}-印象評価`}
							/>
						)}
						{conceptEvaluationData && (
							<ConceptEvaluationGraphs
								data={conceptEvaluationData}
								filename={`${jobNum}-${targetGroup.general}-印象に残った点`}
							/>
						)}
						{purchaseIntentProfileData && (
							<CrossTableArea>
								<ReportTitle>購入意向者のプロフィール</ReportTitle>
								<CrossTable
									filename={`${jobNum}-${targetGroup.general}-購入意向者のプロフィール`}
									data={purchaseIntentProfileData}
									headers={[]}
								/>
							</CrossTableArea>
						)}
					</>
				),
			});
		}
		return tmp;
	}, [data, job]);
	return (
		<GraphWrapArea>
			<TabViewer id={"resutlByTarget"} tabs={tabs} />
		</GraphWrapArea>
	);
});
