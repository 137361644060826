import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import styled from "styled-components";

const EllipsisBtnWrapper = styled.div`
	position: relative;

	button {
		margin: 0;
	}

	.ellipsis-button__menu {
		background: white;
		border: 1px solid rgba(0, 0, 0, 0.15);
		border-radius: 0.25rem;
		display: none;
		min-width: 10rem;
		padding: 0.5rem 0;
		position: absolute;
		right: 0px;
		top: calc(1.5em + 0.75rem + 2px + 2px);
		z-index: 1;

		&--open {
			display: block;
		}
	}
`;

export const MenuItem = styled.div`
	color: #212529;
	padding: 0.25rem 1.5rem;
	white-space: nowrap;

	&:hover {
		cursor: default;
		background-color: #f8f9fa;
	}
`;

export const EllipsisButton: FunctionComponent<{}> = ({ children }) => {
	const [open, setOpen] = useState(false);

	const ref = useRef(null);
	const handler = useRef(null);

	useEffect(() => {
		handler.current = (e: Event) => {
			if (!ref.current.contains(e.target)) {
				setOpen(false);
				removeHandler();
			}
		};
	}, []);

	const toggleHandler = useCallback(() => {
		if (!open) {
			setOpen(true);
			document.addEventListener("click", handler.current);
		}
	}, [handler]);

	const removeHandler = useCallback(() => {
		document.removeEventListener("click", handler.current);
	}, [handler]);

	const closeHandler = useCallback(() => {
		console.log("onClose");
		setOpen(false);
		removeHandler();
	}, []);

	return (
		<EllipsisBtnWrapper>
			<Button variant="light" style={{ color: "#6c757d" }} onClick={toggleHandler}>
				<FontAwesomeIcon icon={faEllipsisV} />
			</Button>
			<div
				ref={ref}
				className={classNames("ellipsis-button__menu", { "ellipsis-button__menu--open": open })}
				onClick={closeHandler}
			>
				{children}
			</div>
		</EllipsisBtnWrapper>
	);
};
