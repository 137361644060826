import * as React from "react";
import { Concept, ConceptType } from "../../../../../../server/models/concept";
import { Col, Row, Button } from "react-bootstrap";
import { EditButton } from "../../../parts/edit-button";
import { Title } from "lu-component";

export type ConceptPreviewProps = {
	concepts: Concept[];
};
export const ConceptPreview = React.memo((props: ConceptPreviewProps) => {
	return (
		<>
			{props.concepts.map((concept, index) => (
				<Row key={`concept-${index}`}>
					<Col md={12}>
						<Title>{ConceptType[concept.type]}</Title>
					</Col>
					<Col key={concept.type} md={{ span: 8, offset: 2 }}>
						<img src={concept.conseptUrl} style={{ maxWidth: "500px" }} />
					</Col>
				</Row>
			))}
		</>
	);
});

export type ConceptPreviewPageProps = {
	concepts: Concept[];
	onEditPage: () => void;
};

export const ConceptPreviewPage = React.memo((props: ConceptPreviewPageProps) => {
	const { concepts, onEditPage } = props;
	return (
		<>
			<div className="m-3">
				<div className="h3">■コンセプトプレビュー</div>
			</div>
			{/*<EditButton onClick={onEditPage} />*/}
			<ConceptPreview concepts={concepts} />
			<Row style={{ marginTop: "30px" }}>
				<Col md={{ span: 8, offset: 2 }}>
					<Col md={{ span: 3, offset: 3 }}>
						<Button variant={"secondary"} block onClick={onEditPage}>
							編集ページに戻る
						</Button>
					</Col>
				</Col>
			</Row>
		</>
	);
});
