import * as React from "react";
import { reducer, initState } from "../../reducers/job/preview";
import { get, makeError } from "../../lib/request";
import { GetPreviewResponse } from "../../../../server/types/request/preview";
import { useParams } from "react-router";
import { endpoint } from "../../../../server/router/endpoint";
import { Loading } from "lu-component";
import { PreviewPage } from "../../components/pages/preview";
import { ConfirmBox } from "../../components/parts/confirm-box";

export const PreviewContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, ...previewPageProps } = state;
	const { jobId } = useParams<{ jobId: string }>();
	React.useEffect(() => {
		get<GetPreviewResponse>(`${endpoint.preview}/${jobId}`)
			.then((response) => {
				dispatch({ type: "loadPreview", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	return (
		<>
			<Loading loading={loading} />
			<ConfirmBox info={info} />
			<PreviewPage {...previewPageProps} />
		</>
	);
};
