import * as React from "react";
import { SimpleQuestion, Choice } from "muscat-library";
import { FormGroup, FormLabel, FormControl, Button, Row, Col } from "react-bootstrap";
import { SingleSelect } from "../../../parts/select";
import { ChoiceSetting } from "./choice-setting";
import styled from "styled-components";
import { ErrorObject } from "../../../../../../server/validation/validation-base";
import Feedback from "react-bootstrap/esm/Feedback";
import { Radios } from "lu-component";
import { getErrorMessage } from "../../../../lib/error";

const QuestionArea = styled.div`
	.form-group {
		margin-bottom: 1.5rem;
	}
`;

const typeOptions: { value: SimpleQuestion["type"]; label: string }[] = [
	{ value: "O", label: "自由記述" },
	{ value: "I", label: "数値回答" },
	{ value: "S", label: "単一回答" },
	{ value: "M", label: "複数回答" },
];

export type JobQuestionPageProps = {
	question: Partial<SimpleQuestion>;
	onChange: (name: keyof SimpleQuestion, value: SimpleQuestion[keyof SimpleQuestion]) => void;
	onAddChoice: (index: number) => void;
	onDeleteChoice: (index: number) => void;
	onChangeChoice: (index: number, name: keyof Choice, value: Choice[keyof Choice]) => void;
	errors?: ErrorObject;
	onSubmit: () => void;
	onDelete: () => void;
};

export const JobQuestionPage = React.memo((props: JobQuestionPageProps) => {
	const { question, onChange, onSubmit, onDelete, errors, ...choicesFunc } = props;
	const choiceErrors = React.useMemo(() => {
		if (!errors) return undefined;
		if (!errors["choice_group"] || typeof errors["choice_group"] !== "object") return undefined;
		if (!errors["choice_group"]["0"] || typeof errors["choice_group"]["0"] !== "object") return undefined;
		return errors["choice_group"]["0"]["choices"];
	}, [errors]);

	const [hasConcept, setHasConcept] = React.useState<boolean>(false);
	React.useEffect(() => {
		setHasConcept(!!question && !!question.afterText);
	}, [question]);
	const onChangeDisplay = React.useCallback(
		(checked: boolean) => onChange("afterText", checked ? "[[[C1回答]]]" : ""),
		[onChange]
	);
	return (
		<QuestionArea>
			<div className="m-3">
				<div className="h3">■ 追加設問</div>
			</div>
			<FormGroup>
				<FormLabel>コンセプト文の表示</FormLabel>
				<Radios
					name={"hasConcept"}
					choices={[
						{ value: true, label: "表示する" },
						{ value: false, label: "表示しない" },
					]}
					checked={hasConcept}
					onChange={onChangeDisplay}
				/>
			</FormGroup>
			<FormGroup>
				<FormLabel>質問文</FormLabel>
				<FormControl
					as={"textarea"}
					value={question.quetitle || ""}
					onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onChange("quetitle", e.target.value)}
					isInvalid={!!errors && !!errors["quetitle"]}
				/>
				{!!errors && !!errors["quetitle"] && (
					<Feedback type={"invalid"} style={{ display: "block" }}>
						{errors["quetitle"]}
					</Feedback>
				)}
			</FormGroup>
			<FormGroup>
				<FormLabel>設問タイプ</FormLabel>
				<SingleSelect
					options={typeOptions}
					value={question.type || ""}
					onChange={(v) => onChange("type", v as SimpleQuestion["type"])}
					error={getErrorMessage("type", errors)}
				/>
			</FormGroup>
			{(question.type === "S" || question.type === "M") && (
				<ChoiceSetting
					choices={question.choice_group[0].choices || []}
					type={question.type}
					errors={choiceErrors}
					{...choicesFunc}
				/>
			)}
			<Row style={{ marginTop: "20px" }}>
				<Col md={{ offset: 3, span: 2 }}>
					<Button variant={"secondary"} block onClick={onSubmit}>
						登録
					</Button>
				</Col>
				<Col md={2}>
					<Button variant={"danger"} block onClick={onDelete}>
						削除
					</Button>
				</Col>
			</Row>
		</QuestionArea>
	);
});
