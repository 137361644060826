import { ActivityCreateAction } from "../../../types/actions";
import { SimpleActivity, StrategyGroup, CategoryGroup, Activity } from "../../../../../server/models/activity";
import { MessageInfo } from "../../../types/info";
import { Brand } from "../../../../../server/models/brand";
import { AccountWithoutPassword } from "../../../../../server/models/account";
import { Category } from "../../../../../server/models/category";
import { isCover } from "../../../../../server/lib/common";

export type State = {
	activity: SimpleActivity;
	categoryTargetGroup?: CategoryGroup;
	strategyTargetGroup?: StrategyGroup;

	// 以下は絞り込みの可能性があるので、そのままのデータを取得。
	accounts: AccountWithoutPassword[];
	brands: Brand[];
	categories: Category[];
	cantEditMessage?: string;
	hasStrategyGroup: boolean;

	loading: boolean;
	info: MessageInfo;
	prompt: boolean;
};
export const initState: State = {
	accounts: [],
	brands: [],
	categories: [],
	activity: undefined,
	hasStrategyGroup: undefined,
	loading: true,
	info: {
		message: "",
		isSuccess: true,
	},
	prompt: false,
};

const makeCategoryGroup = (categoryTargetGroup: CategoryGroup, category: Category) => {
	const { age: categoryAge, price: categoryPrice } = categoryTargetGroup;
	if (!category || (!categoryAge.to && !categoryAge.from)) return categoryTargetGroup;
	let tmpAppearance = 0;
	// ageかtoが未定義の場合は仮に適当な最大値と最小値を入れておく。
	for (const { appearance, price, age } of category.priceAppearance) {
		// ageの判定
		if (!isCover(categoryAge, age)) continue;
		// priceの判定
		if (price) {
			if (!categoryPrice || !isCover(categoryPrice, price)) continue;
		}
		tmpAppearance += appearance * 100; // 小数点は丸めておく。
	}
	return {
		...categoryTargetGroup,
		appearance: tmpAppearance / 100,
	};
};
const changeHasStrategy = (state: State, hasStrategyGroup: boolean) => {
	if (!hasStrategyGroup) {
		delete state.strategyTargetGroup;
	} else {
		state.strategyTargetGroup = {
			age: { from: undefined, to: undefined },
			married: [],
			children: [],
			occupation: [],
			skinAttribute: [],
			sensitiveSkin: [],
			categoryOrAnd: "Or",
			category: [],
			channel: [],
		};
	}
	return { ...state, hasStrategyGroup };
};

const makeInitActivity = (initActivity?: Activity) => {
	if (!initActivity) return {};
	const { strategyTargetGroup, categoryTargetGroup, ...activity } = initActivity;
	return { activity, strategyTargetGroup, categoryTargetGroup, hasStrategyGroup: !!strategyTargetGroup };
};

const changeActivity = (
	state: State,
	name: keyof SimpleActivity,
	value: SimpleActivity[keyof SimpleActivity],
	brands: Brand[]
) => {
	const activity = { ...state.activity, [name]: value };
	// 認知率はブランドとカテゴリが出揃ったタイミングで取得。
	if ((name === "brandId" || name === "category") && activity.brandId && activity.category) {
		const { category, brandId } = activity;
		// カテゴリ x ブランドで見つけることができるものは認知率をセットする。
		const brand = brands.find((brand) => {
			return brand._id.toString() === brandId.toString();
		});
		// nBrandが0は他ブランド
		const filteredCategoryRecognition = category.categoryRecognition.filter(
			(cRecognition) => cRecognition.nBrand !== 0 && cRecognition.nBrand === brand.nBrand
		);
		if (filteredCategoryRecognition.length) {
			activity.goalRecognitionRate = Math.round(
				Math.max(...filteredCategoryRecognition.map((cRecognition) => cRecognition.gAwareness))
			);
		} else {
			activity.goalRecognitionRate = undefined;
		}
	}
	if (name === "category") {
		// カテゴリがなくなった、出現が3%未満は表示されない。
		if (!activity.category || activity.category.priceAppearance.reduce((a, b) => a + b.appearance, 0) < 3) {
			delete state.categoryTargetGroup;
		} else {
			// 設定画面が出てくるようになる。
			state.categoryTargetGroup = {
				price: undefined,
				age: { from: undefined, to: undefined },
				appearance: 0,
			};
		}
		if (
			state.strategyTargetGroup &&
			(!state.activity.category || activity.category.gender !== state.activity.category.gender)
		) {
			state.strategyTargetGroup = { ...state.strategyTargetGroup, category: [] };
		}
	}
	return { ...state, activity: activity };
};

const changeStrategy = (strategyGroup: StrategyGroup): StrategyGroup => {
	// チャネルはカテゴリのチェック状況に応じて変動するので、チェックが無くなったら削除する必要がある。
	if (strategyGroup.channel && strategyGroup.channel.length && strategyGroup.category.length === 0) {
		strategyGroup.channel = [];
	}
	// 制汗剤はチェック状況におうじて削除する必要がある。
	if (strategyGroup.fragrance && strategyGroup.category.every(({ category }) => category.type !== "antiperspirant")) {
		delete strategyGroup.fragrance;
	}
	// ファウンデーションタイプはチェック状況に応じて削除する必要がある。
	if (
		strategyGroup.foundationType &&
		strategyGroup.category.every(({ category }) => category.type !== "liquid-foundation")
	) {
		delete strategyGroup.foundationType;
	}
	return strategyGroup;
};

export const reducer = (state: State, action: ActivityCreateAction): State => {
	switch (action.type) {
		case "loadActivity":
			return {
				...state,
				...action.payload,
				loading: false,
				...makeInitActivity(action.payload.activity),
			};
		case "updateActivity":
			return {
				...state,
				info: {
					message: (state.activity._id ? "更新" : "作成") + "が完了しました。",
					isSuccess: true,
				},
				loading: false,
				prompt: false,
				...makeInitActivity(action.payload),
			};
		case "changeActivity":
			return {
				...changeActivity(state, action.payload.name, action.payload.value, state.brands),
				prompt: true,
				/*/
				strategyTargetGroup:
					action.payload.name === "category" &&
					(!state.activity.category || (action.payload.value as Category).gender !== state.activity.category.gender)
						? // genderが変わる場合はカテゴリは初期化しなければならない。
						  { ...state.strategyTargetGroup, category: [] }
						: state.strategyTargetGroup,
				/*/
			};
		case "changeCategoryGroup":
			return {
				...state,
				categoryTargetGroup: makeCategoryGroup(
					{ ...state.categoryTargetGroup, [action.payload.name]: action.payload.value },
					state.activity.category
				),
			};
		case "changeHasStrategy":
			return changeHasStrategy(state, action.payload);
		case "changeStrategyGroup":
			return {
				...state,
				strategyTargetGroup: changeStrategy({
					...state.strategyTargetGroup,
					[action.payload.name]: action.payload.value,
				}),
			};
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
		default:
			return state;
	}
};
