import { Question } from "muscat-library";
import * as React from "react";
import { AggregateConfig, AggregateConfigProps } from "./aggregate-config";
import { GroupSelections, GroupSelectionsProps } from "./group-selections";
import { SelectionGroup } from "muscat-aggregate-library";
import { VerticalConfig, VerticalConfigProps } from "./vertical-config";
import { AggregateProperty, AggregatePropertyProps } from "./aggregate-property";
import { ErrorObject } from "../../../../../server/validation/validation-base";
import { getErrorMessage, getErrorObject } from "../../../lib/error";
import { CrossAggregate } from "../../../../../server/models/cross-aggregate";
import { Option } from "../../../../../server/types/request";
import { Activity } from "../../../../../server/models/activity";

export type AggregateSettingProps = {
	activity: Activity;
	questions: Question[];
	selectionGroups: SelectionGroup[];
	axis: string[];
	quenames: string[];
	vertical?: boolean;
	crossAggregate: Partial<CrossAggregate>;
	errors?: ErrorObject;
	onChangeAggregate: AggregatePropertyProps["onChange"];
	onChangeQuenames: AggregateConfigProps["onChangeQuenames"];
	onChangeAxis: AggregateConfigProps["onChangeAxis"];
	onAddSelectionGroup: GroupSelectionsProps["onAddSelectionGroup"];
	onDeleteSelectionGroup: GroupSelectionsProps["onDeleteSelectionGroup"];
	onChangeSelectGroups: GroupSelectionsProps["onChange"];
	onChangeVertical: VerticalConfigProps["onChange"];

	targetGroupOptions: Option[];
	conceptOptions: Option[];
};
export const AggregateSetting = React.memo((props: AggregateSettingProps) => {
	const {
		crossAggregate,
		questions,
		selectionGroups,
		axis,
		quenames,
		vertical,
		onChangeQuenames,
		onChangeAxis,
		onAddSelectionGroup,
		onDeleteSelectionGroup,
		onChangeSelectGroups,
		onChangeVertical,
		onChangeAggregate,
		errors,
		conceptOptions,
		targetGroupOptions,
		activity,
	} = props;
	return (
		<>
			<AggregateProperty
				activity={activity}
				crossAggregate={crossAggregate}
				onChange={onChangeAggregate}
				conceptOptions={conceptOptions}
				targetGroupOptions={targetGroupOptions}
				errors={errors}
			/>
			<GroupSelections
				questions={questions}
				selectionGroups={selectionGroups}
				onAddSelectionGroup={onAddSelectionGroup}
				onDeleteSelectionGroup={onDeleteSelectionGroup}
				onChange={onChangeSelectGroups}
				errors={getErrorObject("selectionGroups", errors)}
			/>
			<AggregateConfig
				questions={questions}
				selectionGroups={selectionGroups}
				axis={axis}
				quenames={quenames}
				onChangeQuenames={onChangeQuenames}
				onChangeAxis={onChangeAxis}
				quenamesError={getErrorMessage("quenames", errors)}
				axisError={getErrorMessage("axis", errors)}
			/>
		</>
	);
});
