import * as React from "react";
import { AggregationLabel, CrossData } from "muscat-aggregate-library";

export type CrossHeaderProps = {
	labels: AggregationLabel[];
	isStart?: boolean;
};

const getDeps = (grouping?: CrossData["label"]["grouping"]): number => {
	if (!grouping || grouping.length === 0 || !grouping[0].child) return 1;
	return 1 + getDeps(grouping[0].child.grouping);
};

export const CrossHeader = React.memo((props: CrossHeaderProps) => {
	const { labels, isStart } = props;
	const deps = React.useMemo(() => getDeps(labels[0].grouping), [labels]);
	const childLabels = React.useMemo(() => {
		const tmp: AggregationLabel[] = [];
		for (const { grouping } of labels) {
			for (const { child } of grouping) {
				if (child) tmp.push(child);
			}
		}
		return tmp;
	}, [labels]);
	return (
		<>
			<tr className={`axis-header ${childLabels.length ? "" : "bottom-axis-header"}`}>
				{isStart && <th rowSpan={deps}></th>}
				{labels.map(({ childCount, quename, grouping }, index) => {
					return (
						<React.Fragment key={`header-${quename}-${index}`}>
							{grouping.map(({ label }, gIndex) => (
								<th key={`header-${quename}-${index}-${gIndex}`} colSpan={childCount / grouping.length}>
									<div>{label}</div>
								</th>
							))}
						</React.Fragment>
					);
				})}
			</tr>
			{childLabels.length > 0 && <CrossHeader labels={childLabels} />}
		</>
	);
});
