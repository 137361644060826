import * as React from "react";
import { reducer, initState } from "../../reducers/account";
import { get, makeError, post, put, fileDownload, postFormData } from "../../lib/request";
import {
	GetAccountResponse,
	UpsertAccountResponse,
	UploadAccountResponse,
} from "../../../../server/types/request/account";
import { endpoint } from "../../../../server/router/endpoint";
import { AccountWithoutPassword, Account } from "../../../../server/models/account";
import { AccountPage } from "../../components/pages/account";
import { InfoMessage, Loading } from "lu-component";
import { ConfirmBox } from "../../components/parts/confirm-box";

export const AccountContariner = (loginAccount: AccountWithoutPassword) => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { accounts, brandList, info, loading } = state;
	React.useEffect(() => {
		get<GetAccountResponse>(endpoint.account)
			.then((response) => {
				dispatch({ type: "initAccounts", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	const onCreate = React.useCallback((account: Account, cb?: () => void) => {
		post<UpsertAccountResponse>(endpoint.account, account)
			.then((response) => {
				dispatch({ type: "addAccount", payload: response.data.account });
				if (cb) cb();
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	const onUpdate = React.useCallback((account: AccountWithoutPassword & { password?: string }, cb?: () => void) => {
		put<UpsertAccountResponse>(`${endpoint.account}/${account._id}`, account)
			.then((response) => {
				dispatch({ type: "changeAccount", payload: response.data.account });
				if (cb) cb();
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	const onDownload = React.useCallback(() => {
		dispatch({ type: "changeLoading", payload: true });
		fileDownload(endpoint.accountDownload, `account-list.csv`)
			.then(() => {
				dispatch({ type: "changeLoading", payload: false });
			})
			.catch(() => {
				dispatch({
					type: "changeMessageInfo",
					payload: { message: "ダウンロードに失敗しました。", isSuccess: false },
				});
			});
	}, []);
	const onUpload = React.useCallback((file: File) => {
		const formData = new FormData();
		formData.append("accounts", file);
		dispatch({ type: "changeLoading", payload: true });
		postFormData<UploadAccountResponse>(endpoint.accountUpload, formData)
			.then((response) => {
				dispatch({
					type: "uploadAccounts",
					payload: response.data.accounts,
				});
			})
			.catch((error) => {
				dispatch({
					type: "changeMessageInfo",
					payload: makeError(error, true),
				});
			});
	}, []);
	const onClose = React.useCallback(() => {
		dispatch({
			type: "changeMessageInfo",
			payload: {
				message: "",
				isSuccess: true,
			},
		});
	}, []);
	return (
		<>
			<Loading loading={loading} />
			<ConfirmBox info={info} titleLabel={"処理"} />
			{/*<InfoMessage info={info} />*/}
			<AccountPage
				onClose={onClose}
				onCreate={onCreate}
				onUpdate={onUpdate}
				onDownload={onDownload}
				onUpload={onUpload}
				accounts={accounts}
				brandList={brandList}
				errors={info.errors}
				loginAccount={loginAccount}
			/>
		</>
	);
};
