import * as React from "react";
import { reducer, initState } from "../../../reducers/report/oa";
import { Loading } from "lu-component";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { get, makeError } from "../../../lib/request";
import { endpoint } from "../../../../../server/router/endpoint";
import { useParams } from "react-router";
import { GetOAReportResponse } from "../../../../../server/types/request/report/oa";
import { OAReportPage } from "../../../components/pages/report/oa-report";
import { RetestMenu } from "../../../components/pages/report/common";

export const OAReportContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, ...targetProps } = state;
	const { jobId, activityId } = useParams<{
		jobId: string;
		activityId: string;
	}>();
	React.useEffect(() => {
		get<GetOAReportResponse>(`${endpoint.oaReport}/${jobId}`)
			.then((response) => {
				dispatch({ type: "loadData", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	return (
		<>
			<Loading loading={loading} />
			{targetProps.job && targetProps.job.retestJobId && (
				<RetestMenu activityId={activityId} jobId={targetProps.job.retestJobId.toString()} page={"oa"} />
			)}
			<ConfirmBox info={info} />
			{!!targetProps.data.length && <OAReportPage {...targetProps} />}
		</>
	);
};
