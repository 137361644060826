import * as React from "react";
import { Concept } from "../../../../../server/models/concept";
import { useParams, useHistory } from "react-router";
import { ConceptPreviewPage } from "../../../components/pages/concept/preview";
import { get, makeError } from "../../../lib/request";
import { PreviewConceptResponse } from "../../../../../server/types/request/concept";
import { endpoint, replaceEndpointUrl } from "../../../../../server/router/endpoint";
import { MessageInfo } from "../../../types/info";
import { InfoMessage } from "lu-component";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { clientEndpoint } from "../../../routes/endpoint";

export const ConceptPreviewContainer = () => {
	const [concepts, setConcepts] = React.useState<Concept[]>([]);
	const [info, setInfo] = React.useState<MessageInfo>({ isSuccess: true, message: "" });
	const { jobId, activityId } = useParams<{
		jobId: string;
		activityId: string;
	}>();
	const history = useHistory();
	React.useEffect(() => {
		get<PreviewConceptResponse>(`${endpoint.conceptPreview}/${jobId}`)
			.then((response) => {
				setConcepts(response.data.concepts);
			})
			.catch((error) => {
				setInfo(makeError(error));
			});
	}, []);
	const onEditPage = React.useCallback(() => {
		history.push(replaceEndpointUrl(clientEndpoint.concept, { activityId, jobId }));
	}, []);
	return (
		<>
			<ConfirmBox info={info} titleLabel={"取得処理"} />
			<ConceptPreviewPage concepts={concepts} onEditPage={onEditPage} />
		</>
	);
};
