import * as React from "react";
import Form from "react-bootstrap/Form";
import { Option } from "../../../../../../../server/types/request";
import { MultipleSelect, SingleSelect } from "../../../select";
import { FilteredMultipleSelect } from "./filtered-multiple-select";
import { InlineButtonArea, InterText } from "../../common";
import { DeleteButton, EllipsisButton, MenuItem } from "../../../button";
import { InputText, InputNumber, Checkbox } from "../../../input";
import { getErrorMessage, getErrorObject } from "../../../../../lib/error";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { ErrorObject } from "../../../../../../../server/validation/validation-base";
import { Grouping, SelectionGroup } from "muscat-aggregate-library";
import { Qtype } from "muscat-library";

const Wrapper = styled.div`
	display: flex;
	margin-bottom: 8px;

	.input-number {
		flex-basis: 100px;
	}
`;

const Inner = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex: 1;
	margin-bottom: -8px;
`;

const InnerBlcok = styled.div`
	display: flex;
	flex-basis: 50%;
	flex: 1;
	margin-bottom: 8px;

	> input {
		flex: 1;
		width: unset;
	}

	.form-check {
		align-items: center;
		display: inline-flex;
		height: calc(1.5em + 0.75rem + 2px);
		line-height: 1.5;
		margin: 0 16px;
		white-space: nowrap;
	}

	.tilda {
		align-items: center;
		display: inline-flex;
		flex-basis: 50px;
		height: calc(1.5em + 0.75rem + 2px);
		justify-content: center;
		line-height: 1.5;
		margin: 0 8px;
		white-space: nowrap;
	}

	.react-select-container {
		.react-select__value-container {
			display: block;

			.react-select__multi-value__label {
				flex: 1;
			}
		}
	}

	.multiple-select-wrapper {
		flex: 1;
	}

	.logical-operation-wrapper {
		flex-basis: 88px;
		margin-right: 8px;
	}
`;

const maOptions: Option[] = [
	{ value: "or", label: "OR" },
	{ value: "and", label: "AND" },
];

type Props = {
	index: number;
	errors: ErrorObject;
	selectionGroup: SelectionGroup;
	group: Grouping;
	options: Option[];
	qType: Qtype;
	onAddSelection: (index: number) => void;
	onChangeSelection: (index: number, target: "label" | "from" | "to" | "values" | "not" | "and", value: any) => void;
	onDeleteSelection: (index: number) => void;
};

export const SelectionItem: FunctionComponent<Props> = ({
	index,
	errors,
	selectionGroup,
	group,
	options,
	qType,
	onAddSelection,
	onChangeSelection,
	onDeleteSelection,
}) => {
	const errs = getErrorObject(index.toString(), getErrorObject("grouping", errors));
	const key = `${selectionGroup.name}-group-${index}`;

	return (
		<Form.Group key={key}>
			<Wrapper>
				<InterText>{index + 1}</InterText>
				<Inner>
					<InnerBlcok>
						<InputText
							placeholder={"グループ選択肢名"}
							value={group.label}
							onChange={(v) => onChangeSelection(index, "label", v)}
							error={getErrorMessage("label", errs)}
						/>
						<Checkbox
							id={`${key}-not-checkbox`}
							label={"否定"}
							checked={group.not}
							onChange={(ckecked) => onChangeSelection(index, "not", ckecked)}
						/>
					</InnerBlcok>
					<InnerBlcok>
						{qType === "M" && (
							<div className="logical-operation-wrapper">
								<SingleSelect
									noBlunkOption
									value={group.and ? "and" : "or"}
									options={maOptions}
									onChange={(value) => onChangeSelection(index, "and", value === "and" ? true : false)}
									error={getErrorMessage("and", errs)}
								/>
							</div>
						)}

						{"values" in group ? (
							<div className="multiple-select-wrapper">
								{group.not ? (
									<MultipleSelect
										options={options}
										onChange={(values) => onChangeSelection(index, "values", values)}
										value={group.values}
										error={getErrorMessage("values", errs)}
									/>
								) : (
									<FilteredMultipleSelect
										values={group.values}
										options={options}
										grouping={selectionGroup.grouping}
										onChange={(values) => onChangeSelection(index, "values", values)}
										error={getErrorMessage("values", errs)}
									/>
								)}
							</div>
						) : (
							<>
								<InputNumber
									value={group.from}
									onChange={(v) => onChangeSelection(index, "from", v)}
									error={getErrorMessage("from", errs)}
									className="input-number"
								/>
								<span className="tilda">～</span>
								<InputNumber
									value={group.to}
									onChange={(v) => onChangeSelection(index, "to", v)}
									error={getErrorMessage("to", errs)}
									className="input-number"
								/>
							</>
						)}
					</InnerBlcok>
				</Inner>
				<InlineButtonArea>
					<DeleteButton onClick={() => onDeleteSelection(index)} />
					<EllipsisButton>
						<MenuItem onClick={() => onAddSelection(index)}>一つ前に追加</MenuItem>
						<MenuItem onClick={() => onAddSelection(index + 1)}>一つ後に追加</MenuItem>
					</EllipsisButton>
					{/* <AddButton onClick={() => onAddSelection(index + 1)} /> */}
				</InlineButtonArea>
			</Wrapper>
		</Form.Group>
	);
};
