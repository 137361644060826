import { Question, Choice, NormalQuestion } from "muscat-library";
import * as React from "react";
import { replaceTag } from "../../../../../../server/lib/common";
import { SelectionGroup } from "muscat-aggregate-library";
import { AggregateConfigTable, ScrollWrap } from "./config-table";
import { Row, Col, Alert, Button } from "react-bootstrap";
import { Checkbox } from "../../input";
import { ConfigViewer } from "./config-viewer";
import { SubTitle } from "../common";
import styled from "styled-components";

const Container = styled.div`
	margin-bottom: 64px;
`;

const ConfigWrapper = styled.div`
	display: flex;
	height: 500px;

	> div {
		border: 1px solid #ced4da;
		border-radius: 0.25rem;
	}

	> div:first-child {
		flex-basis: 55%;
		margin-right: 16px;
	}

	> div:last-child {
		flex-basis: 45%;
		overflow-y: auto;

		> * {
			padding: 0 8px;
		}
	}
`;

const ButtonWrapper = styled.div`
	margin-bottom: 8px;

	> button {
		margin: 0;
	}
`;

export type AggregateConfigProps = {
	questions: Question[];
	selectionGroups: SelectionGroup[];
	quenames: string[]; // 対象の設問
	axis: string[]; // 軸情報
	onChangeQuenames: (quenames: string[]) => void;
	onChangeAxis: (axis: string[]) => void;
	quenamesError?: string;
	axisError?: string;
};

export const AggregateConfig = React.memo((props: AggregateConfigProps) => {
	const [targetQuename, setTargetQuename] = React.useState<string>();
	const {
		questions,
		selectionGroups,
		quenames,
		axis,
		onChangeQuenames,
		onChangeAxis,
		axisError,
		quenamesError,
	} = props;
	const qMap = React.useMemo(() => {
		const tmp = questions.reduce((a, b) => {
			if (b.type !== "S" && b.type !== "M" && b.type !== "MT") return a;
			const choices = b.choice_group.reduce(
				(a, b) => [
					...a,
					...b.choices.map((choice) => {
						return { ...choice, text: replaceTag(choice.text) };
					}),
				],
				[]
			);
			const tmp: { choices: Choice[]; childQuestion?: NormalQuestion[] } = {
				choices,
			};
			if (b.type === "MT") {
				const childQuestion: NormalQuestion[] = b.child_questions_group
					.reduce(
						(a, b) => [
							...a,
							...b.questions.map((question) => {
								return { ...question, quetitle: replaceTag(question.quetitle) };
							}),
						],
						[]
					)
					.filter((question) => question.type === "S" || question.type === "M");
				if (!childQuestion.length) return a;
				tmp.childQuestion = childQuestion;
			}
			a.set(b.quename, tmp);
			return a;
		}, new Map<string, { choices: Choice[]; childQuestion?: NormalQuestion[] }>());
		for (const selectionGroup of selectionGroups) {
			tmp.set(selectionGroup.name, {
				choices: selectionGroup.grouping.map((g, index) => ({
					text: g.label,
					value: index + 1,
				})),
			});
		}
		return tmp;
	}, [questions, selectionGroups]);
	const changeQuenames = React.useCallback(
		(quename: string, checked: boolean) => {
			onChangeQuenames(checked ? [...quenames, quename] : quenames.filter((qn) => qn !== quename));
		},
		[quenames, onChangeQuenames]
	);
	const changeAxis = React.useCallback(
		(quename: string, checked: boolean) => {
			onChangeAxis(checked ? [...axis, quename] : axis.filter((ax) => ax !== quename));
		},
		[axis, onChangeAxis]
	);
	const onAllCheckedChange = React.useCallback(() => {
		if (quenames.length) return onChangeQuenames([]);
		const quenamesCheckeds = questions
			.filter(({ quename }) => {
				if (!qMap.has(quename)) return false;
				return true;
			})
			.map(({ quename }) => quename);
		const selectionGroupCheckeds = selectionGroups.map(({ name }) => name);
		onChangeQuenames([...quenamesCheckeds, ...selectionGroupCheckeds]);
	}, [quenames, questions, qMap, onChangeQuenames, selectionGroups]);
	// const onMouseOver = React.useCallback((quename: string) => setTargetQuename(quename), []);
	// const onMouseOut = React.useCallback(() => setTargetQuename(undefined), []);
	const onClick = React.useCallback((quename: string) => setTargetQuename(quename), []);
	return (
		<Container>
			<SubTitle>集計対象の選択</SubTitle>
			{(!!quenamesError || !!axisError) && (
				<Row style={{ marginTop: "0.6rem" }}>
					<Col md={{ offset: 1, span: 10 }}>
						<Alert variant={"danger"}>
							{!!quenamesError && <div>{quenamesError}</div>}
							{!!axisError && <div>{axisError}</div>}
						</Alert>
					</Col>
				</Row>
			)}
			<ButtonWrapper>
				<Button size={"sm"} variant={"secondary"} onClick={onAllCheckedChange}>
					対象設問全選択
				</Button>
			</ButtonWrapper>
			<ConfigWrapper>
				<div>
					<ScrollWrap>
						<AggregateConfigTable>
							<thead>
								<tr>
									<th>集計軸</th>
									<th>対象設問</th>
									<th>設問</th>
								</tr>
							</thead>
							<tbody>
								{questions.map((question) => (
									<React.Fragment key={`target-question-${question.quename}`}>
										{qMap.has(question.quename) ? (
											// <tr onMouseOver={() => onMouseOver(question.quename)} onMouseOut={onMouseOut}>
											<tr
												onClick={() => onClick(question.quename)}
												{...(question.quename == targetQuename && { className: "tr--focused" })}
											>
												<td className={"check"}>
													<Checkbox
														checked={axis.includes(question.quename)}
														onChange={(checked) => changeAxis(question.quename, checked)}
													/>
												</td>
												<td className={"check"}>
													<Checkbox
														checked={quenames.includes(question.quename)}
														onChange={(checked) => changeQuenames(question.quename, checked)}
													/>
												</td>
												<td>
													{question.quename}.{replaceTag(question.quetitle)}
												</td>
											</tr>
										) : (
											""
										)}
									</React.Fragment>
								))}
								{selectionGroups.map((selectionGroup) => (
									<tr
										key={`target-selection-group-${selectionGroup.name}`}
										onClick={() => onClick(selectionGroup.name)}
										{...(selectionGroup.name == targetQuename && { className: "tr--focused" })}
									>
										<td className={"check"}>
											<Checkbox
												checked={axis.includes(selectionGroup.name)}
												onChange={(checked) => changeAxis(selectionGroup.name, checked)}
											/>
										</td>
										<td className={"check"}>
											<Checkbox
												checked={quenames.includes(selectionGroup.name)}
												onChange={(checked) => changeQuenames(selectionGroup.name, checked)}
											/>
										</td>
										<td>{selectionGroup.label}</td>
									</tr>
								))}
							</tbody>
						</AggregateConfigTable>
					</ScrollWrap>
				</div>
				<div>{!!targetQuename && <ConfigViewer {...qMap.get(targetQuename)} />}</div>
			</ConfigWrapper>
		</Container>
	);
});
