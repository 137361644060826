import styled from "styled-components";

export const SubTitle = styled.div`
	display: inline-block;
	font-weight: 600;
	padding-bottom: 8px;
	padding-top: 16px;
`;

export const InterText = styled.div`
	align-items: center;
	display: flex;
	flex-basis: 24px;
	height: calc(1.5em + 0.75rem + 2px);
	line-height: 1.5;
`;

export const InlineButtonArea = styled.div`
	align-items: center;
	display: inline-flex;
	height: calc(1.5em + 0.75rem + 2px);
	margin-left: 8px;

	> * {
		margin-bottom: 3px;
		margin-left: 3px;
		margin-right: 3px;
	}
`;

// export const InputArea = styled.div`
// 	padding: 0.6rem;
// `;

export const InputArea = styled.div``;

export const ButtonArea = styled.div`
	text-align: center;
	padding: 10px;
	button {
		margin: 3px;
	}
`;

export const GroupSelectionItemWrap = styled.div`
	border-radius: 0.25rem;
	border: 1px solid #ced4da;
	margin-bottom: 8px;
	padding: 8px 16px;
`;

export const AddSelectionButtonArea = styled.div`
	margin: 0;
`;

export const ExpandButtonArea = styled.div`
	margin-bottom: 16px;
	margin-top: -8px;

	svg {
		margin-right: 8px;
	}
`;

export const AddSelectionGroupButtonArea = styled.div`
	margin: 8px 0;
`;
