import * as React from "react";
import styled from "styled-components";

export const ReportTitleText = styled.div`
	display: inline-block;
	padding-left: 10px;
	font-weight: bold;
	font-size: 1.3em;
	vertical-align: middle;
`;

export const ReportBigTitleText = styled.div`
	display: inline-block;
	padding-left: 10px;
	font-weight: bold;
	font-size: 1.4em;
	vertical-align: middle;
`;

export const ReportTitleRect = styled.div`
	display: inline-block;
	height: 20px;
	width: 20px;
	background-color: #bbb;
	vertical-align: middle;
`;

export const ReportTitleArea = styled.div`
	margin: 20px 0px 30px 0px;
`;

export const ReportTitle = React.memo((props: { children: React.ReactNode }) => {
	const { children } = props;
	return (
		<ReportTitleArea>
			<ReportTitleRect />
			<ReportTitleText>{children}</ReportTitleText>
		</ReportTitleArea>
	);
});

export const ReportBigTitle = React.memo((props: { children: React.ReactNode }) => {
	const { children } = props;
	return (
		<ReportTitleArea style={{ borderBottom: "1px solid #ddd", padding: "5px 0px 10px" }}>
			<ReportBigTitleText>{children}</ReportBigTitleText>
		</ReportTitleArea>
	);
});
