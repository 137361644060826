import * as React from "react";
import { AggregationQuestion, AggregationLabel, AggregationValue } from "muscat-aggregate-library";
import { CrossHeader } from "../header";

const getBottomLayerTotal = (aggregateValue: AggregationValue): { total: number; noWeightTotal: number }[] => {
	if (aggregateValue.grouping.every((g) => !g.child)) {
		return aggregateValue.grouping.map((g) => ({ total: g.total, noWeightTotal: g.noWeightTotal }));
	}
	return aggregateValue.grouping.reduce((a, b) => {
		return [...a, ...getBottomLayerTotal(b.child)];
	}, []);
};
const roundSamleSize = (input: number): number => Math.round(input);
const CrossData = React.memo((props: AggregationValue & { totals: { total: number; noWeightTotal: number }[] }) => {
	const { totals } = props;
	const bottomLaylerValues = React.useMemo(() => {
		return getBottomLayerTotal(props);
	}, [props]);
	return (
		<>
			{bottomLaylerValues.map((v, index) => {
				return (
					<td className={v && v.total ? "value-area" : "none-value"} key={`${props.quename}-n-${index}`}>
						{v && v.total ? Math.round((v.total / totals[index].total) * 1000) / 10 + "％" : "-"}
					</td>
				);
			})}
		</>
	);
});
const colorLabelText =
	"※回答者ベース（前問MAで「ひとつもない」回答者は含まれない）での計算になっていますので、使用する際はご注意ください。";
const colorTextReg = new RegExp(`${colorLabelText}$`);

export type CrossRowProps = AggregationQuestion & { headerLabel: AggregationLabel };
export const CrossRow = React.memo((props: CrossRowProps) => {
	const { bigTitle, quename, label, items, headerLabel, totals } = props;
	const ns = React.useMemo(() => {
		return getBottomLayerTotal(totals);
	}, [totals]);
	const replaceLabel = React.useMemo(() => {
		if (colorTextReg.test(label)) {
			return (
				<>
					{label.replace(colorLabelText, "")}
					<span className={"color-red"}>{colorLabelText}</span>
				</>
			);
		}
		return label;
	}, [label]);
	return (
		<>
			<tr className={"title-area"}>
				<th colSpan={ns.length + 1}>
					{bigTitle && (
						<>
							{bigTitle}
							<br />
						</>
					)}
					{quename}.{replaceLabel}
				</th>
			</tr>
			<CrossHeader labels={[headerLabel]} isStart />
			<tr key={`data-${quename}-n`}>
				<td>(n)</td>
				{ns.map((n, index) => (
					<td className={n ? "value-area" : "none-value"} key={`${quename}-n-${index}`}>
						{n && n.total ? `${roundSamleSize(n.total)}(${n.noWeightTotal})` : "-"}
					</td>
				))}
			</tr>
			{items.map((item, index) => {
				return (
					<tr className={"data-area"} key={`data-${quename}-${index}`}>
						<td className={"label-area"}>{item.label}</td>
						<CrossData {...item.aggregate} totals={ns} />
					</tr>
				);
			})}
		</>
	);
});
