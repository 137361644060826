import * as React from "react";
import { decimalCalc } from "../../../../../../../../server/lib/aggregate/common";
import { SellPotential } from "../../../../../../../../server/models/graph/demand-forecast";
import { isDefined } from "../../../../../../lib/data";
import { calcDiffPercent } from "../../common";

export type ResultTableRowProps = {
	name: keyof SellPotential | string;
	label: string;
	initValue: number;
	targetValue: number;
};
export const ResultTableRow = React.memo((props: ResultTableRowProps) => {
	const { name, initValue, targetValue, label } = props;
	const diff = React.useMemo(() => calcDiffPercent(initValue, targetValue), [initValue, targetValue]);
	const noChange = React.useMemo(() => !isDefined(targetValue), [targetValue]);
	return (
		<tr>
			<td className={name}>{label}</td>
			<td>{decimalCalc(initValue, 10).toFixed(1)}</td>
			<td>{noChange ? "-" : decimalCalc(targetValue, 10).toFixed(1)}</td>
			<td className={diff < 0 ? "minus" : "plus"}>{noChange ? "-" : diff.toFixed(1)}</td>
		</tr>
	);
});

export type ResultTableProps = {
	sellPotentials: [{ label: string; sellPotential: SellPotential }, { label: string; sellPotential: SellPotential }];
	goalSalesVolume: number;
};

export const ResultTable = React.memo((props: ResultTableProps) => {
	const { sellPotentials, goalSalesVolume } = props;
	const goal50Percent = React.useMemo(() => (goalSalesVolume * 10 * 5) / 100, [goalSalesVolume]);
	const initValues = React.useMemo(() => sellPotentials[0].sellPotential, [sellPotentials]);
	const targetValues = React.useMemo(() => sellPotentials[1].sellPotential, [sellPotentials]);
	return (
		<table className={"result-table"}>
			<thead>
				<tr>
					<th>推定値</th>
					<th>初期値(万個)</th>
					<th>補整値(万個)</th>
					<th>補整率(%)</th>
				</tr>
			</thead>
			<tbody>
				<ResultTableRow
					name={"trial"}
					label={"トライアル"}
					initValue={initValues.trial}
					targetValue={targetValues.trial}
				/>
				<ResultTableRow
					name={"repeat"}
					label={"リピート"}
					initValue={initValues.repeat}
					targetValue={targetValues.repeat}
				/>
				<ResultTableRow
					name={"localOther"}
					label={"ローカルその他"}
					initValue={initValues.localOther}
					targetValue={targetValues.localOther}
				/>
				<ResultTableRow
					name={"inbound"}
					label={"インバウンド"}
					initValue={initValues.inbound}
					targetValue={targetValues.inbound}
				/>
				<ResultTableRow name={"total"} label={"合計"} initValue={initValues.total} targetValue={targetValues.total} />
				<ResultTableRow
					name={"goal"}
					label={"目標対比(%)"}
					initValue={(initValues.total / goalSalesVolume) * 100 - 100}
					targetValue={isDefined(targetValues.total) ? (targetValues.total / goalSalesVolume) * 100 - 100 : undefined}
				/>
				<tr className={"action-standard"}>
					<td>AS判定</td>
					<td>{initValues.total > goal50Percent ? "〇" : "×"}</td>
					<td>{isDefined(targetValues.total) ? (targetValues.total > goal50Percent ? "〇" : "×") : "-"}</td>
					<td className={"none-value"}></td>
				</tr>
			</tbody>
		</table>
	);
});
