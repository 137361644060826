import { InfoAction } from "../../types/actions";
import { MessageInfo } from "../../types/info";
import { GetInfoResponse } from "../../../../server/types/request/info";

export type State = {
	loading: boolean;
	info: MessageInfo;
} & GetInfoResponse;
export const initState: State = {
	manualUrl: "/pdf/manual.pdf",
	guidelinesUrl: "/pdf/guidelines.pdf",
	message: "システムのお知らせです。",
	loading: true,
	info: {
		message: "",
		isSuccess: true,
	},
};

export const reducer = (state: State, action: InfoAction): State => {
	switch (action.type) {
		case "loadInfo":
			return {
				...state,
				loading: false,
				...action.payload,
			};
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
		default:
			return state;
	}
};
