import * as React from "react";
import styled from "styled-components";
import { FloatingMenu } from "../../parts/floating-menu";

export const GraphWrapArea = styled.div`
	margin: 0px auto;
	padding: 0px 10px;
	max-width: 1200px;
	min-width: 1000px;
`;

export type RetestMenuProps = {
	page: "oa" | "result-by-target" | "key-mesurements";
	activityId: string;
	jobId: string;
};

export const RetestMenu = React.memo((props: RetestMenuProps) => {
	const { page, activityId, jobId } = props;
	const onRetest = React.useCallback(() => {
		const url = `/report/${activityId}/${jobId}/${page}`;
		window.open(url);
	}, [page, activityId, jobId]);
	const menus = React.useMemo(
		() => [
			{
				label: "前回調査を開く",
				event: onRetest,
			},
		],
		[onRetest]
	);
	return <FloatingMenu menus={menus} />;
});
