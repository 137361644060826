import * as React from "react";
import * as RB from "react-bootstrap";
import Select from "react-select";
import { ErrorObject } from "../../../../../server/validation/validation-base";
import Feedback from "react-bootstrap/Feedback";
import styled from "styled-components";

interface PillProp {
	variant: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark";
	text: string;
}

const LabelWrap = styled.div`
	white-space: pre;
`;
export type InputRowProps = {
	name: string;
	rowName: React.ReactChild | React.ReactChild[] | React.ReactText;
	children: React.ReactElement | React.ReactChild;
	pill?: PillProp;
	errors?: ErrorObject;
	subText?: React.ReactChild | React.ReactChild[] | React.ReactText;
};
export const InputRow = (props: InputRowProps) => {
	const { pill, errors, rowName, name, children, subText } = props;
	return (
		<RB.Row className="m-2">
			<RB.Col lg={4} className="text-right">
				<LabelWrap>
					{rowName}
					{pill && (
						<RB.Badge pill variant={pill.variant}>
							{pill.text}
						</RB.Badge>
					)}
				</LabelWrap>
			</RB.Col>
			<RB.Col>
				{children}
				{!!errors && !!errors[name] && (
					<Feedback type={"invalid"} style={{ display: "block" }}>
						{errors[name]}
					</Feedback>
				)}
			</RB.Col>
			{subText && <RB.Col>{subText}</RB.Col>}
		</RB.Row>
	);
};

export interface RowProp {
	value: string;
	onChange: (value: any) => void;
	name: string;
	rowName: React.ReactChild | React.ReactChild[] | React.ReactText;
	id: string;
	pill?: PillProp;
	type?: string;
	errors?: ErrorObject;
	subText?: React.ReactChild | React.ReactChild[] | React.ReactText;
}
export const TextRow = (props: RowProp) => {
	const { value, onChange, name, errors, ...attr } = props;
	return (
		<InputRow {...attr} name={name} errors={errors}>
			<>
				{props.type === "number" && (
					<RB.Form.Control
						type={"text"}
						value={value === undefined ? "" : value}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							const tmp = Number(e.target.value);
							if (isNaN(tmp)) return;
							onChange(Math.floor(tmp));
						}}
						pattern="\d+"
						min={1}
						isInvalid={!!errors && !!errors[name]}
					/>
				)}
				{props.type !== "number" && (
					<RB.Form.Control
						type={props.type === "decimal" ? "number" : props.type || "text"}
						defaultValue={value}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
						{...(props.type === "decimal" ? { step: "0.1", min: 1 } : {})}
						isInvalid={!!errors && !!errors[name]}
					/>
				)}
			</>
		</InputRow>
	);
};

export type SelectRowProps = Omit<RowProp, "value"> & {
	value: string | number;
	options: { label: string; value: string | number }[];
};

export const SelectRow = (props: SelectRowProps) => {
	const { name, options, value, onChange, errors, ...other } = props;
	const selectbox = () => {
		return (
			<RB.Form.Control
				as="select"
				value={value ? value.toString() : ""}
				onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
					onChange(e.target.value);
				}}
				isInvalid={!!errors && !!errors[name]}
			>
				<option></option>
				{options.map(({ value, label }) => {
					return (
						<option key={value} value={value}>
							{label}
						</option>
					);
				})}
			</RB.Form.Control>
		);
	};
	return (
		<InputRow name={name} {...other} errors={errors}>
			{selectbox()}
		</InputRow>
	);
};

export const Wrap = styled.div`
	& div.invalid {
		& div {
			border-color: red;
		}
	}
`;
export type ReactMultiSelectRowProps = Omit<SelectRowProps, "value"> & { values: (string | number)[] };
export const ReactMultiSelectRow = (props: ReactMultiSelectRowProps) => {
	const { options, onChange, values, errors, name, ...other } = props;
	const selected = React.useMemo<{ label: string; value: string | number }[]>(() => {
		if (!values) return [];
		return options.filter((op) => values.includes(op.value));
	}, [options, values]);
	const onReactSelectChange = React.useCallback(
		(selectedOptions: { label: string; value: string | number }[]) =>
			onChange(selectedOptions ? selectedOptions.map((op) => op.value) : []),
		[onChange]
	);
	return (
		<InputRow {...other} name={name} errors={errors}>
			<Wrap>
				<Select
					className={"w-100" + (!!errors && !!errors[name] ? " invalid" : "")}
					onChange={onReactSelectChange}
					options={options as any}
					value={selected}
					isMulti
					closeMenuOnSelect={false}
				/>
			</Wrap>
		</InputRow>
	);
};

export const CheckBoxRow = (props: SelectRowProps) => {
	//const [checked, setChecked] = React.useState<{ label: string; value: string }>();
	//const onClick = React.useCallback(() => {});
	const { options, ...other } = props;
	React.useEffect(() => {
		console.log(options[0].label);
	});
	const checkbox = () => {
		return (
			<>
				{options.map((option) => {
					return <RB.Form.Check key={option.value} type="checkbox" {...option} id={other.id + option.value} />;
				})}
			</>
		);
	};
	return <InputRow {...other}>{checkbox()}</InputRow>;
};

export type SelectRangeRowProps = Omit<Omit<SelectRowProps, "value">, "options"> & {
	values: {
		from: number;
		to: number;
	};
	toOptions: { label: string; value: number }[];
	fromOptions: { label: string; value: number }[];
};
export const SelectRangeRow = (props: SelectRangeRowProps) => {
	const { toOptions, fromOptions, values, onChange, name, errors, ...other } = props;
	const onChangeSelect = React.useCallback(
		(name: "from" | "to", value: string) => {
			const tmp = Number(value);
			const v = !!value && !isNaN(tmp) ? tmp : undefined;
			onChange({ ...values, [name]: v });
		},
		[values]
	);
	const toFilterOption = React.useMemo(() => {
		if (!values || !values.from) return toOptions;
		return toOptions.filter((op) => op.value >= values.from);
	}, [toOptions, values]);
	const myError = React.useMemo<ErrorObject>(() => {
		if (!errors || !errors[name]) return undefined;
		const tmp = errors[name];
		if (typeof tmp === "string") return undefined;
		return tmp;
	}, [errors, name]);
	return (
		<InputRow name={name} {...other}>
			<RB.Row noGutters>
				<RB.Col>
					<RB.Form.Control
						as="select"
						value={values.from ? values.from.toString() : ""}
						onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
							onChangeSelect("from", e.target.value);
						}}
						isInvalid={!!myError && !!myError["from"]}
					>
						<option></option>
						{fromOptions.map(({ value, label }) => {
							return (
								<option key={value} value={value}>
									{label}
								</option>
							);
						})}
					</RB.Form.Control>
					{!!myError && !!myError["from"] && (
						<Feedback type={"invalid"} style={{ display: "block" }}>
							{myError["from"]}
						</Feedback>
					)}
				</RB.Col>
				<RB.Col xs="auto">　～　</RB.Col>
				<RB.Col>
					<RB.Form.Control
						as="select"
						value={values.to ? values.to.toString() : ""}
						onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
							onChangeSelect("to", e.target.value);
						}}
						isInvalid={!!myError && !!myError["to"]}
					>
						<option></option>
						{toFilterOption.map(({ value, label }) => {
							return (
								<option key={value} value={value}>
									{label}
								</option>
							);
						})}
					</RB.Form.Control>
					{!!myError && !!myError["to"] && (
						<Feedback type={"invalid"} style={{ display: "block" }}>
							{myError["to"]}
						</Feedback>
					)}
				</RB.Col>
			</RB.Row>
		</InputRow>
	);
};
