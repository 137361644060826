import * as React from "react";
import { ActionStandard } from "./action-standard";
import { PotentialDemand } from "./potential-demand";
import { OneNumberScore } from "./one-number-score";
import { OneNumberScoreDetail } from "./one-number-score-detail";
import styled from "styled-components";
import { GraphWrapArea } from "../common";
import { ExectiveReportData } from "../../../../../../server/types/request/report/exective";
import { SalesVolumePrediction } from "../../../../../../server/analytics/sales-volume";
import { ConceptKey } from "../../../../../../server/models/concept";
import { OneNumberScoreData } from "../../../../../../server/models/graph/demand-forecast";
import { TargetGroup } from "../../../../../../server/models/graph/target-group";
import { array2CsvBlob, downloadData } from "../../../../lib/data";
import { Button } from "react-bootstrap";
import { judgeNorm } from "../../../../lib/action-standard";
const GraphPanel = styled.div`
	width: 100%;
`;
const GraphCard = styled.div`
	vertical-align: top;
	min-width: 500px;
	display: inline-block;
`;
const ButtonArea = styled.div`
	button {
		margin: 0px 10px;
	}
`;

export type ExectiveReportPageProps = ExectiveReportData & { goalSalesVolume: number; onSimulationPage: () => void };

const getData = (data: SalesVolumePrediction | undefined, key: keyof SalesVolumePrediction, isPercent = false) => {
	if (data === undefined || !(key in data)) return "";
	if (isPercent) return Number(data[key]) * 100;
	return data[key];
};

const getSum = (data: SalesVolumePrediction | undefined, keys: (keyof SalesVolumePrediction)[], isPercent = false) => {
	if (data === undefined) return "";
	if (keys.some((key) => !(key in data))) {
		return "";
	}
	const sum = keys.reduce((a, b) => a + data[b], 0);
	if (isPercent) return sum * 100;
	return sum;
};

const findOneNumberScore = (
	oneNumberScores: OneNumberScoreData[],
	targetGroup: TargetGroup,
	concept: ConceptKey,
	key: keyof OneNumberScoreData
) => {
	const target = oneNumberScores.find((ons) => ons.target === targetGroup && ons.conceptType === concept);
	if (!target) return "";
	return target[key];
};
export const ExectiveReportPage = React.memo((props: ExectiveReportPageProps) => {
	const {
		demandForecast,
		oneNumberScoreNorm,
		goalSalesVolume,
		oneNumberScores,
		salesVolumePrediction,
		onSimulationPage,
		job,
	} = props;
	const onDownload = React.useCallback(() => {
		const { test1, test2 } = salesVolumePrediction;
		const test1Norm = findOneNumberScore(oneNumberScores, "category", "test1", "score");
		const test2Norm = findOneNumberScore(oneNumberScores, "category", "test2", "score");
		const tmp: [string, number | string][] = [
			["JOB No.", job.jobNum],
			// テスト品1の需要予測関連
			["【テスト品1】カテゴリユーザー購入意向: TopBox(%)", getData(test1, "c", true)],
			["【テスト品1】カテゴリユーザー購入意向: 2nd Box(%)", getData(test1, "d", true)],
			["【テスト品1】カテゴリノンユーザー購入意向 : Top Box(%)", getData(test1, "a", true)],
			["【テスト品1】カテゴリノンユーザー購入意向: 2nd Box(%)", getData(test1, "b", true)],
			["【テスト品1】カテゴリユーザー購入意向ワンナンバースコア", getData(test1, "n", true)],
			["【テスト品1】カテゴリノンユーザー購入意向ワンナンバースコア", getData(test1, "m", true)],
			["【テスト品1】カテゴリユーザー数(百万人)", getData(test1, "f")],
			["【テスト品1】カテゴリノンユーザー数(百万人)", getData(test1, "e")],
			["【テスト品1】想定商品認知率(%)　", getData(test1, "g", true)],
			["【テスト品1】発売12か月の購入者あたり購入個数（個）", getData(test1, "h")],
			["【テスト品1】ローカルその他比率(%)　※SLI/SCI申告漏れ補正前", getSum(test1, ["i", "j", "k"], true)],
			["【テスト品1】IB比率(%)　※SLI/SCI申告漏れ補正前", getData(test1, "l", true)],
			["【テスト品1】トライアル(万個)", getData(test1, "o")],
			["【テスト品1】リピート(万個)", getData(test1, "p")],
			["【テスト品1】ローカルその他（万個）", getSum(test1, ["q", "r", "s"])],
			["【テスト品1】IB(万個)", getData(test1, "t")],
			["【テスト品1】合計数量(万個)", getData(test1, "u")],
			["【テスト品1】トライアル＋リピート (万個)", getSum(test1, ["o", "p"])],
			// テスト品2の需要予測関連
			["【テスト品2】カテゴリユーザー購入意向: TopBox(%)", getData(test2, "c", true)],
			["【テスト品2】カテゴリユーザー購入意向: 2nd Box(%)", getData(test2, "d", true)],
			["【テスト品2】カテゴリノンユーザー購入意向 : Top Box(%)", getData(test2, "a", true)],
			["【テスト品2】カテゴリノンユーザー購入意向: 2nd Box(%)", getData(test2, "b", true)],
			["【テスト品2】カテゴリユーザー購入意向ワンナンバースコア", getData(test2, "n", true)],
			["【テスト品2】カテゴリノンユーザー購入意向ワンナンバースコア", getData(test2, "m", true)],
			["【テスト品2】カテゴリユーザー数(百万人)", getData(test2, "f")],
			["【テスト品2】カテゴリノンユーザー数(百万人)", getData(test2, "e")],
			["【テスト品2】想定商品認知率(%)　", getData(test2, "g", true)],
			["【テスト品2】発売12か月の購入者あたり購入個数（個）", getData(test2, "h")],
			["【テスト品2】ローカルその他比率(%)　※SLI/SCI申告漏れ補正前", getSum(test2, ["i", "j", "k"], true)],
			["【テスト品2】IB比率(%)　※SLI/SCI申告漏れ補正前", getData(test2, "l", true)],
			["【テスト品2】トライアル(万個)", getData(test2, "o")],
			["【テスト品2】リピート(万個)", getData(test2, "p")],
			["【テスト品2】ローカルその他（万個）", getSum(test2, ["q", "r", "s"])],
			["【テスト品2】IB(万個)", getData(test2, "t")],
			["【テスト品2】合計数量(万個)", getData(test2, "u")],
			["【テスト品2】トライアル＋リピート (万個)", getSum(test2, ["o", "p"])],

			["目標値 (万個)", goalSalesVolume],
			["50%ライン (万個)", goalSalesVolume * 0.5],

			// ワンナンバースコア
			// カテゴリターゲット
			["カテゴリターゲットG テスト品1 購入意向ワンナンバースコア", test1Norm],
			["カテゴリターゲットG テスト品2 購入意向ワンナンバースコア", test2Norm],
			[
				"カテゴリターゲットG 競合 購入意向ワンナンバースコア",
				findOneNumberScore(oneNumberScores, "category", "benchmark", "score"),
			],
			[
				"カテゴリターゲットG 現行 購入意向ワンナンバースコア",
				findOneNumberScore(oneNumberScores, "category", "currentProduct", "score"),
			],
			[
				"カテゴリターゲットG テスト品1 サンプルサイズ",
				findOneNumberScore(oneNumberScores, "category", "test1", "sampleSize"),
			],
			[
				"カテゴリターゲットG テスト品2 サンプルサイズ",
				findOneNumberScore(oneNumberScores, "category", "test2", "sampleSize"),
			],
			[
				"カテゴリターゲットG 競合 サンプルサイズ",
				findOneNumberScore(oneNumberScores, "category", "benchmark", "sampleSize"),
			],
			[
				"カテゴリターゲットG 現行 サンプルサイズ",
				findOneNumberScore(oneNumberScores, "category", "currentProduct", "sampleSize"),
			],
			// 戦略ターゲット
			[
				"戦略ターゲットG テスト品1 購入意向ワンナンバースコア",
				findOneNumberScore(oneNumberScores, "strategy", "test1", "score"),
			],
			[
				"戦略ターゲットG テスト品2 購入意向ワンナンバースコア",
				findOneNumberScore(oneNumberScores, "strategy", "test2", "score"),
			],
			[
				"戦略ターゲットG 競合 購入意向ワンナンバースコア",
				findOneNumberScore(oneNumberScores, "strategy", "benchmark", "score"),
			],
			[
				"戦略ターゲットG 現行 購入意向ワンナンバースコア",
				findOneNumberScore(oneNumberScores, "strategy", "currentProduct", "score"),
			],
			[
				"戦略ターゲットG テスト品1 サンプルサイズ",
				findOneNumberScore(oneNumberScores, "strategy", "test1", "sampleSize"),
			],
			[
				"戦略ターゲットG テスト品2 サンプルサイズ",
				findOneNumberScore(oneNumberScores, "strategy", "test2", "sampleSize"),
			],
			[
				"戦略ターゲットG 競合 サンプルサイズ",
				findOneNumberScore(oneNumberScores, "strategy", "benchmark", "sampleSize"),
			],
			[
				"戦略ターゲットG 現行 サンプルサイズ",
				findOneNumberScore(oneNumberScores, "strategy", "currentProduct", "sampleSize"),
			],
			// ノーム判定
			[
				"【テスト品1】ノーム判定",
				test1Norm === "" || !oneNumberScoreNorm ? "" : judgeNorm(Number(test1Norm), oneNumberScoreNorm),
			],
			[
				"【テスト品2】ノーム判定",
				test2Norm === "" || !oneNumberScoreNorm ? "" : judgeNorm(Number(test2Norm), oneNumberScoreNorm),
			],
			["申告漏れ係数", test1.v === undefined ? "" : test1.v + 1],
		];
		const blob = array2CsvBlob(tmp);
		downloadData(blob, `${job.jobNum}-エグゼグティブサマリー.csv`);
	}, [demandForecast, oneNumberScoreNorm, goalSalesVolume, oneNumberScores, salesVolumePrediction, job]);
	return (
		<GraphWrapArea>
			<ButtonArea>
				<Button variant={"outline-secondary"} size={"sm"} onClick={onDownload}>
					Download
				</Button>
				<Button variant={"outline-secondary"} size={"sm"} onClick={onSimulationPage}>
					補整＆提案フォーマット
				</Button>
			</ButtonArea>
			<GraphPanel>
				<GraphCard>
					<ActionStandard
						job={job}
						demandForecast={demandForecast}
						oneNumberScoreNorm={oneNumberScoreNorm}
						oneNumberScores={oneNumberScores}
						goalSalesVolume={goalSalesVolume}
					/>
				</GraphCard>
				<GraphCard>
					<PotentialDemand
						/**
						jobCode={job.jobNum}
						salesVolumePrediction={salesVolumePrediction}
						 */
						demandForecast={demandForecast}
						goalSalesVolume={goalSalesVolume}
					/>
				</GraphCard>
			</GraphPanel>
			<GraphPanel>
				{oneNumberScoreNorm && (
					<GraphCard>
						<OneNumberScore job={job} oneNumberScores={oneNumberScores} oneNumberScoreNorm={oneNumberScoreNorm} />
					</GraphCard>
				)}
				<GraphCard>
					<OneNumberScoreDetail oneNumberScores={oneNumberScores} />
				</GraphCard>
			</GraphPanel>
		</GraphWrapArea>
	);
});
