import { ActivityListAction } from "../../../types/actions";
import { MessageInfo } from "../../../types/info";
import { Option } from "lu-component";
import { Activity } from "../../../../../server/models/activity";
import { SearchValues } from "../../../components/parts/search";
import { AccountWithoutPassword } from "../../../../../server/models/account";

export type State = {
	activities: Activity[];
	accounts: AccountWithoutPassword[];
	categoryOptions: Option[];
	brandOptions: Option[];
	yearOptions: Option[];
	searches: SearchValues;
	loading: boolean;
	info: MessageInfo;
};
export const initState: State = {
	activities: [],
	accounts: [],
	categoryOptions: [],
	brandOptions: [],
	yearOptions: [],
	searches: {},
	loading: true,
	info: {
		message: "",
		isSuccess: true,
	},
};

const changeSearch = (
	searchValues: SearchValues,
	name: string,
	value: string | number,
	accounts: AccountWithoutPassword[]
) => {
	if (value) {
		const tmp = { ...searchValues, [name]: value };
		if (name === "brandId" && searchValues["accountId"]) {
			const target = accounts.find((account) => account._id === searchValues["accountId"]);
			if (target && target.brand && !target.brand.includes(value.toString())) {
				delete tmp["accountId"];
			}
		}
		return tmp;
	}
	const tmp = { ...searchValues };
	delete tmp[name];
	return tmp;
};

export const reducer = (state: State, action: ActivityListAction): State => {
	switch (action.type) {
		case "loadActivity":
			return {
				...state,
				loading: false,
				...action.payload,
			};
		case "setActivity":
			return {
				...state,
				loading: false,
				activities: action.payload,
			};
		case "changeSearch":
			return {
				...state,
				searches: changeSearch(state.searches, action.payload.name, action.payload.value, state.accounts),
			};
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
		default:
			return state;
	}
};
