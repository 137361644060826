import * as React from "react";
import styled from "styled-components";
import { Accordion, Button, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export const SubTitle = styled.div`
	margin: 0.4em;
	padding: 0.2em 0.8em;
	border-left: 4px solid #ddd;
	// font-weight: bold;
	border-bottom: 1px solid #ddd;
	font-size: 1.1em;
`;

export type AccordionFormProps = {
	title: string;
	eventKey: string;
	children: React.ReactElement;
	defaultShow?: boolean;
};
export const AccordionForm = React.memo((props: AccordionFormProps) => {
	const { title, eventKey, children, defaultShow } = props;
	return (
		<Accordion defaultActiveKey={defaultShow ? eventKey : "0"}>
			<SubTitle>
				{title}
				<Badge pill variant={"primary"} style={{ marginLeft: "10px" }}>
					任意
				</Badge>
				<Accordion.Toggle as={Button} variant={"light"} eventKey={eventKey} size={"sm"}>
					<FontAwesomeIcon icon={faBars} />
				</Accordion.Toggle>
			</SubTitle>
			<Accordion.Collapse eventKey={eventKey}>{children}</Accordion.Collapse>
		</Accordion>
	);
});
