import * as React from "react";
import { Text, Bar } from "../common";

const yPad = 50;
const xRightPad = 100;
const barMargin = 30;
const barWidth = 150;
const precedentSide = 16;

export type StockedBar = {
	label: string;
	color: string;
	value: number;
};

const Precedent = React.memo((props: { bars: StockedBar[]; startX: number; startY: number }) => {
	const { bars, startX, startY } = props;
	const step = 30;
	return (
		<>
			{bars.reverse().map((bar, index) => {
				const y = startY + step * index;
				return (
					<g key={`precedent-${index}`}>
						<Bar x={startX} y={startY + step * index} width={precedentSide} height={precedentSide} color={bar.color} />
						<Text
							x={startX + precedentSide + 5}
							y={y + precedentSide / 2}
							value={bar.label}
							textAnchor={"start"}
							fontSize={"15px"}
						/>
					</g>
				);
			})}
		</>
	);
});

export type StandardLine = {
	value: number;
	color: string;
	label: string;
	dashed?: boolean;
	width?: number;
};

export type StokedBarGroup = {
	label: string;
	total: number;
	bars: StockedBar[];
};

export type StockedBarGraphProps = {
	barGroups: StokedBarGroup[];
	lines: StandardLine[];
	max: number;
	unit: string;
	step?: number;
	width?: number;
	hidePrecedent?: boolean;
	height?: number;
};

export const StockedBarGraph = React.memo((props: StockedBarGraphProps) => {
	const { barGroups, lines, max, unit, width, hidePrecedent, height, step = 20 } = props;
	const xLeftPad = React.useMemo(() => (hidePrecedent ? 100 : 250), [hidePrecedent]);
	const numberX = React.useMemo(() => xLeftPad - 30, [xLeftPad]);
	const barStartX = React.useMemo(() => xLeftPad + barMargin, [xLeftPad]);

	const areaWidth = React.useMemo(() => {
		return xLeftPad + (barWidth + barMargin * 2) * barGroups.length + xRightPad;
	}, [barGroups]);
	const areaHeight = React.useMemo(() => {
		if (height) return height;
		return 200 + 200 * barGroups.length;
	}, [barGroups, height]);
	const BaseLineGroup = React.useMemo(() => {
		const tmp: JSX.Element[] = [];
		let v = 0;
		while (v <= max) {
			const y = areaHeight - yPad - (v * (areaHeight - yPad * 2)) / max;
			tmp.push(
				<g key={`line-${v}`}>
					<Text x={numberX} y={y} value={v.toFixed(1)} fontSize={"13px"} />
					<line x1={xLeftPad - 10} y1={y} x2={xLeftPad + 10} y2={y} stroke="#ccc" />
					<line
						x1={xLeftPad - 10}
						y1={y}
						x2={areaWidth - xRightPad}
						y2={y}
						stroke="#ccc"
						strokeDasharray="3"
						style={{ opacity: 0.4 }}
					/>
				</g>
			);
			/*/
			if (v != max && v + step > max) {
				v = max;
			} else {
				v += step;
			}
			/*/
			v = Math.floor(v * 100 + step * 100) / 100;
		}
		return tmp;
	}, [max, step]);
	const Bars = React.useMemo(() => {
		return barGroups.map((barGroup, groupIndex) => {
			let y = areaHeight - yPad;
			const x = barStartX + (barMargin + barWidth) * groupIndex;
			const { total } = barGroup;
			const totalY = y - (total * (areaHeight - yPad * 2)) / max - 20;
			const textX = x + barWidth / 2;
			return (
				<React.Fragment key={`bargroup-${groupIndex}`}>
					<Text x={textX} y={areaHeight - 20} value={barGroup.label} fontSize={"15px"} />
					{barGroup.bars.map((bar, index) => {
						if (bar.value === undefined) return "";
						const start = y;
						const height = (bar.value * (areaHeight - yPad * 2)) / max;
						y -= height;
						return (
							<Bar
								key={`bar-${groupIndex}-${index}`}
								x={x}
								y={start - height}
								width={barWidth}
								height={height}
								color={bar.color}
								value={bar.value.toFixed(1)}
							/>
						);
					})}
					{total !== undefined && <Text x={textX} y={totalY} value={total.toFixed(1)} fontSize={"15px"} />}
				</React.Fragment>
			);
		});
	}, [barGroups, max]);
	const Lines = React.useMemo(() => {
		return lines.map((ln, index) => {
			const y = areaHeight - yPad - (ln.value * (areaHeight - yPad * 2)) / max;
			return (
				<g key={`line-${index}`}>
					<line
						x1={xLeftPad}
						x2={areaWidth - xRightPad}
						y1={y}
						y2={y}
						stroke={ln.color}
						strokeWidth={ln.width || 1}
						{...(ln.dashed ? { strokeDasharray: "3" } : { strokeOpacity: 0.8 })}
					/>
					<Text
						x={areaWidth - xRightPad + (index === 0 ? -50 : 0)}
						y={y + (index === 0 ? -15 : 0)}
						value={ln.label}
						textAnchor={"start"}
						fontSize={"15px"}
					/>
				</g>
			);
		});
	}, [lines, max]);
	const attribute = React.useMemo(() => {
		if (width) {
			const height = Math.round((width / areaWidth) * areaHeight);
			return { width, height };
		}
		return {};
	}, [width, areaWidth, areaHeight]);

	return (
		<svg viewBox={`0 0 ${areaWidth} ${areaHeight}`} {...attribute}>
			{barGroups.length && !hidePrecedent && <Precedent bars={barGroups[0].bars} startX={30} startY={150} />}
			<Text x={xLeftPad} y={30} value={unit} fontSize={"13px"} />
			<line x1={xLeftPad} x2={areaWidth - xRightPad} y1={areaHeight - yPad} y2={areaHeight - yPad} stroke="#ccc" />
			<line x1={xLeftPad} x2={xLeftPad} y1={yPad} y2={areaHeight - yPad} stroke="#ccc" />
			{BaseLineGroup}
			{Bars}
			{Lines}
		</svg>
	);
});
