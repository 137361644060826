import * as React from "react";
import { Button } from "react-bootstrap";
import { SubTitle, ButtonArea } from "../../../parts/aggregate-setting/common";
import { CrossProps, VerticalCross } from "../../../parts/cross/vertical";

export type AggregateViewPageProps = CrossProps & {
	onEdit: () => void;
	onDownload: () => void;
	onList: () => void;
};
export const AggregateViewPage = React.memo((props: AggregateViewPageProps) => {
	const { label, data, onDownload, onEdit, onList } = props;
	return (
		<>
			<SubTitle>集計結果</SubTitle>
			<VerticalCross label={label} data={data} />
			<ButtonArea>
				<Button variant={"outline-secondary"} onClick={onEdit}>
					編集
				</Button>
				<Button variant={"outline-secondary"} onClick={onDownload}>
					ダウンロード
				</Button>
				<Button variant={"outline-secondary"} onClick={onList}>
					一覧に戻る
				</Button>
			</ButtonArea>
		</>
	);
});
