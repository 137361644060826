import * as React from "react";
import { Card, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const WrapArea = styled.div`
	margin: 2em;
`;
const Icon = styled.div`
	display: inline-block;
	padding: 0px 10px;
	cursor: pointer;
	color: #aaa;
`;
const PDFArea = styled.div`
	margin: 8px 0px;
`;

export type InfoPageProps = {
	manualUrl: string;
	guidelinesUrl: string;
	message: string;
};

export const InfoPage = React.memo((props: InfoPageProps) => {
	const { manualUrl, guidelinesUrl, message } = props;
	const onManualClick = React.useCallback(() => {
		window.open(manualUrl);
	}, [manualUrl]);
	const guidelinesClick = React.useCallback(() => {
		window.open(guidelinesUrl);
	}, [guidelinesUrl]);
	return (
		<>
			<WrapArea>
				<div className={"h3"}>■ マニュアル</div>
				<Card>
					<Card.Header>マニュアルPDF</Card.Header>
					<Card.Body>
						<PDFArea>
							マニュアルPDF
							<Icon onClick={onManualClick}>
								<FontAwesomeIcon icon={faFilePdf} size={"lg"} />
							</Icon>
						</PDFArea>
						<PDFArea>
							ガイドラインPDF
							<Icon onClick={guidelinesClick}>
								<FontAwesomeIcon icon={faFilePdf} size={"lg"} />
							</Icon>
						</PDFArea>
					</Card.Body>
				</Card>
			</WrapArea>
			<WrapArea>
				<div className={"h3"}>■ お知らせ</div>
				<Card>
					<Card.Header>システムからのメッセージ</Card.Header>
					<Card.Body>
						<div dangerouslySetInnerHTML={{ __html: message }} />
					</Card.Body>
				</Card>
			</WrapArea>
		</>
	);
});
